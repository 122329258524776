import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, get, isEmpty } from 'lodash';
import saveAs from 'file-saver';
import moment from 'moment-timezone';
import { getTicketBinaryFile } from '../../services/workki/TicketsActions';
import { getTicket, getTicketInfo } from '../../services/workki/TicketsActions';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import TicketComments from './TicketComments';
import TicketAccordion from './TicketAccordion';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';
import SupportAppealStatus from '../Support/SupportAppealStatus';

const TicketDetails = ({ ticket, showTicketsList, customer, offices }) => {
  const dispatch = useDispatch();
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const ticketLoadStatus = useSelector(requestStatusSelector(actions.requestTicket));
  const ticketInfoLoadStatus = useSelector(requestStatusSelector(actions.requestTicketInfo));
  useEffect(() => {
    dispatch(getTicket(ticket.id));
    dispatch(getTicketInfo(ticket.id));
  }, [dispatch, ticket.id]);

  useEffect(() => {
    if (ticketLoadStatus.isFailure || ticketInfoLoadStatus.isFailure) {
      showTicketsList();
    }
  }, [showTicketsList, ticketInfoLoadStatus.isFailure, ticketLoadStatus.isFailure]);

  if (!ticket) {
    return;
  }

  return (
    <div className='detail-ticket'>
      <div className='detail-ticket__container'>
        <div className='detail-ticket-top-block'>
          <div className='ticket-detail-title-wrapper'>
            <div className='detail-top-btn-wrapper'>
              <ArrowBack
                defaultColor={'#24272A'}
                hoverColor={'#383c40'}
                rotation={0}
                bigArrow={isLaptop}
                onClick={() => showTicketsList()}
                style={{ minWidth: isLaptop ? '32px' : '28px', height: isLaptop ? '32px' : '28px' }}
              />
              <h1>Обращение №{ticket.id}</h1>
            </div>
            <SupportAppealStatus statusId={ticket.status_id} />
          </div>
          <TicketAccordion title='Информация об обращении'>
            <div className='ticket-accordion-item'>
              <div className='ticket-accordion-item-title'>Дата и время</div>
              <div className='ticket-accordion-item-text'>
                {ticket.created_at && moment(ticket.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm')}
              </div>
            </div>
            <div className='ticket-accordion-item'>
              <div className='ticket-accordion-item-title'>Рабочее место и локация</div>

              <div className='ticket-accordion-item-text'>{`${ticket.place && ticket.place.name} - ${ticket.office &&
                ticket.office.t_name}`}</div>
            </div>
            <div className='ticket-accordion-item'>
              <div className='ticket-accordion-item-title'>Категория и подкатегория</div>
              <div className='ticket-accordion-item-text'>{ticket.category.t_name}</div>
            </div>
          </TicketAccordion>
        </div>
        <TicketComments ticket={ticket} customer={customer} offices={offices} />
      </div>
    </div>
  );
};

TicketDetails.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default TicketDetails;
