import React, { useState, useCallback, useMemo } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../shared/ui/components/StepButton';
import { CustomTextField } from '../../shared/ui/components/Field/CustomTextField';
import { FileUploadPreview } from '../../shared/ui/components/Field/FileUploadField';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelectField } from '../../shared/ui/components/Select';
import { createTicket } from '../../services/workki/TicketsActions';
import { ticketCategoriesSelector } from '../../store/selectors/TicketsSelectors';
import { officeSelector } from '../../store/selectors/DefaultSelectors';
import { serviceRequestWithWorkplacesSelector } from '../../store/selectors/ServicesSelector';
import moment from 'moment';
import { find } from 'lodash';
import { SUPER_CATEGORIES } from '../../components/Tickets/constants';
import { FileContainer } from '../Services/Print/PrintFileWidget';
import { IconComponent } from '../../shared/ui/components/Field/IconComponent';

const CreateTicketWidget = ({ handleClose, handleSuccess }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedSuperCategory, setSelectedSuperCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedService, setSelectedService] = useState();
  const [error, setError] = useState('');

  const ticketCategories = useSelector(ticketCategoriesSelector);
  const offices = useSelector(officeSelector);
  const activeWorkplaces = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: moment().subtract(14, 'days'),
      selectorType: 'standard'
    })
  );

  const workplaces = useMemo(() => {
    return activeWorkplaces.map(wp => {
      const office = find(offices, o => o.id === wp.reservation_place.office_id);
      return {
        value: wp.reservation_place.id,
        label: `${wp.reservation_place.name} (${office ? office.t_name : 'Неизвестный офис'})`
      };
    });
  }, [activeWorkplaces, offices]);

  const superCategoryOptions = useMemo(() => SUPER_CATEGORIES.map(sc => ({ value: sc.id, label: sc.name })), []);

  const categoryOptions = useMemo(() => {
    if (!selectedSuperCategory) return [];
    const superCategory = SUPER_CATEGORIES.find(sc => sc.id === selectedSuperCategory);
    const categoryIds = superCategory ? superCategory.categories : [];
    return ticketCategories.filter(tc => categoryIds.includes(tc.id)).map(tc => ({ value: tc.id, label: tc.name }));
  }, [selectedSuperCategory, ticketCategories]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      const ticketData = {
        category_id: selectedCategory,
        description: message,
        service_id: selectedService,
        file: files.length > 0 ? files[0] : undefined
      };
      dispatch(createTicket(ticketData)).then(response => {
        if (response.status === 201) {
          handleClose();
          handleSuccess();
        } else if (response.response && response.response.data) {
          const err = response.response.data[0];
          if (err.message) {
            setError(err.message);
          } else {
            setError(`Не удалось отправить данные. Код ошибки: ${err.code}`);
          }
        }
      });
    },
    [message, files, selectedCategory, selectedService, dispatch, handleClose, handleSuccess]
  );

  const handleRemoveFile = () => {
    setFiles([]);
  };

  return (
    <div>
      <FormControl fullWidth>
        <div className='ticket-modal-subtitle'>Общие данные</div>
        <CustomSelectField
          label='Рабочее место*'
          value={selectedService}
          onChange={e => setSelectedService(e.target.value)}
          options={workplaces}
          variant={'filled'}
        />
        <CustomSelectField
          label='Категория запроса*'
          value={selectedSuperCategory}
          onChange={e => setSelectedSuperCategory(e.target.value)}
          options={superCategoryOptions}
          variant={'filled'}
        />
        <CustomSelectField
          label='Подкатегория*'
          value={selectedCategory}
          onChange={e => setSelectedCategory(e.target.value)}
          options={categoryOptions}
          disabled={!selectedSuperCategory}
          variant={'filled'}
        />
        <CustomTextField
          error={error}
          helperText={error}
          label='Описание проблемы*'
          value={message}
          onChange={e => setMessage(e.target.value)}
          variant={'filled'}
        />
        <div className='ticket-modal-files-text'>Фото (необязательно)</div>
        <FileUploadPreview
          fieldType='changeCredentials'
          files={files}
          setFiles={newFiles => {
            if (files.length === 0) {
              setFiles(newFiles);
            } else {
              handleRemoveFile();
              setFiles(newFiles);
            }
          }}
        />
        {files.length > 0 && (
          <FileContainer>
            <p>Прикрепленные файлы</p>
            {files.map((file, index) => (
              <div key={index}>
                <span>{file.name}</span>
                <IconComponent type={'remove'} handleAction={() => handleRemoveFile(file.name)} />
              </div>
            ))}
          </FileContainer>
        )}
        <FormControl fullWidth style={{ marginTop: '32px' }}>
          <StepButton
            filledType='black'
            type='filled'
            title={'Отправить'}
            handleAction={handleSubmit}
            disabled={!message || !selectedCategory || !selectedService}
          />
        </FormControl>
      </FormControl>
    </div>
  );
};

export default CreateTicketWidget;
