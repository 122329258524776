import { WorkplaceType, AreaType } from '../types';

export const CITY_FLOOR_AREAS = [
  {
    name: 'C31',
    uuid: 'area-C31',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M136.938 115.106L430.897 182.932L435.164 168.393L438.878 169.499L443.303 150.614L445.041 151.009L451.836 120.745L450.078 120.272L461.279 71.3132L413.632 61.2871L359.977 50.9474L304.366 40.6076L248.196 30.5474L166.592 17.5117L150.86 68.6048L136.938 115.106Z',
    workplaces: [
      //верх
      { uuid: 'wp-31-1', name: 'wp-31-1', position: { x: 190, y: 35, angle: 192 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-2', name: 'wp-31-2', position: { x: 228, y: 43, angle: 192 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-3', name: 'wp-31-3', position: { x: 266, y: 51, angle: 192 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-4', name: 'wp-31-4', position: { x: 304, y: 59, angle: 192 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-5', name: 'wp-31-5', position: { x: 342, y: 67, angle: 192 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-6', name: 'wp-31-6', position: { x: 380, y: 75, angle: 192 }, type: WorkplaceType.DEFAULT },
      // слева снизу
      { uuid: 'wp-31-7', name: 'wp-31-7', position: { x: 173, y: 83, angle: 13 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-8', name: 'wp-31-8', position: { x: 211, y: 92, angle: 13 }, type: WorkplaceType.DEFAULT },
      // справа снизу
      { uuid: 'wp-31-9', name: 'wp-31-9', position: { x: 297, y: 110, angle: 13 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-10', name: 'wp-31-10', position: { x: 335, y: 119, angle: 13 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-31-11', name: 'wp-31-11', position: { x: 373, y: 128, angle: 13 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C30',
    uuid: 'area-C30',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M147.869 294.144L177.86 302.225L220.699 144.188L134.189 124.344L122.617 173.971L109.539 226.605L100.93 276.397L149.295 289.832L147.869 294.144Z',
    workplaces: [
      { uuid: 'wp-30-1', name: 'wp-30-1', position: { x: 133, y: 152, angle: 102 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-30-2', name: 'wp-30-2', position: { x: 125, y: 190, angle: 102 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-30-3', name: 'wp-30-3', position: { x: 117, y: 228, angle: 102 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C32',
    uuid: 'area-C32',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M219.507 314.408L249.206 322.464L280.227 205.408C280.227 205.408 267.575 212.793 255.075 210.154C241.168 207.219 233.512 195.326 233.512 195.326C233.512 195.326 226.093 186.046 227.694 170.938C228.948 159.11 240.528 148.884 240.528 148.884L223.513 144.471L182.383 298.768L220.771 309.826L219.507 314.408Z',
    workplaces: [
      { uuid: 'wp-32-1', name: 'wp-32-1', position: { x: 202, y: 216, angle: 105 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-32-2', name: 'wp-32-2', position: { x: 192, y: 254, angle: 105 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C33',
    uuid: 'area-C33',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M283.655 327.106L320.483 337.336L363.533 176.928L293.747 161.021C293.747 161.021 300.378 173.536 295.037 187.228C289.422 201.62 280.06 206.019 280.06 206.019L279.298 208.686L282.915 210.018L252.625 323.209L282.389 331.734L283.655 327.106Z',
    workplaces: [
      { uuid: 'wp-33-1', name: 'wp-33-1', position: { x: 315, y: 175, angle: 285 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-33-2', name: 'wp-33-2', position: { x: 305, y: 213, angle: 285 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-33-3', name: 'wp-33-3', position: { x: 295, y: 251, angle: 285 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-33-4', name: 'wp-33-4', position: { x: 285, y: 289, angle: 285 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C34',
    uuid: 'area-C34',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M428.514 192.07L367.048 178.312L335.828 294.552L416.364 315.715L421.727 292.367L423.582 292.897L431.066 262.299L409.607 256.471L428.514 192.07Z',
    workplaces: [
      { uuid: 'wp-34-1', name: 'wp-34-1', position: { x: 363, y: 184, angle: 105 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-34-2', name: 'wp-34-2', position: { x: 353, y: 222, angle: 105 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-34-3', name: 'wp-34-3', position: { x: 343, y: 260, angle: 105 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C35',
    uuid: 'area-C35',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M416.302 319.286L334.874 297.812L323.867 337.798L403.47 360.19L406.499 349.318H408.104L416.302 319.286Z',
    workplaces: [
      { uuid: 'wp-35-1', name: 'wp-35-1', position: { x: 331, y: 304, angle: 105 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C28',
    uuid: 'area-C28',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M70.5469 435.45L186.532 453.667L195.085 395.027L169.399 387.902L178.155 356.715L148.284 348.225L147.299 350.86L88.9428 334.547L79.0665 384.438L70.5469 435.45Z',
    workplaces: [
      // верхнее
      { uuid: 'wp-28-1', name: 'wp-28-1', position: { x: 99, y: 346, angle: 196 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-28-2', name: 'wp-28-2', position: { x: 110, y: 405, angle: 9 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-28-3', name: 'wp-28-3', position: { x: 149, y: 411, angle: 9 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C29',
    uuid: 'area-C29',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M191.867 454.583L274.215 466.869L279.313 387.92L250.556 379.424L242.191 408.18L200.494 396.416L191.867 454.583Z',
    workplaces: [
      { uuid: 'wp-29-1', name: 'wp-29-1', position: { x: 195, y: 418, angle: 9 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-29-2', name: 'wp-29-2', position: { x: 234, y: 424, angle: 9 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C26',
    uuid: 'area-C26',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M54.7891 540.698L61.5049 541.898L63.9572 527.233L146.407 539.722L146.031 542.508L172.252 545.939L185.76 458.534L69.6773 441.605L61.5049 491.578L54.7891 540.698Z',
    workplaces: [
      // нижние
      { uuid: 'wp-26-1', name: 'wp-26-1', position: { x: 70, y: 491, angle: 9 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-26-2', name: 'wp-26-2', position: { x: 109, y: 497, angle: 9 }, type: WorkplaceType.DEFAULT },
      //верхние
      { uuid: 'wp-26-3', name: 'wp-26-3', position: { x: 103, y: 450, angle: 189 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-26-4', name: 'wp-26-4', position: { x: 142, y: 456, angle: 189 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C23',
    uuid: 'area-C23',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M241.141 822.275L242.83 797.798L245.572 798.059L248.086 766.786L245.27 766.334L247.777 733.854L50.9238 714.865H43.6469V710.946L32.4581 710.121L27.9088 759.431L23.8281 808.886L241.141 822.275Z',
    workplaces: [
      //верхние
      { uuid: 'wp-23-1', name: 'wp-23-1', position: { x: 40, y: 717, angle: 185 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-2', name: 'wp-23-2', position: { x: 79, y: 720.5, angle: 185 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-3', name: 'wp-23-3', position: { x: 119, y: 724, angle: 185 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-4', name: 'wp-23-4', position: { x: 159, y: 728, angle: 185 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-23-5', name: 'wp-23-5', position: { x: 35, y: 770, angle: 4 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-6', name: 'wp-23-6', position: { x: 74, y: 772.5, angle: 4 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-7', name: 'wp-23-7', position: { x: 114, y: 775, angle: 4 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-8', name: 'wp-23-8', position: { x: 154, y: 777.5, angle: 4 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-23-9', name: 'wp-23-9', position: { x: 194, y: 780, angle: 4 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C24',
    uuid: 'area-C24',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M51.8857 703.384L51.3937 709.119L110.969 714.845L126.38 625.16H128.594L135.954 581.623L137.664 582.176L143.153 544.561L72.8309 533.707L69.3543 552.458L54.0346 550.041L46.7531 598.92L38.8858 655.913L33.9062 701.301L51.8857 703.384Z',
    workplaces: [
      { uuid: 'wp-24-1', name: 'wp-24-1', position: { x: 70, y: 540, angle: 98 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-24-2', name: 'wp-24-2', position: { x: 64, y: 580, angle: 98 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-24-3', name: 'wp-24-3', position: { x: 58, y: 620, angle: 98 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-24-4', name: 'wp-24-4', position: { x: 52, y: 660, angle: 98 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C25',
    uuid: 'area-C25',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M131.479 626.211L116.445 715.466L248.008 729.26L258.07 611.299L220.391 606.693L214.969 640.306L131.479 626.211Z',
    workplaces: [
      //верхние
      { uuid: 'wp-25-1', name: 'wp-25-1', position: { x: 129, y: 630, angle: 189 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-25-2', name: 'wp-25-2', position: { x: 168, y: 636.5, angle: 189 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-25-3', name: 'wp-25-3', position: { x: 122, y: 675, angle: 7 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-25-4', name: 'wp-25-4', position: { x: 162, y: 680, angle: 7 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-25-5', name: 'wp-25-5', position: { x: 202, y: 684, angle: 7 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C27',
    uuid: 'area-C27',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M237.137 553.571L236.673 556.119L268.18 560.984L273.541 472.29L190.99 460.059L178.062 544.459L237.137 553.571Z',
    workplaces: [
      // нижние
      { uuid: 'wp-27-1', name: 'wp-27-1', position: { x: 182, y: 507, angle: 9 }, type: WorkplaceType.DEFAULT },
      //верхние
      { uuid: 'wp-27-3', name: 'wp-27-3', position: { x: 188, y: 463, angle: 189 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-27-4', name: 'wp-27-4', position: { x: 227, y: 469, angle: 189 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C17',
    uuid: 'area-C17',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M106.196 931.286L112.674 858.3L114.461 823.789L22.8872 818.02L18.3379 867.329L15.1094 916.784H32.1326V927.351L74.2576 927.833V930.916L106.196 931.286Z',
    workplaces: [
      //верхние
      { uuid: 'wp-17-1', name: 'wp-17-1', position: { x: 30, y: 820, angle: 184 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-17-2', name: 'wp-17-2', position: { x: 70, y: 823, angle: 184 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-17-3', name: 'wp-17-3', position: { x: 35, y: 885, angle: 1 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C19',
    uuid: 'area-C19',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M169.409 929.07L174.319 862.413L116.934 859.164L111.82 931.074L144.15 931.476V928.58L169.409 929.07Z',
    workplaces: [
      { uuid: 'wp-19-1', name: 'wp-19-1', position: { x: 123, y: 862, angle: 184 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C21',
    uuid: 'area-C21',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M240.036 831.616L188.873 828.364L186.556 863.709L179.66 863.127L174.977 929.077L232.679 929.72L235.249 895.921H237.819L240.646 863.709H237.819L240.036 831.616Z',
    workplaces: [
      { uuid: 'wp-21-1', name: 'wp-21-1', position: { x: 190, y: 830, angle: 184 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-21-2', name: 'wp-21-2', position: { x: 185, y: 888, angle: 1 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C13',
    uuid: 'area-C13',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M12.7344 980.019L12.7344 1079.11H92.1408V1052.95H94.9574L95.4165 975.023H63.6856V980.019L12.7344 980.019Z',
    workplaces: [
      { uuid: 'wp-13-1', name: 'wp-13-1', position: { x: 24, y: 980, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-13-2', name: 'wp-13-2', position: { x: 28, y: 1038, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C15',
    uuid: 'area-C15',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M100.859 980.019L100.859 1052.95H185.5L182.364 980.019H176.927V975.023H144.439V980.019H100.859Z',
    workplaces: [
      { uuid: 'wp-15-1', name: 'wp-15-1', position: { x: 103, y: 1012, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-15-2', name: 'wp-15-2', position: { x: 144, y: 1012, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C11',
    uuid: 'area-C11',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M92.3691 1084.52L13.2892 1084.52L13.2891 1187.22H191.357L189.841 1151.5H194.506L192.835 1119.53H188.504L187.46 1096.24H92.3691V1084.52Z',
    workplaces: [
      //верхние
      { uuid: 'wp-11-1', name: 'wp-11-1', position: { x: 30, y: 1086, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-11-2', name: 'wp-11-2', position: { x: 98, y: 1097, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-11-3', name: 'wp-11-3', position: { x: 143, y: 1097, angle: 180 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-11-4', name: 'wp-11-4', position: { x: 20, y: 1145, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-11-5', name: 'wp-11-5', position: { x: 60, y: 1145, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-11-6', name: 'wp-11-6', position: { x: 100, y: 1145, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-11-7', name: 'wp-11-7', position: { x: 140, y: 1145, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C9',
    uuid: 'area-C9',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M197.688 1225.31L200.842 1256.99H198.04L203.573 1321.15L185.207 1321.74L184.501 1291.48L109.595 1292.81L110.417 1322.44L32.3452 1324.02V1346.54L21.4478 1347.16L17.6762 1299.83L14.316 1244.48L12.7344 1192.92L191.899 1192.69L194.97 1225.56L197.688 1225.31Z',
    workplaces: [
      //верхние
      { uuid: 'wp-9-1', name: 'wp-9-1', position: { x: 20, y: 1195, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-9-2', name: 'wp-9-2', position: { x: 60, y: 1195, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-9-3', name: 'wp-9-3', position: { x: 100, y: 1195, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-9-4', name: 'wp-9-4', position: { x: 140, y: 1195, angle: 180 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-9-5', name: 'wp-9-5', position: { x: 50, y: 1280, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-9-6', name: 'wp-9-6', position: { x: 106, y: 1250, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-9-7', name: 'wp-9-7', position: { x: 148, y: 1250, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C7',
    uuid: 'area-C7',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M210.244 1362.64L213.39 1395.11L210.602 1395.61L214.248 1434.22L30.5477 1456.5L21.6719 1356.61L38.1259 1355.81V1328.92L204.888 1326.14L207.67 1363.14L210.244 1362.64Z',
    workplaces: [
      //верхние
      { uuid: 'wp-7-1', name: 'wp-7-1', position: { x: 40, y: 1330, angle: 179 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7-2', name: 'wp-7-2', position: { x: 80, y: 1329.5, angle: 179 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7-3', name: 'wp-7-3', position: { x: 120, y: 1329, angle: 179 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7-4', name: 'wp-7-4', position: { x: 160, y: 1328.5, angle: 179 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-7-5', name: 'wp-7-5', position: { x: 49, y: 1411, angle: -7 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7-6', name: 'wp-7-6', position: { x: 89, y: 1406, angle: -7 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7-7', name: 'wp-7-7', position: { x: 129, y: 1401, angle: -7 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7-8', name: 'wp-7-8', position: { x: 169, y: 1396, angle: -7 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C5',
    uuid: 'area-C5',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M219.981 1468.35L222.978 1500.37H219.981L223.311 1537.44L214.721 1538.42L210.752 1512.72L121.89 1525.16L125.9 1551.44L44.6034 1563.36L32.2422 1461.69L111.94 1451.97L214.431 1439.93L217.317 1468.74L219.981 1468.35Z',
    workplaces: [
      //верхние
      { uuid: 'wp-5-1', name: 'wp-5-1', position: { x: 49, y: 1461, angle: 173 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-5-2', name: 'wp-5-2', position: { x: 89, y: 1456, angle: 173 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-5-3', name: 'wp-5-3', position: { x: 129, y: 1451, angle: 173 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-5-4', name: 'wp-5-4', position: { x: 169, y: 1446, angle: 173 }, type: WorkplaceType.DEFAULT },

      // нижние
      { uuid: 'wp-5-5', name: 'wp-5-5', position: { x: 60, y: 1517, angle: -8 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C3',
    uuid: 'area-C3',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M231.375 1590.86L228.47 1592.1L230.087 1641.9L61.9039 1670.88L53.5887 1622.58L45.0781 1568.83L126.332 1557.3L128.863 1569.64L217.137 1557.3L215.714 1544.49L223.94 1543.22L225.382 1559.99H228.47L231.375 1590.86Z',
    workplaces: [
      //верхние
      { uuid: 'wp-3-1', name: 'wp-3-1', position: { x: 66, y: 1565, angle: 173 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-3-2', name: 'wp-3-2', position: { x: 65, y: 1624.5, angle: -9 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-3-3', name: 'wp-3-3', position: { x: 105, y: 1618, angle: -9 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-3-4', name: 'wp-3-4', position: { x: 145, y: 1611.5, angle: -9 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-3-5', name: 'wp-3-5', position: { x: 185, y: 1605, angle: -9 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C2',
    uuid: 'area-C2',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M375.861 1584.42H294.102L295.663 1613.42H294.102L296.444 1645.99H302.133C302.133 1645.99 301.099 1676.56 338.383 1676.56C373.184 1676.56 375.861 1649.67 375.861 1649.67V1584.42Z',
    workplaces: [
      //верхние
      { uuid: 'wp-2-1', name: 'wp-2-1', position: { x: 317, y: 1585, angle: 180 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-2-2', name: 'wp-2-2', position: { x: 320, y: 1631, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C1',
    uuid: 'area-C1',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M245.724 1710.72L240.843 1711.41L242.636 1745.76L172.176 1762.55L178.634 1789.96L100.328 1807.25L101.683 1813.11L90.3801 1816.2L78.9804 1762.55L62.5703 1676.14L236.951 1646.7L238.833 1678.83L243.926 1678.35L245.724 1710.72Z',
    workplaces: [
      //верхние
      { uuid: 'wp-1-1', name: 'wp-1-1', position: { x: 71, y: 1673, angle: 171 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-1-2', name: 'wp-1-2', position: { x: 111, y: 1666.5, angle: 171 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-1-3', name: 'wp-1-3', position: { x: 151, y: 1660, angle: 171 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-1-4', name: 'wp-1-4', position: { x: 191, y: 1653.5, angle: 171 }, type: WorkplaceType.DEFAULT },

      // нижние
      { uuid: 'wp-1-5', name: 'wp-1-5', position: { x: 90, y: 1761, angle: -12 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-1-6', name: 'wp-1-6', position: { x: 130, y: 1752, angle: -12 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-1-7', name: 'wp-1-7', position: { x: 161, y: 1718, angle: -12 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-1-8', name: 'wp-1-8', position: { x: 199, y: 1710, angle: -12 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C4',
    uuid: 'area-C4',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M441.629 1512.94L288.727 1512.59L291.069 1547.38L289.224 1547.78L291.069 1574.16L291.563 1580.63H441.629V1512.94Z',
    workplaces: [
      { uuid: 'wp-4-1', name: 'wp-4-1', position: { x: 300, y: 1515, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-4-2', name: 'wp-4-2', position: { x: 340, y: 1515, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-4-3', name: 'wp-4-3', position: { x: 380, y: 1515, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C6',
    uuid: 'area-C6',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M427.484 1439.66L282.125 1439.66L284.176 1471.85H286.021L288.661 1509.16H441.158V1491.95H427.484V1439.66Z',
    workplaces: [
      { uuid: 'wp-6-1', name: 'wp-6-1', position: { x: 300, y: 1467, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-6-2', name: 'wp-6-2', position: { x: 340, y: 1467, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-6-3', name: 'wp-6-3', position: { x: 380, y: 1467, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C10',
    uuid: 'area-C10',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M436.166 1274H272.445L274.884 1309.4H273.451L275.16 1342.21H436.166V1274Z',
    workplaces: [
      { uuid: 'wp-10-1', name: 'wp-10-1', position: { x: 300, y: 1275, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-10-2', name: 'wp-10-2', position: { x: 340, y: 1275, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-10-3', name: 'wp-10-3', position: { x: 380, y: 1275, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C8',
    uuid: 'area-C8',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M436.492 1345.96H277.664L279.51 1374.24H277.664L279.51 1406.25L281.446 1406L283.672 1436.16H427.825V1407.78H436.492V1345.96Z',
    workplaces: [
      // верхние
      { uuid: 'wp-8-1', name: 'wp-8-1', position: { x: 310, y: 1347, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-8-2', name: 'wp-8-2', position: { x: 350, y: 1347, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-8-3', name: 'wp-8-3', position: { x: 390, y: 1347, angle: 180 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-8-4', name: 'wp-8-4', position: { x: 300, y: 1395, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-8-5', name: 'wp-8-5', position: { x: 340, y: 1395, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-8-6', name: 'wp-8-6', position: { x: 380, y: 1395, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C12',
    uuid: 'area-C12',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M436.164 917.359H306.541L304.369 942.852H306.349L305.778 949.732H303.742L302.359 984.003L436.164 984.571V917.359Z',
    workplaces: [
      { uuid: 'wp-12-1', name: 'wp-12-1', position: { x: 315, y: 943, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-12-2', name: 'wp-12-2', position: { x: 355, y: 943, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-12-3', name: 'wp-12-3', position: { x: 395, y: 943, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C14',
    uuid: 'area-C14',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M436.164 848H312.935L311.242 873.793H309.504L307.062 905.934H308.915L308.325 914.101L436.164 915.212V848Z',
    workplaces: [
      { uuid: 'wp-14-1', name: 'wp-14-1', position: { x: 315, y: 850, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-14-2', name: 'wp-14-2', position: { x: 355, y: 850, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-14-3', name: 'wp-14-3', position: { x: 395, y: 850, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C16',
    uuid: 'area-C16',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M436.161 780.789H316.873L314.417 811.857H316.193L313.766 844.17H436.161L436.161 780.789Z',
    workplaces: [
      { uuid: 'wp-16-1', name: 'wp-16-1', position: { x: 315, y: 802, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-16-2', name: 'wp-16-2', position: { x: 355, y: 802, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-16-3', name: 'wp-16-3', position: { x: 395, y: 802, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C18',
    uuid: 'area-C18',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M429.999 691.898L325.098 691.895L323.007 719.637H321.23L318.672 752.111H320.678L318.672 777.106H436.168V735.686H429.824L429.999 691.898Z',
    workplaces: [
      // Верхние
      { uuid: 'wp-18-1', name: 'wp-18-1', position: { x: 345, y: 693, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-18-2', name: 'wp-18-2', position: { x: 385, y: 693, angle: 180 }, type: WorkplaceType.DEFAULT },
      // Нижние
      { uuid: 'wp-18-3', name: 'wp-18-3', position: { x: 345, y: 736, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-18-4', name: 'wp-18-4', position: { x: 385, y: 736, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C20',
    uuid: 'area-C20',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M443.983 623.772L328.178 623.566L325.978 655.55H328.194L325.203 688.082H430.153V650.767H443.983V623.772Z',
    workplaces: [
      { uuid: 'wp-20-1', name: 'wp-20-1', position: { x: 345, y: 647, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-20-2', name: 'wp-20-2', position: { x: 385, y: 647, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C22',
    uuid: 'area-C22',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M391.759 517.936L395.232 575.56H444.542V620.433H330.703L332.983 586.932H331.252L333.725 554.531H335.456V517.184C335.456 517.184 333.849 483.902 362.446 483.902C393.053 483.902 391.759 517.936 391.759 517.936Z',
    workplaces: [
      // Верхние
      { uuid: 'wp-22-1', name: 'wp-22-1', position: { x: 345, y: 497, angle: 180 }, type: WorkplaceType.DEFAULT },
      // Нижние
      { uuid: 'wp-22-2', name: 'wp-22-2', position: { x: 345, y: 578, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-22-3', name: 'wp-22-3', position: { x: 385, y: 578, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C64',
    uuid: 'area-C64',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1064.61 1671.73L1091.21 1657.66L1072.2 1621.96L1021.09 1640.34L1019.5 1635.27L988.769 1646.46L990.459 1651L940.055 1669.36L990.634 1789.66L1102.05 1741.81L1064.61 1671.73Z',
    workplaces: [
      // правые
      { uuid: 'wp-64-1', name: 'wp-64-1', position: { x: 1042, y: 1629, angle: 244 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-64-2', name: 'wp-64-2', position: { x: 1035, y: 1677, angle: 242 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-64-3', name: 'wp-64-3', position: { x: 1053, y: 1712, angle: 242 }, type: WorkplaceType.DEFAULT },
      // левые
      { uuid: 'wp-64-4', name: 'wp-64-4', position: { x: 948, y: 1661, angle: 67 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-64-5', name: 'wp-64-5', position: { x: 964, y: 1697, angle: 67 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-64-6', name: 'wp-64-6', position: { x: 980, y: 1735, angle: 67 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C65',
    uuid: 'area-C65',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1158.93 1787.57L1160.74 1786.71L1145.39 1757.61L1143.94 1758.6L1132.75 1736.77L1106.53 1750.67L1103.45 1744.51L991.875 1792.69L1034.4 1893.68L1181.22 1829.65L1158.93 1787.57Z',
    workplaces: [
      // Верхние
      { uuid: 'wp-65-1', name: 'wp-65-1', position: { x: 1000, y: 1784, angle: 157 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-65-2', name: 'wp-65-2', position: { x: 1037, y: 1768, angle: 157 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-65-3', name: 'wp-65-3', position: { x: 1074, y: 1752, angle: 157 }, type: WorkplaceType.DEFAULT },
      // Нижние
      { uuid: 'wp-65-4', name: 'wp-65-4', position: { x: 1035, y: 1835, angle: -23 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-65-5', name: 'wp-65-5', position: { x: 1071, y: 1820, angle: -23 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-65-6', name: 'wp-65-6', position: { x: 1107, y: 1805, angle: -23 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C66',
    uuid: 'area-C66',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M900.956 1837.06L936.935 1931.41L1031.56 1893.92L936.935 1670.6L909.322 1680.54L907.798 1676.29L877.165 1687.19L878.849 1691.44L849.101 1701.93L868.98 1753.91L892.308 1744.1L927.052 1826.25L900.956 1837.06Z',
    workplaces: [
      // правые
      { uuid: 'wp-66-1', name: 'wp-66-1', position: { x: 914, y: 1697, angle: 247 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-66-2', name: 'wp-66-2', position: { x: 929.5, y: 1735, angle: 247 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-66-3', name: 'wp-66-3', position: { x: 945, y: 1771, angle: 247 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-66-4', name: 'wp-66-4', position: { x: 960.5, y: 1808, angle: 247 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-66-5', name: 'wp-66-5', position: { x: 976, y: 1845, angle: 247 }, type: WorkplaceType.DEFAULT },
      // левые
      { uuid: 'wp-66-6', name: 'wp-66-6', position: { x: 860, y: 1699, angle: 68 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-66-7', name: 'wp-66-7', position: { x: 910, y: 1830, angle: 68 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-66-8', name: 'wp-66-8', position: { x: 925, y: 1868, angle: 68 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C67',
    uuid: 'area-C67',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M845.504 1703.25L809.957 1715.82L808.355 1711.5L778.834 1721.98L780.513 1726.56L741.055 1740.45L830.753 1969.84L885.586 1951.06L934.905 1931.81L897.73 1838.62L881.733 1845.11L846.713 1763.08L866.139 1755.39L845.504 1703.25Z',
    workplaces: [
      // левые
      { uuid: 'wp-67-1', name: 'wp-67-1', position: { x: 758, y: 1754, angle: 68 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-67-2', name: 'wp-67-2', position: { x: 773.5, y: 1792, angle: 68 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-67-3', name: 'wp-67-3', position: { x: 789, y: 1828, angle: 68 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-67-4', name: 'wp-67-4', position: { x: 804.5, y: 1865, angle: 68 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-67-5', name: 'wp-67-5', position: { x: 820, y: 1902, angle: 68 }, type: WorkplaceType.DEFAULT },
      // правые
      { uuid: 'wp-67-6', name: 'wp-67-6', position: { x: 815, y: 1717, angle: 247 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-67-7', name: 'wp-67-7', position: { x: 865, y: 1848, angle: 247 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-67-8', name: 'wp-67-8', position: { x: 880, y: 1886, angle: 247 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C68',
    uuid: 'area-C68',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M738.105 1741.84L650.891 1772.9L661.279 1804.23L662.916 1803.62L672.84 1832.47L761.438 1801.98L738.105 1741.84Z',
    workplaces: [
      { uuid: 'wp-68-1', name: 'wp-68-1', position: { x: 670, y: 1784, angle: -19 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-68-2', name: 'wp-68-2', position: { x: 710, y: 1770, angle: -19 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C69',
    uuid: 'area-C69',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M762.614 1805.41L673.734 1835.32L694.985 1900.9L693.214 1901.61L700.855 1926.59L797.039 1893.83L762.614 1805.41Z',
    workplaces: [
      // верхние
      { uuid: 'wp-69-1', name: 'wp-69-1', position: { x: 684, y: 1827, angle: 161 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-69-2', name: 'wp-69-2', position: { x: 724, y: 1813, angle: 161 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-69-3', name: 'wp-69-3', position: { x: 743, y: 1862, angle: -19 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C70',
    uuid: 'area-C70',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M798.584 1897.13L702.164 1929.8L712.083 1960.17L714.022 1959.54L728.353 2004.01L778.561 1988.05L827.315 1971.13L798.584 1897.13Z',
    workplaces: [
      { uuid: 'wp-70-1', name: 'wp-70-1', position: { x: 728, y: 1953, angle: -19 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-70-2', name: 'wp-70-2', position: { x: 768, y: 1939, angle: -19 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C63',
    uuid: 'area-C63',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1394 1724.56L1332.61 1634.05L1318.57 1643.34L1314.64 1637.45L1191.62 1721.19L1207.7 1749.93L1211.91 1747.35L1242.83 1800.36L1285.86 1780.69L1335.07 1756.36L1394 1724.56Z',
    workplaces: [
      // верхние
      { uuid: 'wp-63-1', name: 'wp-63-1', position: { x: 1290, y: 1650, angle: 146 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-63-2', name: 'wp-63-2', position: { x: 1256, y: 1673, angle: 146 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-63-3', name: 'wp-63-3', position: { x: 1233, y: 1749, angle: -31 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-63-4', name: 'wp-63-4', position: { x: 1268, y: 1728, angle: -31 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-63-5', name: 'wp-63-5', position: { x: 1303, y: 1707, angle: -31 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-63-6', name: 'wp-63-6', position: { x: 1338, y: 1686, angle: -31 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C62',
    uuid: 'area-C62',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1253.36 1675.13L1203.82 1603.3L1176.57 1622.01L1175.55 1620.59L1149.11 1639.2L1193.92 1716.2L1253.36 1675.13Z',
    workplaces: [
      { uuid: 'wp-62-1', name: 'wp-62-1', position: { x: 1178, y: 1614, angle: 236 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-62-2', name: 'wp-62-2', position: { x: 1201, y: 1648, angle: 236 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C61',
    uuid: 'area-C61',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1283.6 1559.42L1278.81 1551.79L1260.71 1564.51L1259.66 1562.98L1233.87 1580.67L1234.77 1582.31L1207.14 1601.14L1255.95 1673.17L1312.72 1634.32L1269.98 1568.39L1283.6 1559.42Z',
    workplaces: [
      { uuid: 'wp-61-1', name: 'wp-61-1', position: { x: 1218, y: 1588, angle: 56 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-61-2', name: 'wp-61-2', position: { x: 1240, y: 1622, angle: 56 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C60',
    uuid: 'area-C60',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1487.52 1669.35L1368.78 1490.32L1338.03 1511.24L1337.01 1509.8L1310.47 1528.03L1311.31 1529.65L1281.95 1550.05L1286.39 1557.35L1302.94 1546.67L1351.87 1621.96L1336.18 1632.02L1396.11 1723.14L1445.28 1695.52L1487.52 1669.35Z',
    workplaces: [
      // правые
      { uuid: 'wp-60-1', name: 'wp-60-1', position: { x: 1355, y: 1520, angle: 236 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-60-2', name: 'wp-60-2', position: { x: 1377, y: 1553, angle: 236 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-60-3', name: 'wp-60-3', position: { x: 1399, y: 1586, angle: 236 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-60-4', name: 'wp-60-4', position: { x: 1421, y: 1619, angle: 236 }, type: WorkplaceType.DEFAULT },
      // левые
      { uuid: 'wp-60-5', name: 'wp-60-5', position: { x: 1402, y: 1670, angle: 57 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-60-6', name: 'wp-60-6', position: { x: 1348, y: 1620, angle: 57 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C59',
    uuid: 'area-C59',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1371.94 1488.14L1489.87 1667.44L1537.59 1638.42L1582.01 1607.45L1455.01 1431.26L1426.96 1450.42L1426.07 1448.9L1399.42 1467.17L1400.37 1468.51L1371.94 1488.14Z',
    workplaces: [
      // левые
      { uuid: 'wp-59-1', name: 'wp-59-1', position: { x: 1395, y: 1494, angle: 57 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-59-2', name: 'wp-59-2', position: { x: 1417, y: 1527, angle: 57 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-59-3', name: 'wp-59-3', position: { x: 1439, y: 1560, angle: 57 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-59-4', name: 'wp-59-4', position: { x: 1461, y: 1593, angle: 57 }, type: WorkplaceType.DEFAULT },
      // правые
      { uuid: 'wp-59-5', name: 'wp-59-5', position: { x: 1514, y: 1558, angle: 234 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-59-6', name: 'wp-59-6', position: { x: 1491, y: 1526, angle: 234 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-59-7', name: 'wp-59-7', position: { x: 1468, y: 1494, angle: 234 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-59-8', name: 'wp-59-8', position: { x: 1445, y: 1462, angle: 234 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C58',
    uuid: 'area-C58',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1522.77 1519.97L1585.03 1605.51L1629.37 1574.7L1672.77 1542.41L1622.85 1480.96L1624.02 1480.14L1603.73 1455.86L1552.22 1494.27L1553.82 1496.91L1522.77 1519.97Z',
    workplaces: [
      // левые
      { uuid: 'wp-58-1', name: 'wp-58-1', position: { x: 1534, y: 1505, angle: 53 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-58-2', name: 'wp-58-2', position: { x: 1560, y: 1540, angle: 53 }, type: WorkplaceType.DEFAULT },
      // правые
      { uuid: 'wp-58-3', name: 'wp-58-3', position: { x: 1608, y: 1504, angle: 231 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C57',
    uuid: 'area-C57',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1457.23 1429.75L1475.85 1455.18L1505.94 1433.59L1549.8 1491.24L1600.42 1453.67L1557.49 1401.2L1558.95 1399.91L1538.33 1374.03L1457.23 1429.75Z',
    workplaces: [
      { uuid: 'wp-57-1', name: 'wp-57-1', position: { x: 1510, y: 1410, angle: 53 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-57-2', name: 'wp-57-2', position: { x: 1534, y: 1442, angle: 53 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C56',
    uuid: 'area-C56',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1794.93 1447.12L1727.14 1371.28L1715.19 1382.18L1713.74 1380.81L1638.55 1430.85L1659.94 1455.32L1661.11 1454.04L1712.62 1511.99L1756.57 1478.89L1794.93 1447.12Z',
    workplaces: [
      // левые
      { uuid: 'wp-56-1', name: 'wp-56-1', position: { x: 1690, y: 1456, angle: 48 }, type: WorkplaceType.DEFAULT },
      // правые
      { uuid: 'wp-56-2', name: 'wp-56-2', position: { x: 1740, y: 1420, angle: 229 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-56-3', name: 'wp-56-3', position: { x: 1711, y: 1387, angle: 229 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C55',
    uuid: 'area-C55',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1711.31 1377.78L1677.68 1342.23V1342.23L1604.82 1391.17L1616.23 1403.65L1614.87 1404.98L1635.66 1428.42L1711.31 1377.78Z',
    workplaces: [
      { uuid: 'wp-55-1', name: 'wp-55-1', position: { x: 1655, y: 1354, angle: 229 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C54',
    uuid: 'area-C54',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1675.54 1339.34L1642.84 1303.14V1303.14L1621.96 1316.41L1570.41 1352.17L1581.37 1364.73L1580.29 1366.03L1601.18 1389.51L1675.54 1339.34Z',
    workplaces: [
      { uuid: 'wp-54-1', name: 'wp-54-1', position: { x: 1618, y: 1317, angle: 229 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C53',
    uuid: 'area-C53',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1734.27 1364.56L1802.52 1441.02L1847.49 1403.9L1922.11 1338.95L1880.08 1286.63L1878.98 1287.73L1861.95 1267.52L1741.62 1351.7L1744.19 1355.5L1734.27 1364.56Z',
    workplaces: [
      // верхние
      { uuid: 'wp-53-1', name: 'wp-53-1', position: { x: 1826, y: 1285, angle: 144 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-53-2', name: 'wp-53-2', position: { x: 1793, y: 1309, angle: 144 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-53-3', name: 'wp-53-3', position: { x: 1760, y: 1333, angle: 144 }, type: WorkplaceType.DEFAULT },
      // нижние
      { uuid: 'wp-53-4', name: 'wp-53-4', position: { x: 1860, y: 1327, angle: -40 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-53-5', name: 'wp-53-5', position: { x: 1829, y: 1353, angle: -40 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-53-6', name: 'wp-53-6', position: { x: 1799, y: 1379, angle: -40 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C52',
    uuid: 'area-C52',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1674.35 1280.04L1739.11 1349.67L1799.21 1307.09L1740.6 1234.47L1712.39 1253.54L1709.38 1249.03L1682.78 1267.59L1685.69 1271.91L1674.35 1280.04Z',
    workplaces: [
      { uuid: 'wp-52-1', name: 'wp-52-1', position: { x: 1717, y: 1247, angle: 231 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-52-2', name: 'wp-52-2', position: { x: 1743, y: 1279, angle: 231 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C51',
    uuid: 'area-C51',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1743.23 1232.43L1802.33 1305.61L1861.43 1264.22L1840.62 1239.55L1839.54 1240.41L1799.95 1193.5L1743.23 1232.43Z',
    workplaces: [
      { uuid: 'wp-51-1', name: 'wp-51-1', position: { x: 1757, y: 1217, angle: 51 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-51-2', name: 'wp-51-2', position: { x: 1783, y: 1249, angle: 51 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C50',
    uuid: 'area-C50',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1933.99 1258.79L1937.83 1255.71L1970.55 1294.75L2011.89 1256.41L2050.58 1219.35L2085.87 1184.58L2035.66 1135.54L1913.43 1233.68L1933.99 1258.79Z',
    workplaces: [
      { uuid: 'wp-50-1', name: 'wp-50-1', position: { x: 1954, y: 1209, angle: -42 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-50-2', name: 'wp-50-2', position: { x: 1984, y: 1182, angle: -42 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-50-3', name: 'wp-50-3', position: { x: 2014, y: 1155, angle: -42 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C49',
    uuid: 'area-C49',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1907.15 1196.77L1893.14 1208.45L1911.57 1230.38L2032.78 1133.05L1995.54 1096.97C1995.54 1096.97 1989.6 1101.2 1981.13 1100.86C1972.66 1100.52 1967.76 1096.32 1967.76 1096.32L1881.33 1165.75L1907.15 1196.77Z',
    workplaces: [
      { uuid: 'wp-49-1', name: 'wp-49-1', position: { x: 1894, y: 1149, angle: 141 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-49-2', name: 'wp-49-2', position: { x: 1925, y: 1124, angle: 141 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-49-3', name: 'wp-49-3', position: { x: 1975, y: 1110, angle: 225 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C48',
    uuid: 'area-C48',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1814.41 1106.48L1858.24 1159.8L1868.9 1151.34L1879.25 1163.36L1919.38 1130.94L1863.97 1065.96L1839.64 1087.04L1838.73 1085.8L1814.41 1106.48Z',
    workplaces: [
      { uuid: 'wp-48-1', name: 'wp-48-1', position: { x: 1842, y: 1077, angle: 230 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-48-2', name: 'wp-48-2', position: { x: 1868, y: 1108, angle: 230 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C47',
    uuid: 'area-C47',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1867.25 1063.67L1921.8 1128.3L1964.79 1094.15C1964.79 1094.15 1958.34 1087.72 1957.38 1079.39C1956.29 1069.88 1960.87 1063.11 1960.87 1063.11L1916.83 1019.9L1892.71 1040.3L1893.68 1041.64L1867.25 1063.67Z',
    workplaces: [
      { uuid: 'wp-47-1', name: 'wp-47-1', position: { x: 1878, y: 1047, angle: 50 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-47-2', name: 'wp-47-2', position: { x: 1904, y: 1078, angle: 50 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C46',
    uuid: 'area-C46',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M2013.19 1043.41C2040.57 1069.81 2017.27 1104.31 2017.27 1104.31L2092.52 1177.99L2128.19 1142.22L2166.75 1101.95L2199.22 1066.46L2166.68 1031.41L2128.19 992.375L2088.25 952.532L2046.02 912.535L1997.75 953.48L1996.42 952L1971.72 972.995L1972.71 974.802L1925.23 1014.9L1952.45 1041.31C1952.45 1041.31 1985.81 1017.01 2013.19 1043.41Z',
    workplaces: [
      { uuid: 'wp-46-1', name: 'wp-46-1', position: { x: 2016, y: 935, angle: 225 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-2', name: 'wp-46-2', position: { x: 2046, y: 965, angle: 225 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-3', name: 'wp-46-3', position: { x: 2076, y: 995, angle: 225 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-4', name: 'wp-46-4', position: { x: 2106, y: 1025, angle: 225 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-5', name: 'wp-46-5', position: { x: 2136, y: 1055, angle: 225 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-6', name: 'wp-46-6', position: { x: 2103, y: 1087, angle: 314 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-7', name: 'wp-46-7', position: { x: 2066, y: 1119, angle: 44 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-8', name: 'wp-46-8', position: { x: 2036, y: 1090, angle: 44 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-46-9', name: 'wp-46-9', position: { x: 1952.5, y: 1003.5, angle: 0 }, type: WorkplaceType.ROUND }
    ]
  },
  {
    name: 'C45',
    uuid: 'area-C45',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1747.46 789.403L1730.58 810.873L1768.28 843.086L1819.81 829.773L1818.02 827.327L1830.44 813.874L1832.08 814.944L1853.14 791.341L1851.98 790.074L1878.13 761.374L1836.56 726.313L1793.31 691.3V691.297L1725.12 771.738L1747.46 789.403Z',
    workplaces: [
      { uuid: 'wp-45-1', name: 'wp-45-1', position: { x: 1820, y: 745, angle: 221 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-45-2', name: 'wp-45-2', position: { x: 1743, y: 788, angle: 41 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-45-3', name: 'wp-45-3', position: { x: 1740, y: 746, angle: 131 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-45-4', name: 'wp-45-4', position: { x: 1766, y: 716, angle: 131 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C44',
    uuid: 'area-C44',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1576.11 790.754L1602.85 814.027L1599.86 817.735L1621.47 836.511L1624.86 832.882L1647.43 851.974L1716.32 765.355L1722.62 769.743L1790.34 689.045L1706.7 623.255L1707.23 622.574L1576.11 790.754Z',
    workplaces: [
      { uuid: 'wp-44-1', name: 'wp-44-1', position: { x: 1601, y: 749, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-2', name: 'wp-44-2', position: { x: 1625, y: 718, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-3', name: 'wp-44-3', position: { x: 1649, y: 687, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-4', name: 'wp-44-4', position: { x: 1673, y: 656, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-5', name: 'wp-44-5', position: { x: 1641, y: 779, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-6', name: 'wp-44-6', position: { x: 1666, y: 747, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-7', name: 'wp-44-7', position: { x: 1701, y: 719, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-44-8', name: 'wp-44-8', position: { x: 1726, y: 687, angle: 308 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C43',
    uuid: 'area-C43',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1509.99 713.812L1506.18 710.88L1497.09 722.903L1519.7 742.649L1516.92 746.055L1538.62 765.037L1541.8 761.8L1573.38 788.634L1703.94 622.002L1663.93 592.268L1618.17 559.203L1553.39 646.808L1558.22 651.207L1509.99 713.812Z',
    workplaces: [
      { uuid: 'wp-43-1', name: 'wp-43-1', position: { x: 1565, y: 720, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-2', name: 'wp-43-2', position: { x: 1589, y: 689, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-3', name: 'wp-43-3', position: { x: 1613, y: 658, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-4', name: 'wp-43-4', position: { x: 1637, y: 627, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-5', name: 'wp-43-5', position: { x: 1519, y: 693, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-6', name: 'wp-43-6', position: { x: 1544, y: 661, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-7', name: 'wp-43-7', position: { x: 1570, y: 615, angle: 128 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-43-8', name: 'wp-43-8', position: { x: 1596, y: 582, angle: 128 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C42',
    uuid: 'area-C42',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1531.86 501.777L1419.7 656.758L1431.89 667.627L1428.6 671.25L1450.99 690.353L1454.12 686.895L1494.8 721.313L1502.99 708.705L1478.78 689.674L1527.75 627.026L1551.1 644.942L1615.79 557.909L1575.16 529.623L1531.86 501.777Z',
    workplaces: [
      { uuid: 'wp-42-1', name: 'wp-42-1', position: { x: 1432, y: 627, angle: 126 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-42-2', name: 'wp-42-2', position: { x: 1455, y: 595, angle: 126 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-42-3', name: 'wp-42-3', position: { x: 1478, y: 563, angle: 126 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-42-4', name: 'wp-42-4', position: { x: 1501, y: 531, angle: 126 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-42-5', name: 'wp-42-5', position: { x: 1531, y: 585, angle: 308 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-42-6', name: 'wp-42-6', position: { x: 1557, y: 552, angle: 308 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C41',
    uuid: 'area-C41',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1434.67 442.324L1396.91 522.214L1395.27 521.595L1381.56 550.364L1458.65 586.264L1523.91 496.242L1434.67 442.324Z',
    workplaces: [
      { uuid: 'wp-41-1', name: 'wp-41-1', position: { x: 1407, y: 492, angle: 116 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-41-2', name: 'wp-41-2', position: { x: 1424, y: 456, angle: 116 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-41-3', name: 'wp-41-3', position: { x: 1444, y: 523, angle: 306 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-41-4', name: 'wp-41-4', position: { x: 1467, y: 491, angle: 306 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C40',
    uuid: 'area-C40',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1380.07 553.641L1366.86 581.599L1368.55 582.48L1359.33 601.74L1424.73 633.889L1456.87 589.201L1380.07 553.641Z',
    workplaces: [
      { uuid: 'wp-40-1', name: 'wp-40-1', position: { x: 1403, y: 579, angle: 306 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C39',
    uuid: 'area-C39',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1250.26 452.812L1213.75 529.177L1257.56 550.845L1256.81 552.846L1285.83 567.388L1320.13 493.762L1343 504.207L1345.91 497.738L1250.26 452.812Z',
    workplaces: [
      { uuid: 'wp-39-1', name: 'wp-39-1', position: { x: 1223, y: 498, angle: 116 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-39-2', name: 'wp-39-2', position: { x: 1240, y: 462, angle: 116 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C38',
    uuid: 'area-C38',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1251.1 449.208L1347.45 495.04L1358.74 470.572L1360.38 471.317L1374.32 441.729L1372.9 440.76L1385.5 414.377L1341.93 391.498L1290.58 366.742L1251.1 449.208Z',
    workplaces: [
      { uuid: 'wp-38-1', name: 'wp-38-1', position: { x: 1282, y: 378, angle: 206 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-38-2', name: 'wp-38-2', position: { x: 1319, y: 396, angle: 206 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-38-3', name: 'wp-38-3', position: { x: 1262, y: 418, angle: 26 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-38-4', name: 'wp-38-4', position: { x: 1299, y: 436, angle: 26 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C37',
    uuid: 'area-C37',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1113.91 383.806L1249.02 448.281L1287.49 365.584L1242.84 343.644L1145.06 300.402L1141.71 308.926L1146.36 311.168L1127.67 355.556L1125.96 354.476L1113.91 383.806Z',
    workplaces: [
      { uuid: 'wp-37-1', name: 'wp-37-1', position: { x: 1218, y: 398, angle: 26 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-37-2', name: 'wp-37-2', position: { x: 1181, y: 380, angle: 26 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-37-3', name: 'wp-37-3', position: { x: 1144, y: 362, angle: 26 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-37-4', name: 'wp-37-4', position: { x: 1238, y: 358, angle: 206 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-37-5', name: 'wp-37-5', position: { x: 1201, y: 340, angle: 206 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-37-6', name: 'wp-37-6', position: { x: 1164, y: 322, angle: 206 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'C36',
    uuid: 'area-C36',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M1247.15 450.832L1113.97 387.988L1109.78 398.364L1141.25 411.684L1112.66 479.341L1149.02 497.152L1148.35 498.74L1176.78 512.614L1177.5 511.263L1211.14 527.525L1247.15 450.832Z',
    workplaces: [
      { uuid: 'wp-36-1', name: 'wp-36-1', position: { x: 1181, y: 478, angle: 296 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-36-2', name: 'wp-36-2', position: { x: 1198, y: 442, angle: 296 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-36-3', name: 'wp-36-3', position: { x: 1120, y: 448, angle: 114 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-36-4', name: 'wp-36-4', position: { x: 1136, y: 412, angle: 114 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'M05',
    uuid: 'area-m05',
    type: AreaType.MEETING_ROOM,
    position: { x: 7, y: 57 },
    svgPath:
      'M1518.8 1288.24L1442.2 1198.51L1372.88 1256.9L1449.65 1347.94L1474.65 1326.96L1473.62 1325.94L1518.8 1288.24Z',
    workplaces: [
      {
        uuid: 'wp-M05',
        position: { x: 1417, y: 1240, angle: 0 },
        tableType: 5,
        type: WorkplaceType.MEETING
      }
    ]
  },
  {
    name: 'M04',
    uuid: 'area-m04',
    type: AreaType.MEETING_ROOM,
    position: { x: 7, y: 57 },
    svgPath:
      'M1591.76 1227.49L1515.16 1137.75L1445.84 1196.15L1521.02 1285.41L1565.7 1246.79L1566.91 1248.38L1591.76 1227.49Z',
    workplaces: [
      {
        uuid: 'wp-M04',
        position: { x: 1490, y: 1180, angle: 0 },
        tableType: 5,
        type: WorkplaceType.MEETING
      }
    ]
  },
  {
    name: 'M03',
    uuid: 'area-m03',
    type: AreaType.MEETING_ROOM,
    position: { x: 7, y: 57 },
    svgPath:
      'M1371.44 885.428L1490.03 986.313C1490.03 986.313 1500.07 994.258 1514.06 993.412C1528.06 992.566 1537.28 983.266 1537.28 983.266L1561.09 922.858L1562.74 923.553L1574.67 893.945L1573.11 893.428L1579.15 878.964L1464.45 779.574L1371.44 885.428Z',
    workplaces: [
      {
        uuid: 'wp-M03',
        position: { x: 1405, y: 824, angle: 0 },
        tableType: 12,
        type: WorkplaceType.MEETING
      }
    ]
  },
  {
    name: 'M02',
    uuid: 'area-m02',
    type: AreaType.MEETING_ROOM,
    position: { x: 7, y: 57 },
    svgPath:
      'M881.138 478.523L845.914 577.559L1014.45 638.027L1053.83 524.406L962.424 492.072L926.157 486.521V484.89L894.416 480.132L894.008 481.628L881.138 478.523Z',
    workplaces: [
      {
        uuid: 'wp-M02',
        position: { x: 866, y: 484, angle: -22 },
        tableType: 12,
        type: WorkplaceType.MEETING
      }
    ]
  },
  {
    name: 'M01',
    uuid: 'area-m01',
    type: AreaType.MEETING_ROOM,
    position: { x: 7, y: 57 },
    svgPath:
      'M704.922 505.466V512.276L758.373 530.488L847.951 561.73L878.304 477.185L861.579 474.153V472.29L829.685 466.78V468.495L725.355 449.199L704.922 505.466Z',
    workplaces: [
      {
        uuid: 'wp-M01',
        position: { x: 702, y: 445, angle: 0 },
        tableType: 10,
        type: WorkplaceType.MEETING
      }
    ]
  },
  {
    uuid: 'NF-1',
    type: AreaType.CABINET,
    zones: [
      {
        svgPath: 'M1655.35 1107.93L1548.91 1154.08L1535.65 1119.96L1643.32 1075.58L1655.35 1107.93Z'
      },
      {
        svgPath: 'M964.14 1597.13L747.395 1673.97L730.242 1624.89L950.854 1557.52L964.14 1597.13Z'
      },
      {
        svgPath: 'M437.633 1264.09H272.27L263.227 1140.07H437.633V1264.09Z'
      },
      {
        svgPath: 'M436.17 1041.54H303.852L303.852 987.652H436.17V1041.54Z'
      },
      {
        svgPath:
          'M1921.78 1013.45L1812.18 1105.02L1795.89 1084.21L1760.24 1113.62L1659.64 984.468L1664.19 879.003L1830.94 837.031L1828.62 827.549L1821.3 829.35L1833.14 816.196L1854.55 792.447L1881.23 763.73L1960.88 833.008L2043.71 910.169L1921.78 1013.45Z'
      },
      {
        svgPath:
          'M693.965 524.257L585.362 573.798L444.011 551.946V565.435H404.874L400.818 512.728C400.818 512.728 399.65 490.114 383.015 479.956C366.38 469.797 349.445 477.308 349.445 477.308L289.461 377.478L300.086 341.906L432.253 378.903L442.49 340.453L729.457 422.189L713.341 469.231L693.965 524.257Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  },
  {
    name: 'C-OP-1',
    uuid: 'area-c-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath:
      'M474.367 344.983L546.939 91.4248L626.945 114.019L734.251 145.999L838.199 180.705L940.576 216.956L1040.26 256.601L1136.55 296.546L1130.96 310.328L1141.32 314.886L1125.15 354.056L1126.27 354.768L1114.16 383.866L1112.73 383.154L1106.87 396.897L1105.39 396.42L1066.54 487.104L666.298 400.665L474.367 344.983Z',
    workplaces: [
      {
        name: 'K1',
        textRotate: true,
        uuid: 'city-op-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 505, y: 320, angle: 16 }
      },
      {
        name: 'K2',
        textRotate: true,
        uuid: 'city-op-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 544, y: 331, angle: 16 }
      },
      {
        name: 'K3',
        textRotate: true,
        uuid: 'city-op-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 583, y: 342, angle: 16 }
      },
      {
        name: 'K4',
        textRotate: true,
        uuid: 'city-op-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 622, y: 353, angle: 16 }
      },
      {
        name: 'K5',
        textRotate: true,
        uuid: 'city-op-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 512, y: 250, angle: 286 }
      },
      {
        name: 'K6',
        textRotate: true,
        uuid: 'city-op-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 523, y: 212, angle: 286 }
      },
      {
        name: 'K7',
        textRotate: true,
        uuid: 'city-op-7',
        type: WorkplaceType.DEFAULT,
        position: { x: 534, y: 174, angle: 286 }
      },
      {
        name: 'K8',
        textRotate: true,
        uuid: 'city-op-8',
        type: WorkplaceType.DEFAULT,
        position: { x: 545, y: 136, angle: 286 }
      },
      {
        name: 'K9',
        textRotate: false,
        uuid: 'city-op-9',
        type: WorkplaceType.DEFAULT,
        position: { x: 550, y: 261, angle: 106 }
      },
      {
        name: 'K10',
        textRotate: false,
        uuid: 'city-op-10',
        type: WorkplaceType.DEFAULT,
        position: { x: 561, y: 223, angle: 106 }
      },
      {
        name: 'K11',
        textRotate: false,
        uuid: 'city-op-11',
        type: WorkplaceType.DEFAULT,
        position: { x: 572, y: 185, angle: 106 }
      },
      {
        name: 'K12',
        textRotate: false,
        uuid: 'city-op-12',
        type: WorkplaceType.DEFAULT,
        position: { x: 583, y: 147, angle: 106 }
      },
      {
        name: 'K13',
        textRotate: true,
        uuid: 'city-op-13',
        type: WorkplaceType.DEFAULT,
        position: { x: 605, y: 269, angle: 287 }
      },
      {
        name: 'K14',
        textRotate: true,
        uuid: 'city-op-14',
        type: WorkplaceType.DEFAULT,
        position: { x: 616.5, y: 231, angle: 287 }
      },
      {
        name: 'K15',
        textRotate: true,
        uuid: 'city-op-15',
        type: WorkplaceType.DEFAULT,
        position: { x: 628, y: 193, angle: 287 }
      },
      {
        name: 'K16',
        textRotate: true,
        uuid: 'city-op-16',
        type: WorkplaceType.DEFAULT,
        position: { x: 658, y: 161, angle: 16 }
      },
      {
        name: 'K17',
        textRotate: true,
        uuid: 'city-op-17',
        type: WorkplaceType.DEFAULT,
        position: { x: 697, y: 172, angle: 16 }
      },
      {
        name: 'K18',
        textRotate: false,
        uuid: 'city-op-18',
        type: WorkplaceType.DEFAULT,
        position: { x: 706, y: 216, angle: 106 }
      },
      {
        name: 'K19',
        textRotate: false,
        uuid: 'city-op-19',
        type: WorkplaceType.DEFAULT,
        position: { x: 695, y: 254, angle: 106 }
      },
      {
        name: 'K20',
        textRotate: false,
        uuid: 'city-op-20',
        type: WorkplaceType.DEFAULT,
        position: { x: 684, y: 292, angle: 106 }
      },
      {
        name: 'K21',
        textRotate: true,
        uuid: 'city-op-21',
        type: WorkplaceType.DEFAULT,
        position: { x: 732, y: 310, angle: 289 }
      },
      {
        name: 'K22',
        textRotate: true,
        uuid: 'city-op-22',
        type: WorkplaceType.DEFAULT,
        position: { x: 745, y: 272, angle: 289 }
      },
      {
        name: 'K23',
        textRotate: true,
        uuid: 'city-op-23',
        type: WorkplaceType.DEFAULT,
        position: { x: 758, y: 234, angle: 289 }
      },
      {
        name: 'K24',
        textRotate: true,
        uuid: 'city-op-24',
        type: WorkplaceType.DEFAULT,
        position: { x: 771, y: 196, angle: 289 }
      },
      {
        name: 'K25',
        textRotate: false,
        uuid: 'city-op-25',
        type: WorkplaceType.DEFAULT,
        position: { x: 809, y: 209, angle: 109 }
      },
      {
        name: 'K26',
        textRotate: false,
        uuid: 'city-op-26',
        type: WorkplaceType.DEFAULT,
        position: { x: 796, y: 247, angle: 109 }
      },
      {
        name: 'K27',
        textRotate: false,
        uuid: 'city-op-27',
        type: WorkplaceType.DEFAULT,
        position: { x: 783, y: 285, angle: 109 }
      },
      {
        name: 'K28',
        textRotate: false,
        uuid: 'city-op-28',
        type: WorkplaceType.DEFAULT,
        position: { x: 770, y: 323, angle: 109 }
      },
      {
        name: 'K29',
        textRotate: true,
        uuid: 'city-op-29',
        type: WorkplaceType.DEFAULT,
        position: { x: 824, y: 342, angle: 291 }
      },
      {
        name: 'K30',
        textRotate: true,
        uuid: 'city-op-30',
        type: WorkplaceType.DEFAULT,
        position: { x: 838, y: 305, angle: 291 }
      },
      {
        name: 'K31',
        textRotate: true,
        uuid: 'city-op-31',
        type: WorkplaceType.DEFAULT,
        position: { x: 852, y: 268, angle: 291 }
      },
      {
        name: 'K32',
        textRotate: true,
        uuid: 'city-op-32',
        type: WorkplaceType.DEFAULT,
        position: { x: 885, y: 238, angle: 21 }
      },
      {
        name: 'K33',
        textRotate: true,
        uuid: 'city-op-33',
        type: WorkplaceType.DEFAULT,
        position: { x: 923, y: 253, angle: 21 }
      },
      {
        name: 'K34',
        textRotate: false,
        uuid: 'city-op-34',
        type: WorkplaceType.DEFAULT,
        position: { x: 928, y: 297, angle: 111 }
      },
      {
        name: 'K35',
        textRotate: false,
        uuid: 'city-op-35',
        type: WorkplaceType.DEFAULT,
        position: { x: 914, y: 334, angle: 111 }
      },
      {
        name: 'K36',
        textRotate: false,
        uuid: 'city-op-36',
        type: WorkplaceType.DEFAULT,
        position: { x: 900, y: 371, angle: 111 }
      },
      {
        name: 'K37',
        textRotate: true,
        uuid: 'city-op-37',
        type: WorkplaceType.DEFAULT,
        position: { x: 962, y: 390, angle: 291 }
      },
      {
        name: 'K38',
        textRotate: true,
        uuid: 'city-op-38',
        type: WorkplaceType.DEFAULT,
        position: { x: 976, y: 353, angle: 291 }
      },
      {
        name: 'K39',
        textRotate: true,
        uuid: 'city-op-39',
        type: WorkplaceType.DEFAULT,
        position: { x: 990, y: 316, angle: 291 }
      },
      {
        name: 'K40',
        textRotate: true,
        uuid: 'city-op-40',
        type: WorkplaceType.DEFAULT,
        position: { x: 1004, y: 279, angle: 291 }
      },
      {
        name: 'K41',
        textRotate: false,
        uuid: 'city-op-41',
        type: WorkplaceType.DEFAULT,
        position: { x: 1041, y: 293, angle: 111 }
      },
      {
        name: 'K42',
        textRotate: false,
        uuid: 'city-op-42',
        type: WorkplaceType.DEFAULT,
        position: { x: 1027, y: 330, angle: 111 }
      },
      {
        name: 'K43',
        textRotate: false,
        uuid: 'city-op-43',
        type: WorkplaceType.DEFAULT,
        position: { x: 1013, y: 367, angle: 111 }
      },
      {
        name: 'K44',
        textRotate: false,
        uuid: 'city-op-44',
        type: WorkplaceType.DEFAULT,
        position: { x: 999, y: 404, angle: 111 }
      }
    ]
  }
];
