import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import PageLoader from '../components/PageLoader';
import Tickets from '../components/Tickets';

const TicketsPage = ({ Customer, isLoading }) => (
  <PrimaryLayout>
    <main className='main'>{isLoading ? <PageLoader /> : <Tickets customer={Customer[0]} />}</main>
  </PrimaryLayout>
);

export default TicketsPage;
