import React, { useCallback, useEffect, useState } from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Support from '../components/Support';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, officeSelector } from '../store/selectors/DefaultSelectors';
import { getOffice } from '../services/workki/TypesActions';
import PageLoader from '../components/PageLoader';
import { screenRessolutions } from '../shared/ui/constants/screenResolutions.constants';
import { useResize } from '../shared/utils/hooks/useResize';
import styled from 'styled-components';
import { StepButton } from '../shared/ui/components/StepButton';
import FormControl from '@mui/material/FormControl';
import useModalState from '../utils/useModalState';
import queryString from 'query-string';
import { getActiveServiceRequests } from '../services/workki/ServicesActions';
import { Dialog } from '../shared/ui/components/Dialog';
import { getTickets } from '../services/workki/TicketsActions';
import { ticketsSelector } from '../store/selectors/TicketsSelectors';
import * as actions from '../store/actions';

const BottomMenu = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f4f4;
  box-shadow: 0 -7px 12px 0px #00000014;
  border-radius: 12px 12px 0 0;
  position: fixed;
  left: 0;
  bottom: 56px;
  background-color: #fff;
  z-index: 11;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    display: none;
  }
`;

const BottomMenuContentBox = styled.div`
  margin: 0;
  padding: 8px 16px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  gap: 16px;

  & > p {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #383c40;
    width: 130px;
  }
`;

const SupportPage = ({ isLoading }) => {
  const dispatch = useDispatch();
  const [showTicketDialog, setShowTicketDialog] = useState(false);
  const [showTicketSuccessDialog, setShowTicketSuccessDialog] = useState(false);
  const [isTablet, setIsTablet] = useState(window.innerWidth < screenRessolutions.laptop);
  const handleChangeState = () => {
    const isTaletScreen = window.innerWidth < screenRessolutions.laptop;
    if (isTaletScreen !== isTablet) setIsTablet(isTaletScreen);
  };

  useResize(handleChangeState, 0);

  useEffect(() => {
    dispatch(getOffice());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getActiveServiceRequests());
  }, [dispatch]);

  const customer = useSelector(customerSelector);

  const tickets = useSelector(ticketsSelector);

  const loadHistory = useCallback(() => {
    dispatch(actions.resetTickets());
    customer && dispatch(getTickets());
  }, [dispatch, customer]);

  useEffect(() => loadHistory(), [loadHistory]);

  const offices = useSelector(officeSelector);
  return (
    <PrimaryLayout>
      {isLoading ? (
        <PageLoader />
      ) : (
        offices && (
          <Support offices={offices} setShowTicketDialog={setShowTicketDialog} customer={customer} tickets={tickets} />
        )
      )}
      {isTablet && (
        <BottomMenu>
          <BottomMenuContentBox>
            <FormControl fullWidth={true}>
              <StepButton
                handleAction={() => setShowTicketDialog(true)}
                type='filled'
                title='Обратиться в поддержку'
                filledType='red'
              />
            </FormControl>
          </BottomMenuContentBox>
        </BottomMenu>
      )}
      {showTicketDialog && (
        <Dialog
          dialogType={'createTicket'}
          title={'Обращение в службу поддержки'}
          handleClose={() => {
            setShowTicketDialog(false);
          }}
          handleSubmit={() => {
            setShowTicketSuccessDialog(true);
            loadHistory();
          }}
        />
      )}
      {showTicketSuccessDialog && (
        <Dialog
          dialogType={'createTicketSuccess'}
          title={'Обращение успешно отправлено'}
          buttonLabel={'Спасибо'}
          handleClose={() => {
            setShowTicketSuccessDialog(false);
          }}
        />
      )}
    </PrimaryLayout>
  );
};

export default SupportPage;
