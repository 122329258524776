import React from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';

const EmailCodeAuthStep = ({
  entranceFormClass,
  handleInputChange,
  handleFocus,
  handleTriggerEmailCode,
  handleBlur,
  handleEraseValue,
  errors,
  formData,
  filled,
  isFocused,
  isPending,
  disableButton,
  handlePhoneAuthClick,
  divider,
  setRegistrationStep,
  setTimer,
  RESEND_INTERVAL
}) => {
  return (
    <div>
      <div className='authorization__caption'>
        <p>Вход по коду из e-mail</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={event => {
          handleTriggerEmailCode(event);
          setTimer(RESEND_INTERVAL);
        }}
        autoComplete='false'
        id='email-code-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.email.auth}
          type='email'
          name='email'
          id='outlined-helperText'
          label='E-mail'
          variant='filled'
          value={formData.email.auth}
          onFocus={() => handleFocus('email', 'auth')}
          onBlur={() => handleBlur('email', 'auth')}
          onChange={handleInputChange}
          disabled={isPending}
          helperText={errors.email.auth ? errors.email.auth : ''}
          endAdornmentProps={{
            filled: filled.email.auth,
            isFocused: isFocused.email.auth,
            handleEraseValue: name => handleEraseValue(name, 'auth')
          }}
          autoFocus={true}
        />
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Получить код на e-mail
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
          <div className='authorization__footer__links-wrapper'>
            <img src={divider} alt='divider' />
            <a
              href='#'
              onClick={() => {
                setRegistrationStep(prevStep => ({
                  ...prevStep,
                  type: 'phoneAuthPrimary',
                  destination: 'flash-call'
                }));
              }}
            >
              Войти по номеру телефона
            </a>
            <a href='#' onClick={handlePhoneAuthClick}>
              Войти по e-mail и паролю
            </a>
          </div>
          <p className='authorization__footer__policy'>
            Выполняя вход, вы соглашаетесь{' '}
            <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
              с договором публичной оферты
            </a>
            &#160;и&#160;
            <a href=' https://workki.co/upload/iblock/f5f/Personal_data.pdf' target='_blank'>
              правилами обработки данных
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default EmailCodeAuthStep;
