import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommentItem from './CommentItem';
import TableDataLoader from '../../TableDataLoader';
import TableDataReloader from '../../TableDataReloader';
import {
  getTicketComments,
  checkHasTicketNewComment,
  createTicketComment
} from '../../../services/workki/TicketsActions';
import * as actions from '../../../store/actions';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';
import moment from 'moment';
import { TextareaAutosize } from '@mui/material';
import { IconComponent } from '../../../shared/ui/components/Field/IconComponent';

const Ticket = ({ ticket, customer, offices }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [comments, setComments] = useState([]);
  let lastDate = null;
  const loadStatus = useSelector(requestStatusSelector(actions.requestTicketComments));

  const loadComments = useCallback(() => {
    dispatch(getTicketComments(ticket.id)).then(response => {
      const fetchedComments = response.data || [];

      const allComments = ticket.description
        ? [
            { id: 'ticket-desc', text: ticket.description, created_at: ticket.created_at, isDescription: true },
            ...fetchedComments
          ]
        : fetchedComments;

      setComments(allComments);
    });
  }, [dispatch, ticket.id, ticket.description, ticket.created_at]);

  useEffect(() => {
    loadComments();
    return () => dispatch(actions.requestTicketComments.reset());
  }, [dispatch, loadComments]);

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSendComment = () => {
    if (!inputValue.trim()) return;

    const newComment = {
      ticket_id: ticket.id,
      text: inputValue
    };

    dispatch(createTicketComment(newComment)).then(() => {
      loadComments();
      setInputValue('');
    });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendComment();
    }
  };

  return (
    <>
      <div className='tickets-messages-wrapper'>
        <div className='tickets-messages-title'>История комментариев</div>
        <div className='tickets-messages'>
          {comments.map((comment, index) => {
            const currentDate = moment(comment.created_at, 'YYYY.MM.DD HH:mm:ss').format('YYYY-MM-DD');
            const showDateDivider = currentDate !== lastDate;
            lastDate = currentDate;

            return (
              <CommentItem
                key={comment.id || index}
                comment={comment}
                customer={customer}
                showDateDivider={showDateDivider}
                ticket={ticket}
                offices={offices}
                isDescription={comment.isDescription}
              />
            );
          })}
        </div>
      </div>

      <div className='add-comment-input-wrapper'>
        <TextareaAutosize
          className='add-comment-input'
          placeholder='Введите комментарий'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          maxRows={5}
        />
        {inputValue.trim().length > 0 && (
          <button onClick={handleSendComment} className='send-comment-button-mobile'>
            <IconComponent type='send' />
          </button>
        )}
      </div>

      {loadStatus.isPending && <TableDataLoader />}
      {loadStatus.isFailure && <TableDataReloader reload={loadComments} />}
    </>
  );
};

export default Ticket;
