import React, { useCallback, useMemo } from 'react';

import { FloorAreaTemplate } from './FloorAreaTemplate';
import { FloorWorkplaceDefault } from './FloorWorkplaceDefault';
import { FloorWorkplaceMeeting } from './FloorWorkplaceMeeting';
import { IPlaceStatus, WorkplaceType } from '../../types';
import { isDateBusy } from '../../../../../shared/utils/helperFunctions/heplerFunctions';
import { useSelector } from 'react-redux';
import { serviceRequestWithWorkplacesSelector } from '../../../../../store/selectors/ServicesSelector';
import dayjs from 'dayjs';

export const FloorOpenSpace = ({
  openPriceCard,
  floorArea: { svgPath, workplaces, name: areaName, serverData },
  onTouchStart
}) => {
  const onChangeActivePlace = useCallback(place => openPriceCard(place), [openPriceCard]);
  const bookingInfo = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: dayjs().subtract(365, 'days'),
      selectorType: 'standard'
    })
  );
  return (
    <g data-name={areaName} id={`workplace-${areaName}`} className='workplace-floor-place-item'>
      <FloorAreaTemplate drawn={svgPath}></FloorAreaTemplate>
      {workplaces &&
        workplaces.map(workplace => {
          const serverData = workplace.serverData;
          const onClickHandler = () => {
            if (serverData) {
              onChangeActivePlace(serverData);
            }
          };
          switch (workplace.type) {
            case WorkplaceType.DEFAULT:
              // const isDisabled = isDateBusy(Date.now(), bookingInfo, workplace.serverData.id);
              return (
                <FloorWorkplaceDefault
                  showName
                  tabIndex={0}
                  name={workplace.name}
                  onClick={onClickHandler}
                  onTouchStart={onClickHandler}
                  position={workplace.position}
                  textRotate={workplace.textRotate}
                  key={workplace.uuid + workplace.name}
                  //Если занят или если нет данных с сервера
                  serverData={workplace.serverData}
                  workplaceStatus={workplace.serverData ? workplace.serverData.status === IPlaceStatus.BUSY : 'BUSY'}
                  // disabled={isDisabled}
                  disabled={(workplace.serverData && workplace.serverData.status === IPlaceStatus.BUSY) || !serverData}
                />
              );
            case WorkplaceType.MEETING:
              return <FloorWorkplaceMeeting key={workplace.uuid} position={workplace.position} />;
            default:
              return null;
          }
        })}
    </g>
  );
};
