import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Date, Description, Details, Transaction, Type } from '../../entities/Payments/model/Payments';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Loader } from '../../shared/ui/components/Loader';
import { LinkArrow } from '../../shared/ui/icons/core/LinkArrow';
import { ServiceDetailsDialog } from './ServiceDetailsDialog';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';
import { useDispatch } from 'react-redux';
import { getAllGuests } from '../../services/workki/ServicesActions';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`;
export const Container = styled.div`
  color: #24272a;
  background-color: white;
  font-family: Commissioner, sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
`;
export const StatusWrapper = styled.div`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    flex-direction: row;
    align-items: center;
    & > div {
      margin-right: 12px;
    }
  }
`;

const HistoryList = ({
  customer,
  guestList,
  parkingList,
  tmcList,
  guestsRequestStatus,
  parkingRequestStatus,
  serviceRequestStatus,
  loadGuestData,
  loadParkingData,
  loadTmcData
}) => {
  const dispatch = useDispatch();
  const [validIds, setValidIds] = useState(new Set());
  const [filteredParkingList, setFilteredParkingList] = useState([]);
  const [filteredGuestList, setFilteredGuestList] = useState([]);

  const [open, setOpen] = useState({
    parkingInfo: false,
    guestsInfo: false,
    tmcInfo: false
  });
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const wrapperRef = useRef(null);

  const getAllGuestsReq = async pageNumber => {
    if (isFetching || !hasMore) return;

    setIsFetching(true);
    try {
      const response = await dispatch(getAllGuests(pageNumber));

      if (!response || !response.data || !response.data.items || response.data.items.length === 0) {
        setHasMore(false);
        return;
      }

      const { items, totalPages } = response.data;

      setValidIds(prevIds => new Set([...prevIds, ...items.map(item => item.id)]));

      if (totalPages) {
        setTotalPages(totalPages);
      }

      if (pageNumber >= totalPages) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
    setIsFetching(false);
  };

  const handleScroll = () => {
    if (!wrapperRef.current || isFetching || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;

    if (scrollTop + clientHeight >= scrollHeight && page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    let scrollTimeout;

    const debouncedScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        handleScroll();
      }, 200);
    };

    wrapper.addEventListener('scroll', debouncedScroll);

    return () => {
      wrapper.removeEventListener('scroll', debouncedScroll);
      clearTimeout(scrollTimeout);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (customer) {
      loadTmcData.loadTMCInputHistory();
      loadTmcData.loadTulskayaTMCInputHistory();
      loadTmcData.loadParkKulturyTMCInputHistory();
      loadGuestData();
      loadParkingData();
    }
  }, [customer, loadGuestData, loadParkingData]);

  useEffect(() => {
    getAllGuestsReq(page);
  }, [page]);

  useEffect(() => {
    const newIds = new Set([...validIds, ...guestList.map(item => item.id)]);
    setFilteredGuestList(guestList.filter(item => newIds.has(item.id)));
  }, [guestList]);

  useEffect(() => {
    const newIds = new Set([...validIds, ...parkingList.map(item => item.id)]);
    setFilteredParkingList(parkingList.filter(item => newIds.has(item.id)));
  }, [parkingList]);

  const filteredTmcList = useMemo(() => tmcList.filter(item => validIds.has(item.id)), [tmcList, validIds]);

  if (parkingRequestStatus.isPending || guestsRequestStatus.isPending || serviceRequestStatus.isFetching) {
    return (
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loader wrapperType='transparent' position={'absolute'} />
      </div>
    );
  }

  if (parkingRequestStatus.isFailure || guestsRequestStatus.isFailure) {
    return (
      <Container>
        <Transaction>
          <Details>
            <Description>Ошибка загрузки истории заказов пропусков</Description>
          </Details>
        </Transaction>
      </Container>
    );
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        {filteredGuestList.map(item => (
          <Transaction
            key={item.id}
            onClick={() => {
              setSelectedServiceId(item.id);
              setOpen(prevState => ({ ...prevState, guestsInfo: true }));
            }}
          >
            <Details>
              <Type>
                Гостевой пропуск
                <LinkArrow type='small' defaultColor='#999' spacing='12px' width='12px' height='12px' />
              </Type>
              <Description>{item.location.t_name}</Description>
            </Details>
            <Details direction='end'>
              <Date>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
            </Details>
          </Transaction>
        ))}

        {filteredTmcList.map(item => (
          <Transaction
            key={item.id}
            onClick={() => {
              setSelectedServiceId(item.id);
              setOpen(prevState => ({ ...prevState, tmcInfo: true }));
            }}
          >
            <Details>
              <Type>
                <StatusWrapper>
                  <div>
                    Пропуск на вынос ТМЦ
                    <LinkArrow type='small' defaultColor='#999' width='12px' height='12px' />
                  </div>
                </StatusWrapper>
              </Type>
              <Description>{item.location.t_name}</Description>
            </Details>
            <Details direction='end'>
              <Date>{dayjs(item.date).format('HH:mm')}</Date>
              <Date>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
            </Details>
          </Transaction>
        ))}

        {filteredParkingList.map(item => (
          <Transaction
            key={item.id}
            onClick={() => {
              setSelectedServiceId(item.id);
              setOpen(prevState => ({ ...prevState, parkingInfo: true }));
            }}
          >
            <Details>
              <Type>
                <StatusWrapper>
                  <div>
                    Пропуск на въезд авто
                    <LinkArrow type='small' defaultColor='#999' width='12px' height='12px' />
                  </div>
                </StatusWrapper>
              </Type>
              <Description>{item.location.t_name}</Description>
            </Details>
            <Details direction='end'>
              <Date>{dayjs(item.date).format('HH:mm')}</Date>
              <Date>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
            </Details>
          </Transaction>
        ))}
      </Container>
      {open.parkingInfo && (
        <ServiceDetailsDialog
          title='Пропуск на въезд авто'
          dialogData={filteredParkingList.filter(item => item.id === selectedServiceId)}
          dialogType='serviceDetails'
          handleClose={() => setOpen(prevState => ({ ...prevState, parkingInfo: false }))}
        />
      )}

      {open.tmcInfo && (
        <ServiceDetailsDialog
          title='Пропуск на внос/вынос ТМЦ'
          dialogData={filteredTmcList.filter(item => item.id === selectedServiceId)}
          dialogType='serviceDetails'
          handleClose={() => setOpen(prevState => ({ ...prevState, tmcInfo: false }))}
        />
      )}

      {open.guestsInfo && (
        <ServiceDetailsDialog
          title='Гостевой пропуск'
          dialogData={filteredGuestList.filter(item => item.id === selectedServiceId)}
          dialogType='serviceDetails'
          handleClose={() => setOpen(prevState => ({ ...prevState, guestsInfo: false }))}
        />
      )}
    </Wrapper>
  );
};

export default HistoryList;
