import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';

export const UserDataWidthWrapper = styled.div`
  margin: 0;
  padding: 20px 0;
  width: 344px;
  box-sizing: content-box;

  @media screen and (max-width: ${screenRessolutions.tablet}px) {
    max-width: 328px;
  }
`;

export const UserDataChangeBox = styled.div`
  margin: 20px 0 32px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const UserDataChangeTitle = styled.h3`
  margin: 0;
  padding: 0;
  width: 100%;
  text-direction: start;
  font-family: Commissioner, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #24272a;
`;

export const UserDataChangeList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    gap: 8px;
  }
`;

export const UserDataChangeItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    justify-content: flex-start;
  }
`;

export const UserDataChangeItemLabel = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Commissioner, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #24272a;

  & > span {
    font-size: 12px;
    line-height: 16px;
    color: #8f9295;
  }
`;

export const StyledSvg = styled.svg`
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
`;

export const RemoveProfileBox = styled.div`
  margin: 20px 0 0 0;
  padding: 16px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid #e9eaea;
`;

export const RemoveProfileTitleBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: Commissioner, sans-serif;
  gap: 4px;
  cursor: pointer;

  & > h3 {
    margin: 2px 0;
    paddindg: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #8f9295;
  }
`;

export const RemoveProfileSvg = styled.svg`
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
`;

export const RemoveProfileWarningText = styled.p`
  margin: 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #8f9295;
`;

export const AvatarContainer = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
