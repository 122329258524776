import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';
import { PhoneMask } from '../../shared/ui/icons/core/PhoneMask';

export const ChangeUserDataDialog = props => {
  //TODO по сути тут можно заменить идентичные поля на единообразные и в компоненте ниже
  const phoneConfirmationFields = [
    {
      view: 'field',
      type: 'tel',
      name: 'phone',
      label: 'Телефон',
      valueKey: 'phone',
      errorKey: 'phone',
      context: '',
      autoFocus: true,
      placeholder: '999 999-99-99',
      startAdornment: <PhoneMask spacing='0' defaultColor='#24272A' />,
      complexValueKey: false
    },
    {
      view: 'field',
      type: 'email',
      name: 'email',
      label: 'E-mail',
      valueKey: 'register',
      errorKey: 'register',
      context: 'register',
      autoFocus: false,
      complexValueKey: true
    }
  ];
  const phoneAuthfields = [
    {
      view: 'field',
      type: 'tel',
      name: 'phone',
      label: 'Телефон',
      valueKey: 'phone',
      errorKey: 'phone',
      context: '',
      autoFocus: true,
      placeholder: '999 999-99-99',
      startAdornment: <PhoneMask spacing='0' defaultColor='#24272A' />,
      complexValueKey: false
    }
  ];

  const emailAuthfields = [
    {
      view: 'field',
      type: 'email',
      name: 'email',
      label: 'E-mail',
      valueKey: 'register',
      errorKey: 'register',
      context: 'register',
      autoFocus: false,
      complexValueKey: false
    }
  ];

  const getFieldsByDialogType = dialogType => {
    switch (dialogType) {
      case 'phoneConfirmation':
        return phoneConfirmationFields;
      case 'emailAuth':
        return emailAuthfields;
      case 'phoneAuth':
      default:
        return phoneAuthfields;
    }
  };

  const getTitleByDialogType = dialogType => {
    switch (dialogType) {
      case 'phoneConfirmation':
        return 'Изменить введенные данные';
      case 'emailAuth':
        return 'Изменить E-mail';
      case 'phoneAuth':
      default:
        return 'Изменить номер';
    }
  };

  return (
    <Dialog
      title={getTitleByDialogType(props.dialogType)}
      dialogType={props.dialogType}
      fields={getFieldsByDialogType(props.dialogType)}
      buttonLabel={props.dialogType === 'emailAuth' ? 'Получить код на e-mail' : 'Изменить'}
      requestSentMessage=''
      requestSentDescription=''
      {...props}
    />
  );
};
