import React from 'react';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

const StyledHints = styled(Paper)(({ width, height }) => ({
  border: 'none',
  marginTop: '4px',
  maxWidth: width,
  minWidth: width,
  borderRadius: '8px',
  boxShadow: '0px 2px 12px 0px #00000014',
  justifyContent: 'center',
  minHeight: '80px',
  maxHeight: height ? height : '200px',
  overflowX: 'scroll',
  position: 'absolute'
}));

const StyledHint = styled('div')(({ width }) => ({
  '&:hover': {
    backgroundColor: '#FBECEC',
    cursor: 'pointer'
  },
  'maxWidth': width,
  'minWidth': width,
  'padding': '8px 16px 10px 16px'
}));

const CompanyTitle = styled('p')(() => ({
  color: '#24272A',
  fontSize: '14px',
  fontWeight: '400',
  margin: '0 0 4px 0'
}));

const CompanyInfo = styled('p')(() => ({
  'color': '#8F9295',
  'fontWeight': '300',
  'margin': '0',
  'display': 'flex',
  'flexDirection': 'column',
  '& > p': {
    color: '#24272A',
    fontWeight: '300',
    margin: '0'
  }
}));

export const Hints = ({
  open,
  formData,
  anchorEl,
  inputWidth,
  hintsData,
  addressHintsData,
  setInnValue,
  type,
  setErrors,
  setFullAddress,
  handleAction
}) => {
  const address = hint => {
    return hint.unrestricted_value;
  };
  const validateAddressField = hint => {
    //TODO стоит добавить отдельную обработку на ошибки каждого из полей непосредственно
    if (hint.city_with_type && hint.street_with_type && hint.house) {
      setErrors('');
      setFullAddress(hint);
    } else {
      setErrors('Введите полный адрес');
    }
  };
  return (
    <Popper
      sx={{ zIndex: '12', marginBottom: '30px' }}
      open={Boolean(open)}
      anchorEl={anchorEl}
      placement={'bottom-start'}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            {hintsData && hintsData.length > 0 && type === 'inn' ? (
              <StyledHints width={`${inputWidth}px`}>
                {hintsData.map((hint, index) => (
                  <StyledHint width={`${inputWidth}px`} key={index} onClick={() => setInnValue(hint.inn)}>
                    <CompanyTitle>{hint.name}</CompanyTitle>
                    <CompanyInfo>
                      <span>ИНН {hint.inn}</span>
                      <span>ОГРН {hint.ogrn}</span>
                      <span>{hint.city}</span>
                    </CompanyInfo>
                  </StyledHint>
                ))}
              </StyledHints>
            ) : hintsData && hintsData.length === 0 && type === 'inn' ? (
              <StyledHints height={'58px'} onClick={handleAction}>
                <StyledHint width={`${inputWidth}px`}>
                  <CompanyInfo>Реквизиты не найдены</CompanyInfo>
                  <CompanyTitle>
                    <span>Не получается найти компанию?</span>
                  </CompanyTitle>
                </StyledHint>
              </StyledHints>
            ) : addressHintsData && addressHintsData.length > 0 && type === 'address' ? (
              <StyledHints width={`${inputWidth}px`} height={'164px'}>
                {addressHintsData.map((hint, index) => (
                  <StyledHint
                    width={`${inputWidth}px`}
                    key={index}
                    onClick={() => {
                      validateAddressField(hint);
                      setInnValue(address(hint));
                    }}
                  >
                    <CompanyInfo>
                      <p>{address(hint)}</p>
                    </CompanyInfo>
                  </StyledHint>
                ))}
              </StyledHints>
            ) : (
              <StyledHints height={'58px'}>
                <StyledHint width={`${inputWidth}px`}>
                  <CompanyInfo>Адрес не найден</CompanyInfo>
                </StyledHint>
              </StyledHints>
            )}
          </div>
        </Fade>
      )}
    </Popper>
  );
};
