import React from 'react';
import { InputBoxes } from '../../shared/ui/components/InputBoxes';
import { ProfileIcon } from '../../shared/ui/icons/core/ProfileIcon';

const PhoneConfirmationForm = ({
  phoneNumber,
  email,
  messageType,
  handleSendCode,
  handleResendMessage,
  handleRegisterSubmit,
  setIsChangeData,
  setRegistrationStep,
  infoMessage,
  errors,
  entranceFormClass,
  RESEND_INTERVAL,
  setTimer
}) => {
  const formatFlashCallPhone = phone => {
    if (phone && phone.length === 11) {
      return `${'+' +
        phone[0] +
        ' ' +
        phone.slice(1, 4) +
        ' ' +
        phone.slice(4, 7) +
        '-' +
        phone.slice(7, 9) +
        '-' +
        phone.slice(9, 11)}`;
    } else return phone;
  };
  return (
    <div>
      <div className='authorization__phone-caption'>
        <p>
          Подтверждение
          <br />
          номера телефона
        </p>
        <div className='authorization__phone-caption__phoneWrapper'>
          <span>+7 {formatFlashCallPhone(phoneNumber)}</span>
          <span>{email}</span>
          <a onClick={() => setIsChangeData(prevState => ({ ...prevState, open: true, type: 'phoneConfirmation' }))}>
            <span>Изменить данные</span>
          </a>
        </div>
      </div>
      <div className='authorization__phone-main'>
        <p>
          Введите <span>{messageType === 'call' ? 'последние 4 цифры' : 'код из СМС'}</span>
          <br />
          {messageType === 'call' && 'входящего номера'}
        </p>
        {messageType === 'call' && (
          <div className='authorization__phone-main__bubble'>
            <div className='authorization__phone-main__bubble__wrapper'>
              <div className='authorization__phone-main__call'>
                <p>
                  +7 XXX XXX-<span>XX-XX</span>
                </p>
                <p>Входящий вызов</p>
              </div>
              <div className='authorization__phone-main__profile'>
                <ProfileIcon spacing={'4px'} />
              </div>
            </div>
          </div>
        )}
        <div className='authorization__phone-main__phone-fields'>
          <InputBoxes
            length={4}
            error={errors.code}
            handleRequest={value => {
              handleSendCode(value);
            }}
          />
        </div>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={handleRegisterSubmit}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <div className='authorization__footer'>
          <div className='authorization__footer__links-wrapper'>
            <a
              onClick={() => {
                if (!infoMessage.isDisabled) {
                  handleResendMessage();
                  setRegistrationStep(prevStep => ({
                    ...prevStep,
                    destination: 'sms'
                  }));
                  setTimer(RESEND_INTERVAL);
                }
              }}
            >
              <span
                className={`authorization__footer__links-wrapper__${
                  infoMessage.isDisabled ? 'timer-disabled' : 'timer-active'
                }`}
              >
                {infoMessage.message}
              </span>
            </a>
            <a
            // onClick={() => {
            //   setRegistrationStep(prevStep => ({
            //     ...prevStep,
            //     type: 'registrationSuccess'
            //   }));
            // }}
            >
              Подтвердить номер позже
            </a>
          </div>
        </div>
        <div className='authorization__bottom'>
          <div className='authorization__footer__links-wrapper'>
            <a
              onClick={() =>
                setRegistrationStep(prevStep => ({
                  ...prevStep,
                  type: 'registrationFailed'
                }))
              }
            >
              Не приходит код?
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PhoneConfirmationForm;
