import React from 'react';
import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/screenResolutions.constants';
import SupportAppealStatus from './SupportAppealStatus';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const HistoryOfAppealsBox = styled.div`
  margin: 16px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: ${screenRessolutions.smallMobile}px) {
    overflow-y: auto;
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin-top: 48px;
    overflow: visible;
  }
`;

const ContactsAndManagersTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #24272a;
`;

const ContactsAndManagersTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HistoryOfAppealsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
`;

const HistoryOfAppealsItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  gap: 12px;
  cursor: pointer;
`;

const HistoryOfAppealsItemDescription = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & > h4 {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (min-width: ${screenRessolutions.desktop}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & > p {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (min-width: ${screenRessolutions.desktop}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const HistoryOfAppealsItemDateAndId = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;

  & > h4 {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;

    @media screen and (min-width: ${screenRessolutions.desktop}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & > p {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;
    text-align: end;

    @media screen and (min-width: ${screenRessolutions.desktop}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const TitleDescription = styled.div`
  display: flex;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #575b60;
  cursor: pointer;
`;

export const getDate = string => {
  let startString = string.split('').splice(0, 10);
  startString.splice(0, 2);
  startString = startString
    .join('')
    .split('-')
    .reverse()
    .join('.');

  return startString;
};

export const getTime = string => {
  return string.substring(string.indexOf(' '), string.lastIndexOf(':'));
};

const SupportHistoryList = ({ tickets }) => {
  const history = useHistory();

  const getHistoryItems = () => {
    return tickets
      .map((item, index) => {
        if (!item.place || !item.office) {
          return null;
        }

        return (
          <HistoryOfAppealsItem onClick={() => history.push('/tickets', { ticket: item })} key={index}>
            <HistoryOfAppealsItemDescription>
              <h4>{item.category.t_name}</h4>
              <SupportAppealStatus statusId={item.status_id} />
              <p>
                {item.place.name} {item.office.t_name}
              </p>
            </HistoryOfAppealsItemDescription>
            <HistoryOfAppealsItemDateAndId>
              <h4>№{item.id}</h4>
              <p>
                {getDate(item.created_at)}
                <br />
                {getTime(item.created_at)}
              </p>
            </HistoryOfAppealsItemDateAndId>
          </HistoryOfAppealsItem>
        );
      })
      .slice(0, 3);
  };
  return (
    <HistoryOfAppealsBox>
      <ContactsAndManagersTitleWrapper>
        <ContactsAndManagersTitle>История обращений</ContactsAndManagersTitle>
        <TitleDescription onClick={() => history.push('/tickets')}>Все обращения</TitleDescription>
      </ContactsAndManagersTitleWrapper>
      <HistoryOfAppealsList>{getHistoryItems()}</HistoryOfAppealsList>
    </HistoryOfAppealsBox>
  );
};

export default SupportHistoryList;
