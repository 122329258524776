import { Model } from 'redux-orm';
import * as actions from '../actions';

class CustomerFile extends Model {
  static reducer(action, customerFile) {
    switch (action.type) {
      case actions.requestDeleteCustomerFileSuccess.success.toString():
        const initialArray = customerFile.all().toRefArray();
        customerFile
          .all()
          .toRefArray()
          .forEach(elem => customerFile.delete(elem));
        initialArray.forEach(elem => {
          if (elem.id !== action.payload.imageId) {
            customerFile.upsert(elem);
          }
        });
        // оборачиваем в setTimeout тк в коллбеке вызывается еще один disaptch, что запрещено делать в одном цикле обновления состояний
        setTimeout(() => action.payload.getFilesCallback(), 0);
        break;
      case actions.requestCustomerProfileFilesSuccess.toString():
        customerFile
          .all()
          .toRefArray()
          .forEach(elem => customerFile.delete(elem));
        action.payload.forEach(elem => {
          customerFile.upsert(elem);
        });
        break;
      case actions.requestServiceSendFileToPrinterSuccess.toString():
        customerFile.upsert(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `CustomerFile: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
CustomerFile.modelName = 'CustomerFile';

export default CustomerFile;
