import React, { useMemo, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import NotHistory from '../NotHistory';
import ContentLoader from '../ContentLoader';
import CustomScrollbar from '../CustomScrollbar';
import TableDataLoader from '../TableDataLoader';
import TableDataReloader from '../TableDataReloader';
import TicketHistoryList from './TicketHistoryList';
import { getTicketElementId } from './constants';
import useNode from '../../utils/useNode';
import SupportAppealStatus from '../Support/SupportAppealStatus';
import { getDate, getTime } from '../Support/SupportHistoryList';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const VALUES_TOP_THRESHOLD = 0.9;
const TEXT_ON_EMPTY_LIST = 'Здесь появится ваша история обращений в службу поддержки';

const TicketsHistory = ({
  tickets,
  isLoading,
  onError,
  canLoadMore,
  loadFirstPage,
  loadNextPage,
  showTicketsItem,
  currentItemId
}) => {
  const isListEmpty = useMemo(() => isEmpty(tickets), [tickets]);
  const history = useHistory();
  const [tableNode, tableRef] = useNode();
  const [tableBodyNode, tableBodyRef] = useNode();

  const onUpdate = useCallback(
    values => {
      const hasScrolledToBottom = values.top >= VALUES_TOP_THRESHOLD;
      if (hasScrolledToBottom && !isLoading) {
        loadNextPage();
      }
    },
    [isLoading, loadNextPage]
  );

  useEffect(() => {
    if (isLoading || isListEmpty || !canLoadMore) {
      return;
    }

    if (!tableBodyNode || !tableNode) {
      return;
    }

    const componentRect = tableNode.getBoundingClientRect();
    const tableBodyRect = tableBodyNode.getBoundingClientRect();

    if (tableBodyRect.bottom <= componentRect.bottom) {
      loadNextPage();
    }
  }, [canLoadMore, isListEmpty, isLoading, loadNextPage, tableBodyNode, tableNode]);

  // Effect for scrolling to the title of the previously viewed news item
  useEffect(() => {
    const elem = document.getElementById(getTicketElementId(currentItemId));

    if (!elem) {
      return;
    }

    elem.scrollIntoView(false);
  }, [currentItemId]);

  if (isListEmpty && isLoading) {
    return (
      <section className='page__section std-table'>
        <ContentLoader />
      </section>
    );
  }

  if (isListEmpty && !canLoadMore) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_EMPTY_LIST} />
      </section>
    );
  }

  return (
    <section className='ticket-history-section'>
      <div className='appeals-history-box'>
        <div className='contacts-and-managers-title-wrapper'>
          <ArrowBack
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            rotation={0}
            style={{ minWidth: '28px', height: '28px' }}
            onClick={() => history.push('/support')}
          />
          <h4 className='contacts-and-managers-title'>История обращений</h4>
        </div>
        <div className='appeals-history-list'>
          {tickets.map((item, index) => {
            if (!item.place || !item.office) {
              return null;
            }

            return (
              <div onClick={showTicketsItem(item.id)} className='appeals-history-item' key={index}>
                <div className='appeals-history-item-description'>
                  <h4>{item.category.t_name}</h4>
                  <SupportAppealStatus statusId={item.status_id} />
                  <p>
                    {item.place.name} {item.office.t_name}
                  </p>
                </div>
                <div className='appeals-history-item-info'>
                  <h4>№{item.id}</h4>
                  <p>
                    {getDate(item.created_at)}
                    <br />
                    {getTime(item.created_at)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TicketsHistory;
