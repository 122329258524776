import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  authByCode,
  confirmEmail,
  confirmPassword,
  loginUser,
  registration,
  registrationFailed,
  resendCode,
  resendCodeEmail,
  resetPassword,
  sendCode,
  sendCodeEmail
} from '../api/AuthActions';
import useRecaptcha from '../../../utils/useRecaptcha';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';
import * as actions from '../../../store/actions';
import { event } from '../../../analytics';
import { WorkkiLogo } from '../../../shared/ui/icons/core/workkiLogo';
import divider from '../../../../media/icons/divider.svg';
import ResponseError from '../../../errors/ResponseError';
import { isArray } from 'lodash';
import RequestError from '../../../errors/RequestError';
import Portal, { createContainer } from '../../../hocs/Portal';
import { confirmPhoneCode, resendSMSWithPhoneCode } from '../api/AuthActions';
import { ForgetPasswordDialog } from '../../../widgets/AuthorizationWidgets/ForgetPasswordDialog';
import { ChangeUserDataDialog } from '../../../widgets/AuthorizationWidgets/ChangeUserDataDialog';
import { Loader } from '../../../shared/ui/components/Loader';
import PhoneConfirmationForm from '../../../widgets/AuthorizationWidgets/PhoneConfirmationForm';
import StepForm from '../../../shared/ui/components/StepForm';
import { TabsComponent } from '../../../shared/ui/components/Tabs/Tabs';
import { useLocation } from 'react-router-dom';
import PhoneAuthForm from '../../../widgets/AuthorizationWidgets/PhoneAuthForm';
import { formatPhoneNumber, validateEmail } from '../../../shared/utils/serviceUtils/validationUtils';
import { Dialog } from '../../../shared/ui/components/Dialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
const REACT_APP_GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
const ACTION = 'registration';

const RESEND_INTERVAL = 60;
const LOADER_CONTAINER_ID = 'loader-container';
const steps = [
  'personalData',
  'registrationFailed',
  'registrationComplete',
  'changePassword',
  'emailAuth',
  'phoneAuthPrimary',
  'emailCodeAuth',
  'emailCodeAuthCheck'
];

const AlternativeLogin = ({ isPending, isAuthenticated, login }) => {
  //TODO - пропсы не используются - убрать, привести в порядок
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const [params, setParams] = useState({});
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: {
      auth: '',
      register: '',
      forgetPassword: '',
      confirm: '',
      registrationFailed: ''
    },
    password: '',
    matchPassword: '',
    phone: '',
    firstName: ''
  });
  const [tabValue, setTabValue] = useState(0);
  const [showPassword, setShowPassword] = useState({
    password: false,
    matchPassword: false
  });
  const [filled, setFilled] = useState({
    email: {
      auth: false,
      register: false,
      forgetPassword: false,
      confirm: false,
      registrationFailed: false
    },
    password: false,
    matchPassword: false
  });
  const [isFocused, setIsFocused] = useState({
    email: {
      auth: false,
      register: false,
      forgetPassword: false,
      confirm: false,
      registrationFailed: false
    },
    password: false,
    matchPassword: false,
    firstName: false,
    phone: false
  });
  const [errors, setErrors] = useState({
    email: {
      auth: '',
      register: '',
      forgetPassword: '',
      code: '',
      confirm: '',
      registrationFailed: ''
    },
    commonError: '',
    password: '',
    matchPassword: '',
    firstName: '',
    phone: ''
  });
  const [validationError, setValidationError] = useState({
    email: {
      auth: false,
      register: false,
      forgetPassword: false,
      code: false,
      confirm: false,
      registrationFailed: false
    },
    commonError: false,
    password: false,
    matchPassword: false,
    firstName: false,
    phone: false
  });
  const isServerError = statusCode => {
    return statusCode ? statusCode.toString().startsWith('5') : false;
  };
  const [isForgetPassword, setIsForgetPassword] = useState({
    open: false,
    requestSent: false
  });
  const [isChangeData, setIsChangeData] = useState({
    open: false,
    type: '',
    requestSent: false
  });
  const [recaptcha, execute] = useRecaptcha({
    siteKey: REACT_APP_GOOGLE_RECAPTCHA_KEY,
    hideDefaultBadge: true
  });
  const [isMount, setIsMount] = useState(false);
  const [registrationStep, setRegistrationStep] = useState({
    type: 'personalData',
    data: {}
  });
  const [policyChecked, setPolicyChecked] = useState(false);
  const [timer, setTimer] = useState(RESEND_INTERVAL);
  const [infoMessage, setInfoMessage] = useState({
    message: 'Получить код в СМС',
    isDisabled: true
  });
  const [loadingStatus, setLoadingStatus] = useState('unset');
  const [disableButton, setIsDisableButton] = useState(false);
  const intervalRef = useRef(null);
  const isTimerActive = useMemo(() => timer > 0, [timer]);
  useEffect(() => {
    if (isTimerActive) {
      intervalRef.current = setInterval(() => {
        setTimer(t => t - 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => clearInterval(intervalRef.current);
  }, [isTimerActive]);
  useEffect(() => {
    setInfoMessage(prevState => {
      if (timer > 0) {
        if (registrationStep.type === 'emailCodeAuthCheck') {
          return {
            message: `Запрос нового письма через ${timer} сек`,
            isDisabled: true
          };
        } else {
          return {
            message:
              registrationStep.destination === 'flash-call'
                ? `Получить код в СМС через ${timer} сек`
                : `Запрос нового СМС через ${timer} сек`,
            isDisabled: true
          };
        }
      } else {
        if (registrationStep === 'emailCodeAuthCheck') {
          return {
            message: 'отправить письмо с кодом еще раз',
            isDisabled: false
          };
        } else {
          return {
            message:
              registrationStep.destination === 'flash-call' ? 'Получить код в СМС' : 'Получить код в СМС еще раз',
            isDisabled: false
          };
        }
      }
    });
  }, [timer, registrationStep.destination, registrationStep]);
  useEffect(() => {
    if (registrationStep.type === 'phoneConfirmation' || registrationStep.destination === 'sms') {
      setTimer(RESEND_INTERVAL);
    }
    setErrors(prevError => ({
      ...prevError,
      commonError: ''
    }));
  }, [registrationStep, tabValue]);
  useEffect(() => {
    if (tabValue === 0) {
      setRegistrationStep(prevStep => ({ ...prevStep, type: 'emailAuth' }));
    }
    if (tabValue === 1) {
      setRegistrationStep(prevStep => ({ ...prevStep, type: 'personalData' }));
    }
  }, [tabValue]);

  const handlePolicyChange = event => {
    setPolicyChecked(event.target.checked);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // const validateEmail = email => {
  //   const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return emailRegex.test(email);
  // };
  const validatePassword = password => {
    // Регулярное выражение для проверки пароля
    // (?=.*[a-zA-Z]) - наличие хотя бы одной латинской буквы (обязательно)
    // (?=.*\d) - наличие хотя бы одной цифры (обязательно)
    // [a-zA-Z\d!@#$%^&*]+ - только латинские буквы, цифры и указанные спецсимволы
    // {8,20} - длина пароля от 8 до 20 символов
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,20}$/;

    const isValid = passwordRegex.test(password);
    return isValid;
  };

  const { email, password, hasError, error, phone, firstName } = formData;
  useEffect(() => {
    createContainer({ id: LOADER_CONTAINER_ID });
    setIsMount(true);
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filteredParams = {};

    if (searchParams) {
      if (searchParams.has('action')) {
        filteredParams['action'] = searchParams.get('action');
      }
      if (searchParams.has('token')) {
        filteredParams['token'] = searchParams.get('token');
      }
    }
    setParams(filteredParams);
  }, [location]);

  useEffect(() => {
    if (params.action === 'registration_completed') {
      dispatch(confirmEmail(params.token));
      setRegistrationStep(prevStep => ({
        ...prevStep,
        type: 'registrationComplete'
      }));
    }
    if (params.action === 'forget_password') {
      setRegistrationStep(prevStep => ({
        ...prevStep,
        type: 'changePassword'
      }));
    }
  }, [params]);

  useEffect(() => {
    const allFieldsValid = obj => {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          if (!allFieldsValid(obj[key])) {
            return false;
          }
        } else if (obj[key] !== false) {
          return false;
        }
      }
      return true;
    };
    if (
      loadingStatus === 'loading' ||
      !allFieldsValid(validationError) ||
      (registrationStep.type === 'personalData' && tabValue === 1 && !policyChecked) ||
      registrationStep.type === 'registrationFailed'
    ) {
      //TODO доработать валидацию при завершении регистрации
      setIsDisableButton(true);
    } else {
      setIsDisableButton(false);
    }
  }, [loadingStatus, policyChecked, errors, validationError]);

  useEffect(() => {
    if (
      (registrationStep.type === 'personalData' ||
        registrationStep.type === 'phoneConfirmation' ||
        registrationStep.type === 'registrationFailed') &&
      tabValue === 1 &&
      (formData.firstName === '' || formData.email.register === '' || formData.phone === '')
    ) {
      setIsDisableButton(true);
    }
  }, [formData]);

  useEffect(() => {
    if (registrationStep.type === 'registrationFailed') {
      const isFormValid =
        formData.firstName.trim() !== '' &&
        formData.email.registrationFailed.trim() !== '' &&
        formData.phone.trim() !== '' &&
        policyChecked;

      setIsDisableButton(!isFormValid);
    }
  }, [formData, policyChecked, registrationStep.type]);

  const handleSubmit = (evt, type) => {
    evt.preventDefault();

    if (type === 'auth' || type === 'confirm') {
      setLoadingStatus('loading');
      const newEmail = email[type];
      dispatch(loginUser({ newEmail, password })).catch(error => {
        const status = error.status;
        if (status === 401) {
          const backendMessage = error.response && error.response.data && error.response.data.message;
          setErrors(prevErrors => ({
            ...prevErrors,
            commonError: backendMessage || 'Неверный логин или пароль'
          }));
        } else if (isServerError(status)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            commonError: 'Ошибка сервера'
          }));
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            commonError: 'Неизвестная ошибка'
          }));
        }
        setLoadingStatus('unset');
      });
    } else if (type === 'register') {
      const newEmail = email[type];
      const phoneWithCountryCode = '+7' + phone;
      setLoadingStatus('loading');
      const token = '';
      execute(ACTION)
        .catch(err => {})
        .then(reCaptcha => {
          dispatch(registration({ firstName, newEmail, phoneWithCountryCode, token }))
            .then(response => {
              setLoadingStatus('unset');
              setRegistrationStep(prevStep => ({
                ...prevStep,
                type: 'phoneConfirmation',
                destination: 'flash-call',
                data: response.data
              }));
              if (isChangeData.open) {
                setIsChangeData(prevState => ({
                  ...prevState,
                  open: false,
                  type: ''
                }));
              }
              return response;
            })
            .catch(error => {
              const requestErrors = error.response ? error.response.data : null;
              const status = error.status;
              if (requestErrors && !isServerError(status) && Array.isArray(requestErrors)) {
                setErrors(prevErrors => ({
                  ...prevErrors,
                  email: {
                    ...prevErrors.email,
                    [type]: requestErrors.find(error => error.field === 'email')
                      ? requestErrors.find(error => error.field === 'email').message
                      : ''
                  },
                  phone: requestErrors.find(error => error.field === 'mobile_phone')
                    ? requestErrors.find(error => error.field === 'mobile_phone').message
                    : '',
                  commonError: requestErrors.find(error => error.field === 'reCaptcha')
                    ? requestErrors.find(error => error.field === 'reCaptcha').message
                    : '',
                  firstName: requestErrors.find(error => error.field === 'name')
                    ? requestErrors.find(error => error.field === 'name').message
                    : ''
                }));
              } else if (isServerError(status)) {
                setErrors(prevErrors => ({
                  ...prevErrors,
                  email: {
                    ...prevErrors.email,
                    [type]: ''
                  },
                  phone: '',
                  commonError: 'Ошибка сервера'
                }));
              } else {
                setErrors(prevErrors => ({
                  ...prevErrors,
                  email: {
                    ...prevErrors.email,
                    [type]: ''
                  },
                  phone: '',
                  commonError: 'Неизвестная ошибка'
                }));
              }
              setLoadingStatus('unset');
            });
        });
    } else if (type === 'registrationFailed') {
      const newEmail = email.registrationFailed;
      const phoneWithCountryCode = '+7' + phone;

      dispatch(registrationFailed({ firstName, newEmail, phoneWithCountryCode }))
        .then(res => {
          if (res.status === 200) {
            setOpenDialog(true);
          }
        })
        .catch(error => {
          const requestErrors = error.response ? error.response.data : null;
          const status = error.status;

          if (requestErrors && !isServerError(status) && Array.isArray(requestErrors)) {
            setErrors(prevErrors => ({
              ...prevErrors,
              email: {
                ...prevErrors.email,
                [type]: requestErrors.find(err => err.field === 'email')
                  ? requestErrors.find(err => err.field === 'email').message
                  : ''
              },
              phone: requestErrors.find(err => err.field === 'mobile_phone')
                ? requestErrors.find(err => err.field === 'mobile_phone').message
                : '',
              commonError: requestErrors.find(err => err.field === 'name')
                ? requestErrors.find(err => err.field === 'name').message
                : ''
            }));
          } else if (isServerError(status)) {
            setErrors(prevErrors => ({
              ...prevErrors,
              commonError: 'Ошибка сервера. Попробуйте позже.'
            }));
          } else {
            setErrors(prevErrors => ({
              ...prevErrors,
              commonError: 'Неизвестная ошибка. Попробуйте снова.'
            }));
          }
        });
    }
  };
  const handleResendMessage = () => {
    const sendSms = false;
    const customerId = registrationStep.data.id;
    setLoadingStatus('loading');
    dispatch(resendSMSWithPhoneCode({ customerId, sendSms }))
      .then(response => {
        setLoadingStatus('unset');
      })
      .catch(() => {
        setLoadingStatus('unset');
      });
  };
  const handleSendCode = codeValues => {
    const code = codeValues.join('');
    const customerId = registrationStep.data.id;
    setLoadingStatus('loading');
    dispatch(confirmPhoneCode({ code, customerId }))
      .then(code => {
        if (code.data) {
          setErrors(prevErrors => ({
            ...errors,
            code: ''
          }));
          setRegistrationStep(prevStep => ({
            ...prevStep,
            type: 'registrationSuccess'
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Неправильный код'
          }));
        }
        setLoadingStatus('unset');
      })
      .catch(error => {
        if (error instanceof ResponseError && error.response && isArray(error.response.data)) {
          setErrors(prevErrors => ({
            ...errors,
            code: error.response.data
          }));
        } else if (error instanceof RequestError) {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сети.'
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сервера.'
          }));
        }
        setLoadingStatus('unset');
      });
  };
  //Авторизация по коду
  const handleSendAuthCode = codeValues => {
    const code = codeValues.join('');
    const customerId = registrationStep.data.customer_id;
    const type = 'phone_code';
    setLoadingStatus('loading');
    dispatch(authByCode({ code, customerId, type }))
      .then(code => {
        if (code.data) {
          setErrors(prevErrors => ({
            ...errors,
            code: ''
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Неправильный код'
          }));
        }
        setLoadingStatus('unset');
      })
      .catch(error => {
        if (error instanceof ResponseError && error.response && isArray(error.response.data)) {
          setErrors(prevErrors => ({
            ...errors,
            code: error.response.data
          }));
        } else if (error instanceof RequestError) {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сети.'
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сервера.'
          }));
        }
        setLoadingStatus('unset');
      });
  };
  const handleSendEmailAuthCode = codeValues => {
    const code = codeValues.join('');
    const customerId = registrationStep.data.customer_id;
    const type = 'email_code';
    setLoadingStatus('loading');
    dispatch(authByCode({ code, customerId, type }))
      .then(code => {
        if (code.data) {
          setErrors(prevErrors => ({
            ...errors,
            code: ''
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Неправильный код'
          }));
        }
        setLoadingStatus('unset');
      })
      .catch(error => {
        if (error instanceof ResponseError && error.response) {
          setErrors(prevErrors => ({
            ...errors,
            code: error.response.data.message
          }));
        } else if (error instanceof RequestError) {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сети.'
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сервера.'
          }));
        }
        setLoadingStatus('unset');
      });
  };
  //первичное получение информации по телефону customer_id
  const handleTriggerCall = evt => {
    evt.preventDefault();
    const phoneWithCountryCode = '7' + phone;
    setLoadingStatus('loading');
    dispatch(sendCode({ phoneWithCountryCode }))
      .then(response => {
        setLoadingStatus('unset');
        setRegistrationStep(prevStep => ({
          ...prevStep,
          type: 'phoneAuth',
          destination: 'flash-call',
          data: response.data.data
        }));
        if (isChangeData.open) {
          setIsChangeData(prevState => ({
            ...prevState,
            open: false,
            type: ''
          }));
        }
        setTimer(RESEND_INTERVAL);
        return response;
      })
      .catch(error => {
        const requestErrors = error.response ? error.response.data : null;
        const status = error.status;
        if (requestErrors && !isServerError(status) && Array.isArray(requestErrors)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: requestErrors.find(error => error.field === 'mobile_phone')
              ? requestErrors.find(error => error.field === 'mobile_phone').message
              : '',
            commonError: requestErrors.find(error => error.field === 'reCaptcha')
              ? requestErrors.find(error => error.field === 'reCaptcha').message
              : ''
          }));
        } else if (isServerError(status)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Ошибка сервера'
          }));
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Неизвестная ошибка'
          }));
        }
        setLoadingStatus('unset');
      });
  };
  const handleAuthResendMessage = () => {
    const phoneWithCountryCode = '7' + phone;
    setLoadingStatus('loading');
    dispatch(resendCode({ phoneWithCountryCode }))
      .then(response => {
        setLoadingStatus('unset');
      })
      .catch(() => {
        setLoadingStatus('unset');
      });
  };

  const handleTriggerEmailCode = evt => {
    const emailForCode = email.auth;
    evt.preventDefault();
    setLoadingStatus('loading');
    dispatch(sendCodeEmail(emailForCode))
      .then(response => {
        setLoadingStatus('unset');
        setRegistrationStep(prevStep => ({
          ...prevStep,
          type: 'emailCodeAuthCheck',
          data: response.data.data
        }));
        if (isChangeData.open) {
          setIsChangeData(prevState => ({
            ...prevState,
            open: false,
            type: ''
          }));
        }
        return response;
      })
      .catch(error => {
        const requestError = error.response ? error.response.data : error.data ? error.data : null;
        const status = error.status;
        if (requestError && !isServerError(status)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: requestError.message
          }));
        } else if (isServerError(status)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Ошибка сервера'
          }));
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Неизвестная ошибка'
          }));
        }
        setLoadingStatus('unset');
      });
  };
  const handleEmailAuthResendMessage = () => {
    const emailForCode = email.auth;
    setLoadingStatus('loading');
    dispatch(resendCodeEmail(emailForCode))
      .then(response => {
        setLoadingStatus('unset');
      })
      .catch(() => {
        setLoadingStatus('unset');
      });
  };
  const handleForgetPassword = useCallback(
    evt => {
      evt.preventDefault();
      //TODO добавить потом обработку капчи туда - куда надо
      // setRequestStatus(STATUSES.PENDING);
      // execute()
      //   .catch(err => {
      //     console.log(err, 'ошибка ');
      //     // setErrors(prevErrors => ({
      //     //   ...prevErrors,
      //     //   email: {
      //     //     ...prevErrors.email,
      //     //     forgetPassword: 'Проблемы google recaptcha.'
      //     //   }
      //     // }));
      //     // setRequestStatus(STATUSES.FAILURE);
      //   })
      //   .then(token => {
      setLoadingStatus('loading');
      dispatch(confirmPassword(email.forgetPassword, ''))
        .then(response => {
          setErrors(prevErrors => ({
            ...prevErrors,
            email: {
              ...prevErrors.email,
              forgetPassword: ''
            }
          }));
          setLoadingStatus('unset');
          setIsForgetPassword(prevState => ({ ...prevState, requestSent: true }));
          return response;
        })
        .catch(error => {
          const status = error.status;
          const requestErrors = error.response ? error.response.data : null;
          if (requestErrors && !isServerError(status) && Array.isArray(requestErrors)) {
            setErrors(prevErrors => ({
              ...prevErrors,
              email: {
                ...prevErrors.email,
                forgetPassword: requestErrors.find(error => error.field === 'email')
                  ? requestErrors.find(error => error.field === 'email').message
                  : ''
              }
            }));
          } else if (isServerError(status)) {
            setErrors(prevErrors => ({
              ...prevErrors,
              email: {
                ...prevErrors.email,
                forgetPassword: 'Ошибка сервера'
              }
            }));
          } else {
            setErrors(prevErrors => ({
              ...prevErrors,
              email: {
                ...prevErrors.email,
                forgetPassword: 'Неизвестная ошибка'
              }
            }));
          }
          setLoadingStatus('unset');
        });
    },
    [execute, dispatch, email]
  );
  const handleResetPassword = event => {
    const password = formData.password;
    const token = params.token;
    return dispatch(resetPassword(token, password))
      .then(() => {
        console.log('пароль сброшен');
      })
      .catch(err => {
        if (err && err.status === 404) {
          console.log(err);
          return;
        }
        console.log(err);
      });
  };

  const handleAuthSubmit = evt => handleSubmit(evt, 'auth');
  const handleInstantAuth = evt => handleSubmit(evt, 'confirm');

  const handleRegisterSubmit = evt => handleSubmit(evt, 'register');
  const handleFailedRegisterSubmit = evt => handleSubmit(evt, 'registrationFailed');

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    const inputValue = value.replace(/\s+/g, '');

    updateFilledState(name, inputValue);
    handleValidation(name, inputValue);
    updateFormData(name, inputValue);
  };

  const updateFilledState = (name, inputValue) => {
    const type = isForgetPassword.open
      ? 'forgetPassword'
      : registrationStep.type === 'registrationComplete'
      ? 'confirm'
      : registrationStep.type === 'registrationFailed'
      ? 'registrationFailed'
      : tabValue === 1
      ? 'register'
      : 'auth';
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]:
        name === 'email'
          ? {
              ...prevFilled[name],
              [type]: true
            }
          : inputValue !== ''
    }));
  };

  const handleValidation = (name, inputValue) => {
    switch (name) {
      case 'email':
        handleEmailValidation(inputValue);
        break;
      case 'password':
        if (registrationStep.type !== 'registrationComplete') {
          handlePasswordValidation(inputValue);
        }
        break;
      case 'matchPassword':
        handleMatchPasswordValidation(inputValue);
        break;
    }
  };

  const handleEmailValidation = inputValue => {
    const type = isForgetPassword.open ? 'forgetPassword' : 'register';
    const isValidEmail = validateEmail(inputValue);

    if (!isValidEmail && tabValue === 1) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          [type]: 'Неверный формат email'
        }
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: {
          ...prevError.email,
          [type]: true
        }
      }));
    } else if (!isValidEmail && registrationStep.type === 'registrationFailed') {
      console.log(registrationStep.type);
      setErrors(prevErrors => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          registrationFailed: 'Неверный формат email'
        }
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: {
          ...prevError.email,
          registrationFailed: true
        }
      }));
    } else if (!isValidEmail && isForgetPassword.open) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          forgetPassword: 'Неверный формат email'
        }
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: {
          ...prevError.email,
          [type]: true
        }
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          register: '',
          registrationFailed: '',
          forgetPassword: ''
        }
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          register: false,
          registrationFailed: false,
          forgetPassword: false
        }
      }));
    }
  };

  const handlePasswordValidation = inputValue => {
    if (!validatePassword(inputValue) && tabValue === 1) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Неверный формат пароля'
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        password: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: ''
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        password: false
      }));
    }
  };

  const handleMatchPasswordValidation = inputValue => {
    if (inputValue !== formData.password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        matchPassword: 'Пароли не совпадают'
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        matchPassword: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        matchPassword: ''
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        matchPassword: false
      }));
    }
  };

  const updateFormData = (name, inputValue) => {
    setFormData(prevState => {
      if (name === 'phone') {
        const cleaned = inputValue.replace(/\D/g, '');
        const limitedInput = cleaned.substring(0, 10);
        return {
          ...prevState,
          [name]: formatPhoneNumber(limitedInput, setErrors, setValidationError)
        };
      } else if (name === 'email') {
        //TODO тоже страдает логика из-за таба
        const type = isForgetPassword.open
          ? 'forgetPassword'
          : registrationStep.type === 'registrationComplete'
          ? 'confirm'
          : registrationStep.type === 'registrationFailed'
          ? 'registrationFailed'
          : tabValue === 1
          ? 'register'
          : 'auth';
        return {
          ...prevState,
          email: {
            ...prevState.email,
            [type]: inputValue
          }
        };
      } else {
        return {
          ...prevState,
          [name]: inputValue
        };
      }
    });
  };
  const handleFocus = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: fieldName === 'email' ? { ...prevFocused[fieldName], [type]: true } : true
    }));
  };

  const handleBlur = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: fieldName === 'email' ? { ...prevFocused[fieldName], [type]: false } : false
    }));
  };

  const handleRegistrationClick = () => event('registracia');
  if (isAuthenticated) {
    return null;
  }
  const handlePhoneAuthClick = () => {
    if (registrationStep.type === 'emailAuth') {
      setRegistrationStep(prevStep => ({
        ...prevStep,
        type: 'phoneAuthPrimary',
        destination: 'flash-call'
      }));
    } else {
      setRegistrationStep(prevStep => ({
        ...prevStep,
        type: 'emailAuth'
      }));
    }
  };

  const handleEmailCodeAuth = () => {
    setRegistrationStep(prevStep => ({
      ...prevStep,
      type: 'emailCodeAuth'
    }));
  };

  const handleClickShowPassword = field => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };
  const handleEraseValue = (fieldName, type) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: fieldName === 'email' ? { ...prevState[fieldName], [type]: '' } : ''
    }));
  };
  const tabs = [{ label: 'Вход' }, { label: 'Регистрация' }];

  return (
    <div>
      <div className='authorization__tabs-wrapper'>
        {registrationStep.type === 'personalData' ||
        registrationStep.type === 'emailAuth' ||
        registrationStep.type === 'emailCodeAuth' ||
        registrationStep.type === 'emailCodeAuthCheck' ||
        registrationStep.type === 'phoneAuthPrimary' ? (
          <div className='authorization__tabs-wrapper__logo-wrapper'>
            <WorkkiLogo width={'136px'} height={'24px'} />
          </div>
        ) : (
          <div className='authorization__tabs-wrapper__phone-logo-wrapper'>
            <WorkkiLogo width={'136px'} height={'24px'} />
          </div>
        )}
        {(registrationStep.type === 'personalData' ||
          registrationStep.type === 'emailAuth' ||
          registrationStep.type === 'emailCodeAuth' ||
          registrationStep.type === 'emailCodeAuthCheck' ||
          registrationStep.type === 'phoneAuthPrimary') && (
          <TabsComponent
            tabsContainerStyle={{ width: '100%', height: '48px' }}
            tabStyle={{ width: '158px', height: '40px' }}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            tabs={tabs}
          />
        )}
      </div>
      <main className='authorization__fields-wrapper'>
        {steps.map((step, index) => {
          if (registrationStep.type === step) {
            return (
              <StepForm
                key={index}
                stepType={
                  registrationStep.type === 'registrationComplete' ? 'registrationComplete' : registrationStep.type
                }
                setTimer={setTimer}
                RESEND_INTERVAL={RESEND_INTERVAL}
                handleTriggerCall={handleTriggerCall}
                handleTriggerEmailCode={handleTriggerEmailCode}
                handleRegisterSubmit={handleRegisterSubmit}
                handleFailedRegisterSubmit={handleFailedRegisterSubmit}
                handleAuthSubmit={handleAuthSubmit}
                handleInputChange={handleInputChange}
                handleResetPassword={handleResetPassword}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleEraseValue={handleEraseValue}
                handleClickShowPassword={handleClickShowPassword}
                handlePolicyChange={handlePolicyChange}
                setIsForgetPassword={setIsForgetPassword}
                handlePhoneAuthClick={handlePhoneAuthClick}
                setIsChangeData={setIsChangeData}
                handleEmailCodeAuth={handleEmailCodeAuth}
                handleSendCode={handleSendEmailAuthCode}
                infoMessage={infoMessage}
                email={registrationStep.data.email}
                handleEmailAuthResendMessage={handleEmailAuthResendMessage}
                handleInstantAuth={handleInstantAuth}
                setLoadingStatus={value => setLoadingStatus(value)}
                errors={errors}
                formData={formData}
                filled={filled}
                isFocused={isFocused}
                showPassword={showPassword}
                isPending={isPending}
                policyChecked={policyChecked}
                disableButton={disableButton}
                divider={divider}
                setRegistrationStep={setRegistrationStep}
              />
            );
          }
          return null;
        })}
        {registrationStep.type === 'phoneAuth' && registrationStep.destination === 'flash-call' && (
          <PhoneAuthForm
            phoneNumber={formData.phone}
            clientId={registrationStep.data.client_id}
            messageType='call'
            handleSendCode={handleSendAuthCode}
            handleResendMessage={handleAuthResendMessage}
            handleRegisterSubmit={handleRegisterSubmit}
            setIsChangeData={setIsChangeData}
            setRegistrationStep={setRegistrationStep}
            infoMessage={infoMessage}
            errors={errors}
            RESEND_INTERVAL={RESEND_INTERVAL}
            setTimer={setTimer}
            divider={divider}
            handlePhoneAuthClick={handlePhoneAuthClick}
          />
        )}
        {registrationStep.type === 'phoneAuth' && registrationStep.destination === 'sms' && (
          <PhoneAuthForm
            phoneNumber={formData.phone}
            clientId={registrationStep.data.client_id}
            messageType='sms'
            handleSendCode={handleSendAuthCode}
            handleResendMessage={handleAuthResendMessage}
            handleRegisterSubmit={handleRegisterSubmit}
            setIsChangeData={setIsChangeData}
            setRegistrationStep={setRegistrationStep}
            infoMessage={infoMessage}
            errors={errors}
            RESEND_INTERVAL={RESEND_INTERVAL}
            setTimer={setTimer}
            divider={divider}
            handlePhoneAuthClick={handlePhoneAuthClick}
          />
        )}
        {registrationStep.type === 'phoneConfirmation' && registrationStep.destination === 'flash-call' && (
          <PhoneConfirmationForm
            phoneNumber={formData.phone}
            email={registrationStep.data.email}
            clientId={registrationStep.data.client_id}
            messageType='call'
            handleSendCode={handleSendCode}
            handleResendMessage={handleResendMessage}
            handleRegisterSubmit={handleRegisterSubmit}
            setIsChangeData={setIsChangeData}
            setRegistrationStep={setRegistrationStep}
            infoMessage={infoMessage}
            errors={errors}
            RESEND_INTERVAL={RESEND_INTERVAL}
            setTimer={setTimer}
          />
        )}
        {registrationStep.type === 'phoneConfirmation' && registrationStep.destination === 'sms' && (
          <PhoneConfirmationForm
            phoneNumber={registrationStep.data.mobile_phone}
            email={registrationStep.data.email}
            messageType='sms'
            handleSendCode={handleSendCode}
            handleResendMessage={handleResendMessage}
            handleRegisterSubmit={handleRegisterSubmit}
            setIsChangeData={setIsChangeData}
            setRegistrationStep={setRegistrationStep}
            infoMessage={infoMessage}
            errors={errors}
            RESEND_INTERVAL={RESEND_INTERVAL}
            setTimer={setTimer}
          />
        )}
        {registrationStep.type === 'registrationSuccess' && (
          <div>
            <div className='authorization__phone-caption'>
              <div className='authorization__phone-caption__successWrapper'>
                <p>Проверьте почту</p>
                <span>{`На адрес ${registrationStep.data.email} было отправлено письмо с паролем.`}</span>
                <span>Если вы не видите письмо, пожалуйста, проверьте, не попало ли оно в спам.</span>
              </div>
            </div>
          </div>
        )}
      </main>
      <>
        {isForgetPassword.open && (
          <ForgetPasswordDialog
            dialogType='forgetPassword'
            disableButton={disableButton}
            errors={errors}
            formData={formData}
            handleInputChange={handleInputChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            handleEraseValue={handleEraseValue}
            handleSubmit={handleForgetPassword}
            isPending={isPending}
            filled={filled}
            isFocused={isFocused}
            handleClose={() => setIsForgetPassword(prevState => ({ ...prevState, open: false, requestSent: false }))}
            requestSent={isForgetPassword.requestSent}
          />
        )}
        {isChangeData.open && (
          <ChangeUserDataDialog
            dialogType={isChangeData.type}
            disableButton={disableButton}
            errors={errors}
            formData={formData}
            handleInputChange={handleInputChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            handleEraseValue={handleEraseValue}
            handleSubmit={
              isChangeData.type === 'phoneConfirmation'
                ? handleRegisterSubmit
                : isChangeData.type === 'emailAuth'
                ? handleTriggerEmailCode
                : handleTriggerCall
            }
            isPending={isPending}
            filled={filled}
            isFocused={isFocused}
            handleClose={() => setIsChangeData(prevState => ({ ...prevState, open: false }))}
            requestSent={isChangeData.requestSent}
          />
        )}
      </>
      {isMount && loadingStatus === 'loading' && (
        <Portal id={LOADER_CONTAINER_ID}>
          <Loader />
        </Portal>
      )}
      {openDialog && (
        <Dialog
          title='Заявка отправлена'
          handleClose={() => {
            setOpenDialog(false);
            history.push('/');
          }}
          buttonLabel='Cпасибо'
          dialogType='failedRegistrationSuccess'
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const requestAuthStatus = requestStatusSelector(actions.requestLogin)(state);
  return {
    errorMessage: state.errors.auth,
    isPending: requestAuthStatus.isPending,
    isAuthenticated: state.isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(loginUser({ email, password }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlternativeLogin);
