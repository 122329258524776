import React, { useState } from 'react';
import { ArrowBack } from '../icons/core/ArrowBack';
import styled from 'styled-components';
import { useResize } from '../../utils/hooks/useResize';
import { screenRessolutions } from '../constants/styleConstants';

const StepLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '')};

  & > p {
    margin: 0;
    padding: 0 43px 0 0;
    font-family: Commissioner, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #24272a;
    text-align: ${props => props.textAlign};
    width: 100%;
  }

  @media (min-width: ${screenRessolutions.laptop}px) {
    & > p {
      padding: 0;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.03em;
    }
  }
`;

const StepBack = ({
  title,
  onClick,
  arrowsSpacing,
  titleStyle,
  textAlign = 'center',
  hideArrow = false,
  maxWidth,
  ...props
}) => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const checkLaptop = () => {
    const width = window.innerWidth >= screenRessolutions.laptop;
    if (width !== isLaptop) setIsLaptop(width);
  };

  useResize(checkLaptop, 0);
  return (
    <StepLabel maxWidth={maxWidth} {...props}>
      {!hideArrow && (
        <ArrowBack
          onClick={onClick}
          defaultColor={'#24272A'}
          spacing={arrowsSpacing}
          hoverColor={'#383c40'}
          bigArrow={isLaptop}
          style={{ minWidth: isLaptop ? '32px' : '28px', height: isLaptop ? '32px' : '28px' }}
        />
      )}
      <p style={{ ...titleStyle }}>{title}</p>
    </StepLabel>
  );
};

export default StepBack;
