import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/screenResolutions.constants';
import { useResize } from '../../shared/utils/hooks/useResize';
import StepBack from '../../shared/ui/components/StepBack';
import { StepButton } from '../../shared/ui/components/StepButton';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SupportContactsAndManagers from './SupportContactsAndManagers';
import SupportInformationLinks from './SupportInformationLinks';
import SupportHistoryList from './SupportHistoryList';
import { MainWrapper } from '../../entities/Payments/model/Payments';
import { UserIdBox } from '../../entities/Dashboard/DashboardUserInfoSections';

const TitleBox = styled.div`
  margin: -6px 0 8px 0;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;

  @media screen and (min-width: ${screenRessolutions.tablet}px) {
    margin: 2px 0 8px 0;
  }

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    margin: 10px 0 24px 0;
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin: 16px 0 40px 0;
    display: flex;
  }
`;

export const PrefillForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: ${props => props.position};
  overflow: auto;
  @media (min-width: 320px) {
    width: 100%;
    padding: 16px;
    overflow: auto;
    height: fit-content;
    padding-bottom: 120px;
  }
  @media (min-width: 768px) {
    min-width: 340px;
    max-width: 650px;
  }
  @media (min-width: 1366px) {
    min-width: 416px;
    max-width: 650px;
  }
  @media (min-width: 1920px) {
    width: 600px;
  }
`;

const TopButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  grid-area: button;
  justify-items: flex-end;
  flex-direction: row-reverse;
`;

const Support = ({ offices, setShowTicketDialog, customer, tickets }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const defaultScreenState = {
    laptop: window.innerWidth >= screenRessolutions.laptop,
    desktop: window.innerWidth >= screenRessolutions.desktop
  };
  const [screenWidthState, setScreenWidthState] = useState({ ...defaultScreenState });
  const setScreenState = () => {
    const isLaptopScreen = window.innerWidth >= screenRessolutions.laptop;
    const isDesktopScreen = window.innerWidth >= screenRessolutions.desktop;

    if (isLaptopScreen !== screenWidthState.laptop || isDesktopScreen !== screenWidthState.desktop) {
      setScreenWidthState({
        laptop: isLaptopScreen,
        desktop: isDesktopScreen
      });
    }
  };

  useResize(setScreenState, 0);

  return (
    <MainWrapper>
      <PrefillForm>
        <TitleBox>
          <StepBack
            title={'Поддержка'}
            style={{ gridArea: 'stepBack', maxWidth: screenWidthState.desktop ? '239px' : '' }}
            onClick={() => history.goBack()}
            titleStyle={{ padding: 0 }}
            textAlign={'left'}
          />
          {screenWidthState.laptop && (
            <TopButtonWrapper>
              <StepButton
                handleAction={() => setShowTicketDialog(true)}
                type='filled'
                title='Обратиться в поддержку'
                filledType='red'
                spacing='0'
              />
            </TopButtonWrapper>
          )}
        </TitleBox>
        <CardShell padding={'14px 16px'} type='support'>
          <UserIdBox>
            <p>ID аккаунта:</p>
            <p>{customer.id}</p>
          </UserIdBox>
        </CardShell>
        <SupportContactsAndManagers screenWidthState={screenWidthState} offices={offices} />
        <SupportInformationLinks screenWidthState={screenWidthState} />
        {tickets.length > 0 && <SupportHistoryList tickets={tickets} />}
      </PrefillForm>
    </MainWrapper>
  );
};

export default Support;
