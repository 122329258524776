import React from 'react';
import { InputBoxes } from '../../../shared/ui/components/InputBoxes';

const EmailCodeAuthCheckStep = ({
  entranceFormClass,
  errors,
  handlePhoneAuthClick,
  divider,
  RESEND_INTERVAL,
  email,
  setTimer,
  handleSendCode,
  setIsChangeData,
  handleEmailAuthResendMessage,
  handleRegisterSubmit,
  infoMessage
}) => {
  return (
    <div>
      <div className='authorization__phone-caption'>
        <div className='authorization__phone-caption__phoneAuthWrapper'>
          <span>{email}</span>
          <div onClick={() => setIsChangeData(prevState => ({ ...prevState, open: true, type: 'emailAuth' }))}>
            <span>Изменить</span>
          </div>
        </div>
      </div>
      <div className='authorization__phone-main'>
        <p>Введите код из письма</p>
        <div className='authorization__phone-main__phone-fields'>
          <InputBoxes
            length={4}
            error={errors.code}
            handleRequest={value => {
              handleSendCode(value);
            }}
          />
        </div>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={handleRegisterSubmit}
        autoComplete='false'
        id='email-code-check-form'
        noValidate
      >
        <div className='authorization__footer'>
          <div className='authorization__footer__links-wrapper'>
            <a
              onClick={() => {
                if (!infoMessage.isDisabled) {
                  handleEmailAuthResendMessage();
                  setTimer(RESEND_INTERVAL);
                }
              }}
            >
              <span
                className={`authorization__footer__links-wrapper__${
                  infoMessage.isDisabled ? 'timer-disabled' : 'timer-active'
                }`}
              >
                {infoMessage.message}
              </span>
            </a>
            <img src={divider} alt='divider' />
            <a href='#' onClick={handlePhoneAuthClick}>
              Войти по e-mail и паролю
            </a>
            <p className='authorization__footer__policy'>
              Выполняя вход, вы соглашаетесь{' '}
              <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
                с договором публичной оферты
              </a>
              &#160;и&#160;
              <a href='https://workki.co/docs/Personal-data.pdf' target='_blank'>
                правилами обработки данных
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailCodeAuthCheckStep;
