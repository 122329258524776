import React from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';

const formatDate = date => {
  const momentDate = moment(date, 'YYYY.MM.DD HH:mm:ss');
  const now = moment();

  if (momentDate.isSame(now, 'day')) {
    return `Сегодня, ${momentDate.format('HH:mm')}`;
  }
  if (momentDate.isSame(now.subtract(1, 'day'), 'day')) {
    return `Вчера, ${momentDate.format('HH:mm')}`;
  }
  return momentDate.format('DD MMMM, HH:mm');
};

const CommentItem = ({ comment, customer, showDateDivider, offices, ticket }) => {
  const managers = ticket.office.communityManagers;
  const activeManager = managers.find(manager => manager.id === comment.manager_id);

  const name = customer.is_company ? customer.individual.name_short : customer.individual.first_name;
  const nameChar = name[0];
  return (
    <>
      {showDateDivider && <div className='tickets-message-date-divider'>{formatDate(comment.created_at)}</div>}
      <div
        className={classNames('tickets-message-row', {
          'manager-message': comment.manager_id,
          'user-message': !comment.manager_id
        })}
      >
        <div className='tickets-message-bubble'>
          <div className='tickets-message-name'>
            {activeManager && activeManager.first_name ? activeManager.first_name : 'Вы'}
          </div>
          <div className='tickets-message-text'>{comment.text}</div>
          <div className='tickets-message-date'>{formatDate(comment.created_at)}</div>
        </div>
        <div className='tickets-message-avatar'>
          {comment.manager_id ? 'М' : nameChar}
          {activeManager && activeManager.photo && (
            <img className='tickets-message-avatar-img' src={activeManager.photo} alt='' />
          )}
        </div>
      </div>
    </>
  );
};

export default CommentItem;
