import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { FileUploadPreview } from '../../../shared/ui/components/Field/FileUploadField';
import { useDispatch } from 'react-redux';
import { IconComponent } from '../../../shared/ui/components/Field/IconComponent';
import { FileContainer } from '../../Services/Print/PrintFileWidget';
import { colors } from '../../../shared/ui/constants/styleConstants';
import { styled } from 'styled-components';
import { changeEmployeeCredentialsRequest } from '../../../services/workki/ChildActions';
export const Caption = styled.div`
  margin: 0 0 16px 0;
  font-weight: 400;
  color: ${colors.workkiDarkFill};
`;

const ChangeEmployeeCredentialsWidget = ({ handleClose }) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleInputChange = inputValue => {
    setError('');
    setMessage(inputValue);
  };

  const handleRemoveFile = fileName => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  useEffect(() => {
    setIsSubmitDisabled(!message.trim());
  }, [message]);

  const dispatch = useDispatch();

  const handleCreateRequest = useCallback(
    async event => {
      event.preventDefault();

      dispatch(changeEmployeeCredentialsRequest(message, files)).then(response => {
        if (response.status === 200) {
          handleClose();
        } else if (response.response && response.response.data && response.response.data.errors) {
          const errors = response.response.data.errors;
          if (errors.document_files) {
            setError(errors.document_files);
          } else {
            setError(`Не удалось отправить данные. Код ошибки: ${response.status}`);
          }
        }
      });
    },
    [message, files, dispatch, handleClose]
  );

  return (
    <>
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleCreateRequest}
        autoComplete='off'
        noValidate
      >
        <Caption>Пожалуйста, укажите, какие данные изменились.</Caption>
        <FormControl fullWidth={true}>
          <CustomTextField
            error={error}
            type='text'
            name='message'
            label='Текст сообщения'
            variant='filled'
            value={message}
            required={true}
            onChange={e => handleInputChange(e.target.value)}
            helperText={error}
            autoFocus={true}
          />
          <FileUploadPreview fieldType='changeCredentials' files={files} setFiles={newFiles => setFiles(newFiles)} />
          {files.length > 0 && (
            <FileContainer>
              <p>Прикрепленные файлы</p>
              {files.map((file, index) => (
                <div key={index}>
                  <span>{file.name}</span>
                  <IconComponent type={'remove'} handleAction={() => handleRemoveFile(file.name)} />
                </div>
              ))}
            </FileContainer>
          )}
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title={'Отправить'}
              handleAction={handleCreateRequest}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default ChangeEmployeeCredentialsWidget;
