import React from 'react';
import { TextField, FormControl, InputAdornment, FormHelperText } from '@mui/material';
import EndAdornment from './EndAdornment';
import { createStyles, makeStyles } from '@mui/styles';
import { colors } from '../../../ui/constants/styleConstants';

const useStyles = makeStyles(theme =>
  createStyles({
    formControlRoot: {
      'marginBottom': 16,
      '&.MuiFormControl-fullWidth:focus': {
        borderRadius: 8
      }
    },
    inputOutlinedRoot: {
      '&.MuiOutlinedInput-root': {
        'backgroundColor': colors.workkiLightLight1,
        'borderRadius': 8,
        'padding': '0 12px',

        '&:hover': {
          backgroundColor: colors.workkiLightLight05,
          borderColor: colors.workkiDarkMiddle
        },
        '&.Mui-focused': {
          backgroundColor: colors.workkiLightLight05,
          borderColor: colors.workkiDarkMiddle
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }
    },
    inputBaseRoot: {
      '&.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
        'backgroundColor': colors.workkiLightLight1,
        'borderRadius': 8,
        'paddingRight': 0,
        'cursor': 'pointer',
        '&::before': {
          border: `transparent 1px solid`,
          borderRadius: 8
        },
        '&::after': {
          border: '1px solid transparent',
          borderRadius: 8
        }
      },
      '&.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root': {
        'border': `solid 1px transparent !important`,
        'paddingRight': 0,
        'paddingLeft': 0,
        '&.Mui-error': {
          'backgroundColor': `${colors.workkiRedLight} !important`,
          '&.Mui-focused, &:hover': {
            backgroundColor: `${colors.workkiLightLight05} !important`
          }
        },
        '&.Mui-focused, &:hover': {
          backgroundColor: `${colors.workkiLightLight05} !important`,
          cursor: 'pointer'
        }
      },
      '&.css-17gnpz5-MuiInputBase-root-MuiFilledInput-root': {
        '&.Mui-focused, &:hover': {
          backgroundColor: colors.workkiLightLight05,
          cursor: 'pointer'
        },
        '&.Mui-error': {
          backgroundColor: `${colors.workkiRedLight} !important`
        },
        '&.MuiFormHelperText-root': {
          '&.Mui-error': {
            marginLeft: '0'
          }
        }
      }
    },
    formHelperTextRoot: {
      margin: '0 !important',
      color: `${colors.workkiRedMain} !important`
    },
    inputAdornmentRoot: {
      margin: 0
    },
    inputBaseInput: {
      '&.css-10botns-MuiInputBase-input-MuiFilledInput-input:focus': {
        backgroundColor: `${colors.workkiLightLight05} !important`,
        // border: `1px ${colors.workkiLightLine} solid !important`,
        borderRadius: 8
      }
    },
    label: {
      'paddingLeft': 0,
      '&.Mui-focused': {
        color: `${colors.workkiDarkMiddle} !important`
      },
      '&.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root, &.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root.Mui-error': {
        color: `${colors.workkiDarkMiddle} !important`
      }
    }
  })
);

export const CustomTextField = ({
  error,
  type,
  name,
  id,
  label,
  variant,
  value,
  onFocus,
  required,
  onBlur,
  onChange,
  disabled,
  hideAdornment,
  helperText,
  endAdornmentProps,
  startAdornment,
  placeholder,
  onKeyDown,
  autoFocus,
  inputRef
}) => {
  const classes = useStyles();

  const rootClass = variant === 'filled' ? classes.inputBaseRoot : classes.inputOutlinedRoot;

  return (
    <FormControl fullWidth={true} className={classes.formControlRoot}>
      <TextField
        error={error !== ''}
        inputRef={inputRef}
        type={type}
        name={name}
        id={id}
        onKeyDown={onKeyDown}
        required={required}
        label={label}
        variant={variant}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        helperText={
          helperText ? <FormHelperText className={classes.formHelperTextRoot}>{helperText}</FormHelperText> : ''
        }
        placeholder={placeholder}
        autoFocus={autoFocus}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.label
          }
        }}
        InputProps={{
          classes: {
            root: rootClass,
            input: classes.inputBaseInput
          },
          endAdornment: endAdornmentProps && (
            <EndAdornment
              filled={endAdornmentProps.filled}
              isFocused={endAdornmentProps.isFocused}
              fieldName={name}
              hideAdornment={hideAdornment}
              errors={error}
              disabled={disabled}
              onFocus={onFocus}
              handleEraseValue={endAdornmentProps.handleEraseValue}
              showPassword={endAdornmentProps.showPassword}
              handleClickShowPassword={endAdornmentProps.handleClickShowPassword}
            />
          ),
          startAdornment: startAdornment && (
            <InputAdornment
              onMouseDown={e => {
                e.preventDefault();
                onFocus();
              }}
              position='start'
              className={classes.inputAdornmentRoot}
            >
              {startAdornment}
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};
