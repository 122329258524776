import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { customerFilesSelector, customerPhotoSelector, customerSelector } from '../store/selectors/DefaultSelectors';
import StepBack from '../shared/ui/components/StepBack';
import ProfileCard from '../shared/ui/components/Profile/ProfileCard';
import {
  confirmPhoneCode,
  logoutUser,
  pofilePhoneConfirm,
  pofilePhoneSendCode,
  resendSMSWithPhoneCode,
  sendCode
} from '../entities/Authentification/api/AuthActions';
import { ChangeUserDataDialog } from '../widgets/AuthorizationWidgets/ChangeUserDataDialog';
import requestStatusSelector from '../store/selectors/requestStatusSelector';
import * as actions from '../store/actions';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PageLoader from '../components/PageLoader';
import PrefillProfilePage from '../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import ProfileAvatarModal from '../components/Profile/ProfileSocial/ProfileAvatarModal';
import ProfileAvatar from '../components/Profile/ProfileSocial/ProfileAvatar';
import Portal, { createContainer } from '../hocs/Portal';
import { Confirmed, Dialog, DialogContent, DialogWrapper, Title, TopBlock } from '../shared/ui/components/Dialog';
import { IconComponent } from '../shared/ui/components/Field/IconComponent';
import { CustomTextField } from '../shared/ui/components/Field/CustomTextField';
import { Hints } from '../shared/ui/components/Profile/Hints';
import { StepButton } from '../shared/ui/components/StepButton';
import {
  changeCustomerProfilePhoto,
  changeCustomerProfileName,
  getCustomerCurrent,
  deleteCustomerProfilePhoto,
  deleteCustomerProfile,
  getCustomerFiles,
  sendCustomerFileWithTypeId,
  getBinaryFile
} from '../services/workki/CustomerActions';
import { FileUploadPreview } from '../shared/ui/components/Field/FileUploadField';
import * as types from '../services/workki/TypesActions';
import getCustomerPublicFilePathByFileId from '../shared/utils/serviceUtils/getCustomerPublicFilePathByFileId';
import {
  AvatarContainer,
  ContentContainer,
  RemoveProfileBox,
  RemoveProfileSvg,
  RemoveProfileTitleBox,
  RemoveProfileWarningText,
  StyledSvg,
  UserDataChangeBox,
  UserDataChangeItem,
  UserDataChangeItemLabel,
  UserDataChangeList,
  UserDataChangeTitle,
  UserDataWidthWrapper
} from './StyledComponents/StyledProfileSettings';

import { LogoPlaceholder } from '../shared/ui/icons/core/LogoPlaceholder';
import { FILE_TYPE_ID } from '../constants';
import ErrorWithShowedMessage from '../errors/ErrorWithShowedMessage';
import { isArray } from 'lodash';
import { error } from 'react-toastify-redux';
import { PhoneMask } from '../shared/ui/icons/core/PhoneMask';
import { formatPhoneNumber } from '../shared/utils/serviceUtils/validationUtils';
import { InputBoxes } from '../shared/ui/components/InputBoxes';
import ResponseError from '../errors/ResponseError';
import RequestError from '../errors/RequestError';
import { Button, FormControl } from '@mui/material';
import { Description, Warning } from '@mui/icons-material';
import { Success } from '../shared/ui/icons/core/Success';
import styled from 'styled-components';
const RESEND_INTERVAL = 60;

export const SvgTooltipWrapper = styled.div`
  position: relative;
`;

export const ToolTip = styled.div`
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  padding: 6px 12px;
  color: #fff;
  width: 238px;
  height: 93px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(56, 60, 64, 0.8);
  backdrop-filter: blur(4px);
  font-weight: 400;
  font-size: 14px;

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(90deg) translateX(-50%);
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(56, 60, 64, 0.8) transparent transparent transparent;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const ToolTipButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

const ProfileSettings = ({ defaultProfileState, setProfilePageState, isPending, isLoading, ...props }) => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector);
  const avatarPhoto = useSelector(customerPhotoSelector(FILE_TYPE_ID.PROFILE_AVATAR));
  const customerFiles = useSelector(customerFilesSelector());

  // /customer/file
  const makeLogout = useCallback(() => dispatch(logoutUser()), [dispatch]);
  const [isModalWindowOpened, setIsModalWindowOpened] = useState(false);
  const [isPhotoEditorOpened, setIsPhotoEditorOpened] = useState(false);
  const [cardFile, setCardFile] = useState([]);
  const [nameChangeFiles, setNameChangeFiles] = useState([]);
  const [avatarFile, setAvatarFile] = useState([]);
  const [currentEditingPhoto, setCurrentEditingPhoto] = useState(null);
  // const [photoEditorCallback, setPhotoEditorCallback] = useState(null);
  const photoEditorCallback = useRef(null);
  const DIALOG_CONTAINER_ID = 'profile-edit-container';
  const PHOTO_EDIT_CONTAINER_ID = 'photo-edit-container';

  let passPhoto = useSelector(customerPhotoSelector(FILE_TYPE_ID.PHOTO_FOR_PASS));
  if (!cardFile.length && passPhoto) {
    dispatch(getBinaryFile(passPhoto.id)).then(binaryFile => {
      setCardFile([binaryFile.data]);
    });
  }

  const getErrorHandler = (dispatch, defaultMessage) => err => {
    if (err instanceof ErrorWithShowedMessage) {
      return;
    }

    let message = defaultMessage;

    if (err && err.response && err.response.data && isArray(err.response.data)) {
      message = err.response.data[0].message;
    }

    dispatch(error(message, { hideProgressBar: true }));
  };

  const openAvatarEditor = () => dispatch(actions.openAvatarEditor());
  const closeAvatarEditor = () => dispatch(actions.closeAvatarEditor());

  const uploadCustomerPhoto = (fileTypeId, file) => {
    return dispatch(sendCustomerFileWithTypeId(fileTypeId, file)).catch(
      getErrorHandler(dispatch, 'Ошибка при загрузке фото.')
    );
  };

  const deleteCustomerPhoto = id => {
    dispatch(deleteCustomerProfilePhoto(id, dispatch)).catch(getErrorHandler(dispatch, 'Ошибка при удалении фото.'));
  };

  const getUserPhone = () => {
    let userPhone = customer.mobile_phone.split('');
    userPhone.unshift('+');
    userPhone.splice(2, 0, ' ');
    userPhone.splice(6, 0, ' ');
    userPhone.splice(10, 0, '-');
    userPhone.splice(13, 0, '-');
    return userPhone.join('');
  };

  const modalType = {
    NAME: 'name',
    NAME_SUCCESS: 'name_success',
    PHONE: 'phone',
    CONFIRM_PHONE_STEP_1: 'confirm-phone_step-1',
    CONFIRM_PHONE_STEP_2: 'confirm-phone_step-2',
    CONFIRM_PHONE_STEP_3: 'confirm-phone_step-3',
    CONFIRM_PHONE_PROBLEM: 'confirm-phone_problem',
    EMAIL: 'email',
    PHOTO_FOR_PASS: 'photoForPass',
    PASSWORD: 'password',
    MATCH_PASSWORD: 'matchPassword',
    AVATAR_EDITOR: 'avatarEditor',
    DELETE_PROFILE: 'deleteProfile',
    DELETE_PROFILE_SUCCESS: 'deleteProfileSuccess'
  };

  const loadedPhotoType = {
    AVATAR: 'avatar',
    CARD: 'card'
  };

  const defaultFormData = {
    [modalType.NAME]: '',
    [modalType.PHONE]: '',
    [modalType.EMAIL]: '',
    [modalType.PHOTO_FOR_PASS]: '',
    [modalType.PASSWORD]: '',
    [modalType.MATCH_PASSWORD]: ''
  };

  const [formData, setFormData] = useState({ ...defaultFormData });
  // console.log('formData', formData);
  const passCallback = photo => {
    console.log('setPhotoEditorCallback photo', photo);
    console.log('passCallback', photo);
    if (photo) {
      setCurrentPhotos([photo]);
      setCardFile([photo]);
    }
  };
  // console.log('customer', customer);
  let cards = [
    {
      title: 'Фото на пропуск',
      caption: '',
      handleFollowLink: () => {
        setModalContent(modalType.PHOTO_FOR_PASS);
        photoEditorCallback.current = passCallback;
        setIsModalWindowOpened(true);
      }
    },
    {
      title: 'Обновить пароль',
      // caption: 'Менялся 2 месяца назад',
      handleFollowLink: () => {
        if (customer.email) {
          setModalContent(modalType.PASSWORD);
          setIsModalWindowOpened(true);
        } else {
          alert('Ошибка при получении данных о вашем e-mail');
        }
      }
    }
  ];

  const defaultModal = {
    nameChange: false,
    phoneChange: false,
    emailChange: false,
    photoForPassChange: false,
    passwordChange: false,
    deleteProfileChange: false
  };

  const [modalState, setModalState] = useState({ ...defaultModal });

  const handleChangeState = fieldName => {
    console.log({
      ...defaultModal,
      [fieldName]: true
    });
    setModalState({
      ...defaultModal,
      [fieldName]: true
    });
  };

  const getModal = () => {
    const modal = Object.keys(modalState).find(item => modalState[item]);
    return modal;
  };

  const handleCloseModal = () => setModalState({ ...defaultModal });

  const [errors, setErrors] = useState({
    email: '',
    commonError: '',
    password: '',
    matchPassword: '',
    firstName: '',
    phone: ''
  });

  const [modalContent, setModalContent] = useState(null);

  const [isChangeData, setIsChangeData] = useState({
    open: false,
    type: '',
    requestSent: false
  });

  const [loadingStatus, setLoadingStatus] = useState('unset');

  const [validationError, setValidationError] = useState({
    name: false,
    phone: false,
    email: false,
    photoForPass: false,
    password: false,
    matchPassword: false
  });

  const [disableButton, setIsDisableButton] = useState(false);

  const [openDialogData, setOpenDialogData] = useState({ open: false, title: '', description: '' });

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    const inputValue = value.replace(/\s+/g, '');

    console.log('{ name, value } = evt.target;', name, inputValue);
    handleValidation(name, inputValue);
    updateFormData(name, inputValue);
  };

  const updateFormData = (name, inputValue) => {
    console.log('updateFormData', name, inputValue);
    setFormData(prevState => {
      if (name === 'phone') {
        const cleaned = inputValue.replace(/\D/g, '');
        const limitedInput = cleaned.substring(0, 10);
        return {
          ...prevState,
          [name]: formatPhoneNumber(limitedInput, setErrors, setValidationError)
        };
      } else {
        return {
          ...prevState,
          [name]: inputValue
        };
      }
    });
  };

  const handleEmailValidation = inputValue => {
    const isValidEmail = validateEmail(inputValue);

    if (!isValidEmail) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Неверный формат email'
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: ''
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: false
      }));
    }
  };

  const validateEmail = email => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const validatePassword = password => {
    // Регулярное выражение для проверки пароля
    // (?=.*[a-zA-Z]) - наличие хотя бы одной латинской буквы (обязательно)
    // (?=.*\d) - наличие хотя бы одной цифры (обязательно)
    // [a-zA-Z\d!@#$%^&*]+ - только латинские буквы, цифры и указанные спецсимволы
    // {8,20} - длина пароля от 8 до 20 символов
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,20}$/;

    const isValid = passwordRegex.test(password);
    return isValid;
  };

  const handlePasswordValidation = inputValue => {
    if (!validatePassword(inputValue)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Неверный формат пароля'
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        password: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: ''
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        password: false
      }));
    }
  };

  const handleMatchPasswordValidation = inputValue => {
    if (inputValue !== formData.password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        matchPassword: 'Пароли не совпадают'
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        matchPassword: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        matchPassword: ''
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        matchPassword: false
      }));
    }
  };

  const handleValidation = (name, inputValue) => {
    switch (name) {
      case 'email':
        const testEmail = validateEmail(inputValue);
        console.log(!testEmail, 'статус кнопки');
        setIsDisableButton(!testEmail);
        break;
      case 'password':
        handlePasswordValidation(inputValue);
        break;
      case 'matchPassword':
        handleMatchPasswordValidation(inputValue);
        break;
    }
  };

  const handleSendCode = codeValues => {
    const code = codeValues.join('');
    const customerId = customer.id;
    setLoadingStatus('loading');
    dispatch(pofilePhoneConfirm({ code, customerId }))
      .then(code => {
        if (code.data) {
          setErrors(prevErrors => ({
            ...errors,
            code: ''
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Неправильный код'
          }));
        }
        setLoadingStatus('unset');
        setOpenDialogData({
          open: true,
          title: 'Номер телефона подтверждён',
          description: 'Вы успешно подтвердили номер телефона в личном кабинете Worrki'
        });
      })
      .catch(error => {
        if (error instanceof ResponseError && error.response && isArray(error.response.data)) {
          setErrors(prevErrors => ({
            ...errors,
            code: error.response.data
          }));
        } else if (error instanceof RequestError) {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сети.'
          }));
        } else {
          setErrors(prevErrors => ({
            ...errors,
            code: 'Ошибка сервера.'
          }));
        }
        setLoadingStatus('unset');
      });
  };

  const [isFocused, setIsFocused] = useState({
    name: false,
    phone: false,
    email: false,
    photoForPass: false,
    password: false,
    matchPassword: false
  });

  const handleFocus = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: fieldName === 'email' ? { ...prevFocused[fieldName], [type]: true } : true
    }));
  };

  const handleBlur = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: fieldName === 'email' ? { ...prevFocused[fieldName], [type]: false } : false
    }));
  };

  const handleEraseValue = (fieldName, type) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  const { name, phone, email, photoForPass, password, matchPassword } = formData;

  const [changeStep, setChangeStep] = useState({
    type: 'personalData',
    // type: 'changePassword',
    data: {}
  });

  const [timer, setTimer] = useState(RESEND_INTERVAL);

  const [filled, setFilled] = useState({
    email: false,
    password: false,
    matchPassword: false
  });

  const isServerError = statusCode => {
    return statusCode ? statusCode.toString().startsWith('5') : false;
  };

  const handleTriggerCall = evt => {
    evt.preventDefault();
    const phoneWithCountryCode = '7' + phone;
    setLoadingStatus('loading');
    dispatch(sendCode({ phoneWithCountryCode }))
      .then(response => {
        console.log(response.data.data, 'данные по коду клиента');
        setLoadingStatus('unset');
        setChangeStep(prevStep => ({
          ...prevStep,
          type: 'phoneAuth',
          destination: 'flash-call',
          data: response.data.data
        }));
        if (isChangeData.open) {
          setIsChangeData(prevState => ({
            ...prevState,
            open: false,
            type: ''
          }));
        }
        setTimer(RESEND_INTERVAL);
        return response;
      })
      .catch(error => {
        const requestErrors = error.response ? error.response.data : null;
        const status = error.status;
        if (requestErrors && !isServerError(status) && Array.isArray(requestErrors)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: requestErrors.find(error => error.field === 'mobile_phone')
              ? requestErrors.find(error => error.field === 'mobile_phone').message
              : '',
            commonError: requestErrors.find(error => error.field === 'reCaptcha')
              ? requestErrors.find(error => error.field === 'reCaptcha').message
              : ''
          }));
        } else if (isServerError(status)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Ошибка сервера'
          }));
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Неизвестная ошибка'
          }));
        }
        setLoadingStatus('unset');
      });
  };
  const [infoMessage, setInfoMessage] = useState({
    message: 'Получить код в СМС',
    isDisabled: true
  });

  const handleSendMessage = () => {
    const customerId = customer.id;
    const phoneWithCountryCode = '+7' + formData.phone;
    setLoadingStatus('loading');
    dispatch(pofilePhoneSendCode({ customerId, phoneWithCountryCode }))
      .then(response => {
        setLoadingStatus('unset');
      })
      .catch(() => {
        setLoadingStatus('unset');
      });
  };

  useEffect(() => {
    setInfoMessage(prevState => {
      if (timer > 0) {
        return {
          message: `Получить код в СМС через ${timer} сек`,
          isDisabled: true
        };
      } else {
        return {
          message: 'Получить код в СМС еще раз',
          isDisabled: false
        };
      }
    });
  }, [timer]);

  const isTimerActive = useMemo(() => timer > 0, [timer]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isTimerActive) {
      intervalRef.current = setInterval(() => {
        setTimer(t => t - 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => clearInterval(intervalRef.current);
  }, [isTimerActive]);

  const handleRegisterSubmit = () => {
    console.log('Отправка формы');
    console.log(formData);
  };

  // useEffect(() => {
  //   if (formData.phone.length !== 10) {
  //     setIsDisableButton(true);
  //   } else {
  //     setIsDisableButton(false);
  //   }
  // }, [formData]);
  const history = useHistory();

  const getLoadedFile = () => {
    if (fileInputRef.current) {
      return fileInputRef.current.files[0];
    }
    return null;
  };

  const triggerClickToUploadFile = () => {
    fileInputRef.current.click();
  };

  const restoreChangeName = () => {
    setIsModalWindowOpened(false);
    setModalContent(null);
    setNameChangeFiles([]);
    setFormData(prevState => {
      return {
        ...prevState,
        [modalType.NAME]: ''
      };
    });
  };

  const fileInputRef = useRef(null);

  const [currentPhotos, setCurrentPhotos] = useState(cardFile);

  const disableSendName = !formData[modalType.NAME].length || !nameChangeFiles.length;

  const renderDialogContent = type => {
    switch (type) {
      case modalType.NAME: {
        return (
          <DialogWrapper
            onClick={() => {
              restoreChangeName();
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'}>
                  <Title>Изменить имя</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      restoreChangeName();
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                  />
                </TopBlock>
                <CustomTextField
                  // error={errors.inn.legalEntity}
                  type='text'
                  name={modalType.NAME}
                  id='outlined-helperText'
                  label='Новое имя'
                  variant='filled'
                  value={formData[modalType.NAME]}
                  // onFocus={() => handleFocus('name', 'legalEntity')}
                  // onBlur={() => handleBlur('name', 'legalEntity')}
                  onChange={handleInputChange}
                  // helperText={errors.inn.legalEntity ? errors.inn.legalEntity : ''}
                  // endAdornmentProps={{
                  //   filled: filled.inn.legalEntity,
                  //   isFocused: isFocused.inn.legalEntity,
                  //   handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                  // }}
                  autoFocus={true}
                />
                <FileUploadPreview
                  key={'change-name-photos'}
                  fieldType='changeName'
                  files={nameChangeFiles}
                  disablePhotoEditing
                  setFiles={files => {
                    console.log('files', files);
                    setNameChangeFiles(files);
                  }}
                />
                {/*<Hints*/}
                {/*  open={open}*/}
                {/*  formData={formData}*/}
                {/*  anchorEl={anchorEl}*/}
                {/*  hintsData={hintsData}*/}
                {/*  setInnValue={inn => setInnValue(inn)}*/}
                {/*  inputWidth={inputWidth}*/}
                {/*  type={'inn'}*/}
                {/*/>*/}
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Отправить'
                  handleAction={() => {
                    dispatch(changeCustomerProfileName(customer.id, name, nameChangeFiles)).then(() => {
                      setModalContent(modalType.NAME_SUCCESS);
                      dispatch(getCustomerCurrent()).then(() => {
                        dispatch(types.getAccount());
                      });
                    });
                  }}
                  disabled={disableSendName}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.NAME_SUCCESS: {
        return (
          <DialogWrapper
            onClick={() => {
              restoreChangeName();
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <div style={{ alignSelf: 'center', margin: '12px 0 0' }}>
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M35.9475 17.4142C36.7286 16.6332 36.7286 15.3668 35.9475 14.5858C35.1665 13.8047 33.9002 13.8047 33.1191 14.5858L19.0667 29.1716L14.4809 24.5858C13.6998 23.8047 12.4335 23.8047 11.6525 24.5858C10.8714 25.3668 10.8714 26.6332 11.6525 27.4142L17.6525 33.4142C18.4335 34.1953 19.6998 34.1953 20.4809 33.4142L35.9475 17.4142Z'
                      fill='#02A93B'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24Z'
                      fill='#02A93B'
                    />
                  </svg>
                </div>
                <div style={{ alignSelf: 'center', margin: '-20px 0' }}>
                  <Title>Заявка отправлена</Title>
                </div>
                <p style={{ alignSelf: 'center', textAlign: 'center', padding: '0 20px', margin: '8px' }}>
                  Заявка на изменение данных отправлена.
                  <br />
                  Сведения об изменении статуса заявки будут приходить на вашу электронную почту.
                </p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Спасибо'
                  handleAction={() => {
                    restoreChangeName();
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.EMAIL: {
        return <></>;
      }
      case modalType.DELETE_PROFILE: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer alightItems={'center'}>
                <TopBlock direction={'space-between'}>
                  <Title>Удалить профиль?</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>Ваши персональные данные будут удалены из системы, а личный кабинет Workki будет отключен.</p>
                <StepButton
                  type='outlined'
                  title='Да, удалить'
                  handleAction={() => {
                    dispatch(deleteCustomerProfile(customer.id)).then(() => {
                      dispatch(getCustomerCurrent()).then(() => {
                        setModalContent(modalType.DELETE_PROFILE_SUCCESS);
                        dispatch(types.getAccount());
                      });
                    });
                  }}
                />
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Нет, не удалять'
                  handleAction={() => {
                    setIsModalWindowOpened(false);
                    setModalContent(null);
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.DELETE_PROFILE_SUCCESS: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <div style={{ alignSelf: 'center', margin: '12px 0 0' }}>
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M35.9475 17.4142C36.7286 16.6332 36.7286 15.3668 35.9475 14.5858C35.1665 13.8047 33.9002 13.8047 33.1191 14.5858L19.0667 29.1716L14.4809 24.5858C13.6998 23.8047 12.4335 23.8047 11.6525 24.5858C10.8714 25.3668 10.8714 26.6332 11.6525 27.4142L17.6525 33.4142C18.4335 34.1953 19.6998 34.1953 20.4809 33.4142L35.9475 17.4142Z'
                      fill='#02A93B'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24Z'
                      fill='#02A93B'
                    />
                  </svg>
                </div>
                <div style={{ alignSelf: 'center', margin: '-20px 0' }}>
                  <Title>Запрос отправлен</Title>
                </div>
                <p style={{ alignSelf: 'center', textAlign: 'center', padding: '0 20px', margin: '8px' }}>
                  Запрос на удаление персональных данных отправлен. На вашу электронную почту придет письмо со статусом
                  заявки.
                </p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Спасибо'
                  handleAction={() => {
                    setIsModalWindowOpened(false);
                    setModalContent(null);
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.PHONE: {
        return <></>;
      }
      case modalType.PASSWORD: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'}>
                  <Title>Сброс пароля</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>
                  Письмо о сбросе пароля придёт на почту <strong>{customer.email}</strong>
                </p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Отпарвить пиьмо на e-mail'
                  handleAction={() => {
                    // dispatch(changeCustomerProfileName(customer.id, name, ['qwe']));
                  }}
                  // disabled={isSubmitDisabled}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.CONFIRM_PHONE_STEP_1: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'}>
                  <Title>Подтвердите номер телефона</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>После подтверждения номера телефона станет доступна авторизация с его помощью</p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Подтвердить номер телефона'
                  handleAction={() => {
                    setModalContent(modalType.CONFIRM_PHONE_STEP_2);
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.CONFIRM_PHONE_STEP_2: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'}>
                  <Title>Подтвердите номер телефона</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>После подтверждения номера телефона станет доступна авторизация с его помощью</p>
                <CustomTextField
                  error={errors.phone}
                  id='outlined-helperText'
                  label='Телефон'
                  name='phone'
                  helperText={errors.phone ? errors.phone : ''}
                  variant='filled'
                  type='tel'
                  value={formData.phone}
                  onChange={handleInputChange}
                  disabled={isPending}
                  placeholder={'999 999-99-99'}
                  onFocus={() => handleFocus('phone')}
                  onBlur={() => handleBlur('phone')}
                  endAdornmentProps={{
                    filled: filled.phone,
                    isFocused: isFocused.phone,
                    handleEraseValue: handleEraseValue
                  }}
                  startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
                  autoFocus={true}
                />
                <StepButton
                  filledType='black'
                  type='filled'
                  disabled={errors.phone || formData.phone === ''}
                  title='Подтвердить номер телефона'
                  handleAction={() => {
                    setModalContent(modalType.CONFIRM_PHONE_STEP_3);
                    handleSendMessage();
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.CONFIRM_PHONE_STEP_3: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <div className='authorization__phone-main'>
                  <Title>Введите код из смс</Title>
                  <div style={{ position: 'absolute', top: 10, right: 0 }}>
                    <IconComponent
                      type='close'
                      handleAction={() => {
                        setIsModalWindowOpened(false);
                        setModalContent(null);
                      }}
                      defaultColor={'#24272A'}
                      hoverColor={'#383c40'}
                      spacing='0'
                    />
                  </div>
                  <div>Отправим на +7 {formData.phone}</div>
                  <div className='authorization__phone-main__phone-fields'>
                    <InputBoxes
                      length={4}
                      error={errors.code}
                      handleRequest={value => {
                        handleSendCode(value);
                      }}
                    />
                  </div>
                  <div
                    style={{ marginTop: 16 }}
                    onClick={() => {
                      if (!infoMessage.isDisabled) {
                        handleSendMessage();
                        setTimer(RESEND_INTERVAL);
                      }
                    }}
                  >
                    <span
                      className={`authorization__footer__links-wrapper__${
                        infoMessage.isDisabled ? 'timer-disabled' : 'timer-active'
                      }`}
                    >
                      {infoMessage.message}
                    </span>
                  </div>
                  <div className='authorization__bottom'>
                    <div className='authorization__footer__links-wrapper'>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setModalContent(modalType.CONFIRM_PHONE_PROBLEM);
                        }}
                      >
                        Не приходит код?
                      </div>
                    </div>
                  </div>
                </div>
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.CONFIRM_PHONE_PROBLEM: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <div>
                  <div className='authorization__caption' style={{ paddingLeft: 0, textAlign: 'left' }}>
                    <p>
                      Не удалось подтвердить
                      <br />
                      номер телефона?
                    </p>
                    <p>
                      Укажите контактный номер телефона, мы
                      <br />
                      свяжемся с вами для ее решения проблемы.
                    </p>
                  </div>
                  <form
                    onSubmit={() => console.log('Отправляем запрос на новый эндпоинт - которого еще нет')}
                    autoComplete='false'
                    id='authorization-login-form'
                    noValidate
                  >
                    <CustomTextField
                      error={errors.phone}
                      id='outlined-helperText'
                      label='Телефон'
                      name='phone'
                      helperText={errors.phone ? errors.phone : ''}
                      variant='filled'
                      type='tel'
                      value={formData.phone}
                      onChange={handleInputChange}
                      disabled={isPending}
                      placeholder={'999 999-99-99'}
                      onFocus={() => handleFocus('phone')}
                      onBlur={() => handleBlur('phone')}
                      endAdornmentProps={{
                        filled: filled.phone,
                        isFocused: isFocused.phone,
                        handleEraseValue: handleEraseValue
                      }}
                      startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
                      autoFocus={false}
                    />
                    <div className='authorization__footer'>
                      <FormControl fullWidth={true}>
                        <StepButton
                          filledType='black'
                          type='filled'
                          title='Отправить'
                          disabled={errors.phone || formData.phone === ''}
                          handleAction={() => {
                            setOpenDialogData({
                              open: true,
                              title: 'Заявка отправлена',
                              description: 'В ближайшее время с вами свяжется менеджер для решения проблемы'
                            });
                          }}
                        />
                        {errors.commonError !== '' && (
                          <div className='authorization__common-error'>
                            <span>
                              <Warning spacing='8px' defaultColor='#DB4344' />
                            </span>
                            <span>{errors.commonError}</span>
                          </div>
                        )}
                      </FormControl>
                    </div>
                  </form>
                </div>
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.PHOTO_FOR_PASS: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'} sticky>
                  <Title>Фото на пропуск</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p style={{ margin: 0 }}>
                  Требуется на некоторых локациях.
                  <br />
                  При необходимости менеджер Workki свяжется с вами и попросит его загрузить.
                </p>
                <FileUploadPreview
                  fieldType='employee'
                  files={cardFile}
                  setFiles={files => setCardFile(files)}
                  deletePhotoHandler={() => {
                    if (passPhoto && passPhoto.id) {
                      console.warn('passPhoto.id ' + passPhoto.id);
                      console.warn('dispatch(deleteCustomerProfilePhoto(passPhoto.id)).then(() => {');
                      let id = passPhoto.id;
                      passPhoto = null;
                      dispatch(deleteCustomerProfilePhoto(id, dispatch)).then(() => {
                        setCardFile([]);
                        dispatch(getCustomerCurrent()).then(() => {
                          dispatch(types.getAccount());
                        });
                      });
                    }
                    setAvatarFile(null);
                    setCurrentEditingPhoto(null);
                  }}
                  // setFiles={files => {}}
                  changeFileHandler={photo => {
                    setIsPhotoEditorOpened(true);
                    setCurrentPhotos(photo);
                    if (photo instanceof Blob) {
                      photo = new File([photo], 'example.txt', { type: photo.type });
                    }
                    setCurrentEditingPhoto(photo);
                    photoEditorCallback.current = passCallback;
                    // setPhotoEditorCallback(file => {
                    //   setCardFile(file);
                    // });
                    // setPhotoEditorCallback(() => {
                    //
                    // })
                    // setModalContent(modalType.AVATAR_EDITOR);
                    // setIsModalWindowOpened(true);
                  }}
                  isSingleFile
                />
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Сохранить'
                  handleAction={() => {
                    uploadCustomerPhoto(FILE_TYPE_ID.PHOTO_FOR_PASS, cardFile[0]).then(() => {
                      dispatch(getCustomerCurrent()).then(() => {
                        dispatch(types.getAccount());
                        setAvatarFile(null);
                        setIsModalWindowOpened(false);
                        setModalContent(null);
                      });
                    });
                    // dispatch(changeCustomerProfilePhoto(customer.id, cardFile, FILE_TYPE_ID.PHOTO_FOR_PASS)).then(() => {
                    //   dispatch(getCustomerCurrent()).then(() => {
                    //     dispatch(types.getAccount());
                    //     setAvatarFile(null);
                    //   });
                    // });
                  }}
                  disabled={!cardFile.length}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.MATCH_PASSWORD: {
        return <></>;
      }
      case modalType.AVATAR_EDITOR: {
        return (
          <ProfileAvatarModal
            image={getLoadedFile()}
            modalWindowTitle='Добавление изображения'
            sendCustomerFile={file => {
              console.log('file', file);
              uploadCustomerPhoto(FILE_TYPE_ID.PROFILE_AVATAR, file);
              dispatch(getCustomerCurrent()).then(() => {
                dispatch(types.getAccount());
                setAvatarFile(null);
              });
              // dispatch(changeCustomerProfilePhoto(customer.id, file, loadedPhotoType.AVATAR)).then(() => {
              //   dispatch(getCustomerCurrent()).then(() => {
              //     dispatch(types.getAccount());
              //     setAvatarFile(null);
              //   });
              // });
              // /customer/profile/photo
            }}
            isModalWindowOpen={isModalWindowOpened}
            closeModalWindow={() => {
              setIsModalWindowOpened(false);
            }}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const avatarCallback = blob => {
    // console.log('blob', blob, 'photoEditorCallback', photoEditorCallback);
    // console.log('setPhotoEditorCallback photo', blob);
    // if (blob) {
    //   setCurrentPhotos([blob]);
    //   setCardFile([blob]);
    // }
    console.log('avatarCallback', blob);
    console.log('photoEditorCallback', photoEditorCallback.current);
    !!photoEditorCallback.current && photoEditorCallback.current(blob);
  };

  useEffect(() => {
    createContainer({ id: DIALOG_CONTAINER_ID });
    createContainer({ id: PHOTO_EDIT_CONTAINER_ID });
  }, []);

  useEffect(() => {
    console.log('cardFiles', cardFile);
  }, [cardFile]);

  return (
    <PrimaryLayout>
      <main className='main'>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            <UserDataWidthWrapper>
              <StepBack
                onClick={() => history.push('/profile')}
                title={'Настройки профиля'}
                style={{ marginBottom: '8px' }}
              />
              <AvatarContainer>
                {avatarPhoto ? (
                  <ProfileAvatar
                    src={getCustomerPublicFilePathByFileId(avatarPhoto.id ? avatarPhoto.id : '')}
                    sendCustomerFile={() => alert('Загрузка...')}
                    deleteCallback={() => {
                      deleteCustomerPhoto(avatarPhoto.id);
                      dispatch(getCustomerCurrent()).then(() => {
                        dispatch(types.getAccount());
                        setAvatarFile(null);
                      });
                    }}
                    openEditorCallback={triggerClickToUploadFile}
                  />
                ) : (
                  <LogoPlaceholder onClick={triggerClickToUploadFile} captionText={'Добавить фото'} />
                )}
              </AvatarContainer>
              <UserDataChangeBox>
                <UserDataChangeTitle>Персональные данные</UserDataChangeTitle>
                <UserDataChangeList>
                  <UserDataChangeItem>
                    <UserDataChangeItemLabel>
                      <span>Имя:</span>
                      {customer.name}
                    </UserDataChangeItemLabel>
                    <StyledSvg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={() => {
                        setModalContent(modalType.NAME);
                        setIsModalWindowOpened(true);
                      }}
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.4508 3.56675C13.7186 2.83452 12.5314 2.83452 11.7992 3.56675L4.55807 10.8079C4.55051 10.8154 4.54315 10.8232 4.53601 10.8311C4.46263 10.9123 4.41433 11.008 4.39112 11.1085L3.14367 16.0982C3.09043 16.3112 3.15283 16.5365 3.30807 16.6918C3.46331 16.847 3.68861 16.9094 3.9016 16.8561L8.8899 15.6091C9.00059 15.5838 9.10573 15.528 9.19194 15.4418L9.19521 15.4385L16.4331 8.20064C17.1653 7.4684 17.1653 6.28122 16.4331 5.54899L14.4508 3.56675ZM8.74999 14.116L15.5492 7.31675C15.7933 7.07268 15.7933 6.67695 15.5492 6.43287L13.567 4.45064C13.3229 4.20656 12.9271 4.20656 12.6831 4.45064L5.88387 11.2498L8.74999 14.116ZM5.33861 12.4723L4.60899 15.3908L7.52748 14.6612L5.33861 12.4723Z'
                      />
                    </StyledSvg>
                  </UserDataChangeItem>
                  <UserDataChangeItem>
                    <UserDataChangeItemLabel>
                      <span>Телефон:</span>
                      {getUserPhone('phone')}
                    </UserDataChangeItemLabel>
                    {/*пока заккоментировано тк нашлась ошибка в userFlow при котором можно сменить реквизиты без подтверждения старыми*/}
                    {/* <StyledSvg*/}
                    {/*  width='20'*/}
                    {/*  height='20'*/}
                    {/*  viewBox='0 0 20 20'*/}
                    {/*  fill='none'*/}
                    {/*  xmlns='http://www.w3.org/2000/svg'*/}
                    {/*  onClick={() => handleChangeState('phoneChange')}*/}
                    {/*>*/}
                    {/*  <path*/}
                    {/*    fill-rule='evenodd'*/}
                    {/*    clip-rule='evenodd'*/}
                    {/*    d='M14.4508 3.56675C13.7186 2.83452 12.5314 2.83452 11.7992 3.56675L4.55807 10.8079C4.55051 10.8154 4.54315 10.8232 4.53601 10.8311C4.46263 10.9123 4.41433 11.008 4.39112 11.1085L3.14367 16.0982C3.09043 16.3112 3.15283 16.5365 3.30807 16.6918C3.46331 16.847 3.68861 16.9094 3.9016 16.8561L8.8899 15.6091C9.00059 15.5838 9.10573 15.528 9.19194 15.4418L9.19521 15.4385L16.4331 8.20064C17.1653 7.4684 17.1653 6.28122 16.4331 5.54899L14.4508 3.56675ZM8.74999 14.116L15.5492 7.31675C15.7933 7.07268 15.7933 6.67695 15.5492 6.43287L13.567 4.45064C13.3229 4.20656 12.9271 4.20656 12.6831 4.45064L5.88387 11.2498L8.74999 14.116ZM5.33861 12.4723L4.60899 15.3908L7.52748 14.6612L5.33861 12.4723Z'*/}
                    {/*  />*/}
                    {/*</StyledSvg> */}
                    <SvgTooltipWrapper>
                      <StyledSvg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fillColor={'#BA393A'}
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={() => {
                          setModalContent(modalType.CONFIRM_PHONE_STEP_1);
                          setIsModalWindowOpened(true);
                        }}
                      >
                        <path
                          d='M10 6.87491C10.3452 6.87491 10.625 7.15473 10.625 7.49991V11.2499C10.625 11.5951 10.3452 11.8749 10 11.8749C9.65482 11.8749 9.375 11.5951 9.375 11.2499V7.49991C9.375 7.15473 9.65482 6.87491 10 6.87491Z'
                          fill='#BA393A'
                        />
                        <path
                          d='M10 13.7499C10.3452 13.7499 10.625 13.4701 10.625 13.1249C10.625 12.7797 10.3452 12.4999 10 12.4999C9.65482 12.4999 9.375 12.7797 9.375 13.1249C9.375 13.4701 9.65482 13.7499 10 13.7499Z'
                          fill='#BA393A'
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M8.37652 3.74801C9.09835 2.49871 10.9017 2.49871 11.6235 3.74802L17.5827 14.0619C18.3049 15.3119 17.4028 16.8749 15.9592 16.8749H4.04088C2.59724 16.8749 1.69517 15.3119 2.41739 14.0619L8.37652 3.74801ZM10.5412 4.37336C10.3006 3.95693 9.69946 3.95693 9.45886 4.37336L3.49972 14.6873C3.25898 15.1039 3.55967 15.6249 4.04088 15.6249H15.9592C16.4404 15.6249 16.7411 15.1039 16.5003 14.6873L10.5412 4.37336Z'
                          fill='#BA393A'
                        />
                      </StyledSvg>
                      <ToolTip>
                        <div>Подтвердите номер телефона. После этого станет доступна авторизация с его помощью.</div>
                        <ToolTipButton
                          onClick={() => {
                            setModalContent(modalType.CONFIRM_PHONE_STEP_1);
                            setIsModalWindowOpened(true);
                          }}
                        >
                          Подтвердить номер телефона
                        </ToolTipButton>
                      </ToolTip>
                    </SvgTooltipWrapper>
                  </UserDataChangeItem>
                  <UserDataChangeItem>
                    <UserDataChangeItemLabel>
                      <span>E-mail:</span>
                      {customer.email}
                    </UserDataChangeItemLabel>
                    {/*пока заккоментировано тк нашлась ошибка в userFlow при котором можно сменить реквизиты без подтверждения старыми*/}
                    <div></div>
                    {/*<StyledSvgStyledSvg*/}
                    {/*  width='20'*/}
                    {/*  height='20'*/}
                    {/*  viewBox='0 0 20 20'*/}
                    {/*  fill='none'*/}
                    {/*  xmlns='http://www.w3.org/2000/svg'*/}
                    {/*  onClick={() => handleChangeState('emailChange')}*/}
                    {/*>*/}
                    {/*  <path*/}
                    {/*    fill-rule='evenodd'*/}
                    {/*    clip-rule='evenodd'*/}
                    {/*    d='M14.4508 3.56675C13.7186 2.83452 12.5314 2.83452 11.7992 3.56675L4.55807 10.8079C4.55051 10.8154 4.54315 10.8232 4.53601 10.8311C4.46263 10.9123 4.41433 11.008 4.39112 11.1085L3.14367 16.0982C3.09043 16.3112 3.15283 16.5365 3.30807 16.6918C3.46331 16.847 3.68861 16.9094 3.9016 16.8561L8.8899 15.6091C9.00059 15.5838 9.10573 15.528 9.19194 15.4418L9.19521 15.4385L16.4331 8.20064C17.1653 7.4684 17.1653 6.28122 16.4331 5.54899L14.4508 3.56675ZM8.74999 14.116L15.5492 7.31675C15.7933 7.07268 15.7933 6.67695 15.5492 6.43287L13.567 4.45064C13.3229 4.20656 12.9271 4.20656 12.6831 4.45064L5.88387 11.2498L8.74999 14.116ZM5.33861 12.4723L4.60899 15.3908L7.52748 14.6612L5.33861 12.4723Z'*/}
                    {/*  />*/}
                    {/*</StyledSvg>*/}
                  </UserDataChangeItem>
                </UserDataChangeList>
              </UserDataChangeBox>
              <ProfileCard
                cards={cards}
                logoutShown={true}
                handleLogout={makeLogout}
                handleFollowLink={() => {
                  setProfilePageState({ ...defaultProfileState, profileSettings: true });
                }}
              />
              <RemoveProfileBox>
                <RemoveProfileTitleBox
                  onClick={() => {
                    setIsModalWindowOpened(true);
                    setModalContent(modalType.DELETE_PROFILE);
                  }}
                >
                  <h3>Удаление профиля</h3>
                  <RemoveProfileSvg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M5.59088 3.34088C5.92038 3.01137 6.45462 3.01137 6.78412 3.34088L12.4091 8.96588C12.7386 9.29538 12.7386 9.82962 12.4091 10.1591L6.78412 15.7841C6.45462 16.1136 5.92038 16.1136 5.59088 15.7841C5.26137 15.4546 5.26137 14.9204 5.59088 14.5909L10.6193 9.5625L5.59088 4.53412C5.26137 4.20462 5.26137 3.67038 5.59088 3.34088Z'
                    />
                  </RemoveProfileSvg>
                </RemoveProfileTitleBox>
                <RemoveProfileWarningText>После удаления профиля восстановление невозможно</RemoveProfileWarningText>
              </RemoveProfileBox>
            </UserDataWidthWrapper>
            {!!getModal() && (
              <ChangeUserDataDialog
                dialogType={getModal()}
                disabledButton={disableButton}
                handleClose={() => {
                  setFormData({ ...defaultFormData });
                  handleCloseModal();
                  setIsDisableButton(false);
                }}
                errors={errors}
                formData={formData}
                handleInputChange={handleInputChange}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleEraseValue={handleEraseValue}
                handleSubmit={isChangeData.type === 'phoneConfirmation' ? handleTriggerCall : handleRegisterSubmit}
                isPending={isPending}
                filled={filled}
                isFocused={isFocused}
                requestSent={isChangeData.requestSent}
              />
            )}
            <input
              ref={fileInputRef}
              onInput={() => {
                if (fileInputRef.current.files[0]) {
                  setIsModalWindowOpened(true);
                  setModalContent(modalType.AVATAR_EDITOR);
                }
              }}
              className='file-upload__input is-empty'
              type='file'
              accept='image/jpeg, image/png'
            />
          </>
        )}
      </main>
      {isPhotoEditorOpened && (
        <Portal id={PHOTO_EDIT_CONTAINER_ID}>
          <div style={{ zIndex: 21, position: 'relative' }}>
            <ProfileAvatarModal
              image={currentEditingPhoto}
              modalWindowTitle='Добавление изображения'
              sendCustomerFile={blob => {
                setAvatarFile(blob);
              }}
              isModalWindowOpen={isModalWindowOpened}
              closeModalWindow={() => {
                setIsPhotoEditorOpened(false);
              }}
              saveCallback={avatarCallback}
            />
          </div>
        </Portal>
      )}
      {openDialogData.open && (
        <Dialog
          dialogType={'confirmPhoneSuccess'}
          title={openDialogData.title}
          dialogData={openDialogData.description}
          handleClose={() => {
            setIsModalWindowOpened(false);
            setModalContent(null);
            setOpenDialogData({ open: false });
          }}
        />
      )}
      {isModalWindowOpened && <Portal id={DIALOG_CONTAINER_ID}>{renderDialogContent(modalContent)}</Portal>}
    </PrimaryLayout>
  );
};

const mapStateToProps = state => {
  const requestAuthStatus = requestStatusSelector(actions.requestLogin)(state);
  return {
    errorMessage: state.errors.auth,
    isPending: requestAuthStatus.isPending
    // isAuthenticated: state.isAuthenticated
  };
};

export default connect(mapStateToProps)(ProfileSettings);
