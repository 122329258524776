import React, { useState } from 'react';
import styled from 'styled-components';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { screenRessolutions } from '../../shared/ui/constants/screenResolutions.constants';
import Avatar from '@mui/material/Avatar';
import { useResize } from '../../shared/utils/hooks/useResize';
import { isEmpty, last } from 'lodash';
import getCustomerPublicFilePathByFileId from '../../shared/utils/serviceUtils/getCustomerPublicFilePathByFileId';
import { stringAvatar } from '../../shared/utils/helperFunctions/heplerFunctions';

const NullMessage = styled.p`
  margin: 16px 0 0 0;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #575b60;
`;

const ResidentsList = styled.ul`
  margin: 24px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  list-style-type: none;
  box-sizing: border-box;

  &:after {
    content: '';
    height: 30px;
  }
`;

const CardTitleBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  gap: 10px;
`;

const CardImgBox = styled.div`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;

  &:first-child {
    margin: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      width: 48px;
      height: 48px;
    }
  }
`;

const CardTitle = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  & > h4 {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    font-family: Commissioner, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }
  }

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #575b60;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const CardDescription = styled.p`
  margin: 0;
  padding: 0;
  disply: flex;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #24272a;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const CardCompaniesList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  list-style-type: none;
`;

const CardCompaniesItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 2px;
  align-items: center;
  width: 100%;

  & > svg {
    margin: 0;
    padding: 0;
    width: 20px;
    heigth: 20px;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      width: 24px;
      heigth: 24px;
    }
  }

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #575b60;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const ShowMoreText = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #575b60;
  cursor: pointer;
`;

const CommunityList = ({ residentsList, searchValue }) => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const [visibleCount, setVisibleCount] = useState(10);

  const checkWidth = () => {
    const windowIsLaptop = window.innerWidth >= screenRessolutions.laptop;
    if (isLaptop !== windowIsLaptop) setIsLaptop(windowIsLaptop);
  };

  const filteredResidents = residentsList.filter(resident => {
    if (!searchValue) return true;
    const lowerSearch = searchValue.toLowerCase();
    return (
      (resident.name && resident.name.toLowerCase().includes(lowerSearch)) ||
      (resident.description && resident.description.toLowerCase().includes(lowerSearch))
    );
  });

  useResize(checkWidth, 0);

  if (!Array.isArray(residentsList)) residentsList = [residentsList];

  if (filteredResidents.length === 0) {
    return <NullMessage>По запросу ничего не найдено</NullMessage>;
  }

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };

  const getPlaces = items => {
    if (!items || !Array.isArray(items)) {
      return null;
    }

    return items.map(item => {
      return (
        <CardCompaniesItem key={item.id}>
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.0002 5.625C8.6195 5.625 7.50021 6.74429 7.50021 8.125C7.50021 9.50571 8.6195 10.625 10.0002 10.625C11.3809 10.625 12.5002 9.50571 12.5002 8.125C12.5002 6.74429 11.3809 5.625 10.0002 5.625ZM8.75021 8.125C8.75021 7.43464 9.30986 6.875 10.0002 6.875C10.6906 6.875 11.2502 7.43464 11.2502 8.125C11.2502 8.81536 10.6906 9.375 10.0002 9.375C9.30986 9.375 8.75021 8.81536 8.75021 8.125Z'
              fill='#575B60'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.0002 2.5C6.89361 2.5 4.37521 5.0184 4.37521 8.125C4.37521 11.1313 6.3856 14.7837 9.60978 17.363C9.83804 17.5457 10.1624 17.5457 10.3906 17.363C13.6148 14.7837 15.6252 11.1313 15.6252 8.125C15.6252 5.0184 13.1068 2.5 10.0002 2.5ZM5.62521 8.125C5.62521 5.70875 7.58397 3.75 10.0002 3.75C12.4165 3.75 14.3752 5.70875 14.3752 8.125C14.3752 10.5312 12.7848 13.6764 10.0002 16.0636C7.21567 13.6764 5.62521 10.5312 5.62521 8.125Z'
              fill='#575B60'
            />
          </svg>
          <p>{item.name}</p>
        </CardCompaniesItem>
      );
    });
  };

  const ProfileAvatar = profile => {
    const publicFiles = profile.publicFiles ? profile.publicFiles : [];
    const avatarFile = last(publicFiles) ? last(publicFiles) : {};
    const src = getCustomerPublicFilePathByFileId(avatarFile.id ? avatarFile.id : '');
    const alt = profile.name ? profile.name : 'Социальный профиль';
    return (
      <Avatar
        sx={{
          color: '#8F9295',
          fontWeight: 500,
          backgroundColor: '#F4F4F4',
          width: isLaptop ? '48px' : '36px',
          height: isLaptop ? '48px' : '36px',
          boxShadow: '0px 2px 12px 0px #00000014'
        }}
        src={src}
        alt={alt}
        {...stringAvatar(alt)}
      />
    );
  };

  const getResidentsCards = () => {
    return filteredResidents.slice(0, visibleCount).map((item, index) => (
      <CardShell style={{ gap: '12px' }} key={index}>
        <CardTitleBox>
          <CardImgBox>{ProfileAvatar(item)}</CardImgBox>
          <CardTitle>
            <h4>{item.name}</h4>
            <a style={{ textDecoration: 'none', color: '#575B60', fontWeight: 400 }} target={'_blank'} href={item.url}>
              {item.url}
            </a>
          </CardTitle>
        </CardTitleBox>
        <CardDescription>{item.description}</CardDescription>
        {!!item.places && <CardCompaniesList>{getPlaces(item.places)}</CardCompaniesList>}
      </CardShell>
    ));
  };

  return (
    <ResidentsList>
      {getResidentsCards()}
      {visibleCount < filteredResidents.length && <ShowMoreText onClick={handleShowMore}>Показать ещё</ShowMoreText>}
    </ResidentsList>
  );
};

export default CommunityList;
