import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CommunityList from './CommunityList';
import { getPublicProfileList } from '../../services/workki/PublicProfileActions';
import { officeSelector, publicProfilesSelector } from '../../store/selectors/DefaultSelectors';
import ContentLoader from '../ContentLoader';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/screenResolutions.constants';
import StepBack from '../../shared/ui/components/StepBack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { CustomSelectField } from '../../shared/ui/components/Select';
import { CustomTextField } from '../../shared/ui/components/Field/CustomTextField';
import { InputAdornment } from '@mui/material';
import { IconComponent } from '../../shared/ui/components/Field/IconComponent';

const CommunityBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 554px;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    max-width: 667px;
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    max-width: 944px;
  }
`;

const TitleBox = styled.div`
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: ${screenRessolutions.tablet}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const AccordionBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: 48px;
  @media screen and (min-width: ${screenRessolutions.tablet}px) {
    max-width: 288px;
  }
`;

const mapStateToProps = state => {
  const locations = officeSelector(state)
    .filter(location => location.active)
    .filter(function(location) {
      let disabledFloors = location.officeFloors.filter(floor => !floor.active);
      return disabledFloors.length !== location.officeFloors.length;
    });
  return {
    locations: locations,
    communityPublicProfiles: publicProfilesSelector(state),
    requestStatus: requestStatusSelector(actions.requestPublicProfileList)(state)
  };
};

const mapDispatchToProps = dispatch => ({
  loadPublicProfiles: locationId => dispatch(getPublicProfileList(locationId))
});

const Loader = () => (
  <div className='community__loader'>
    <ContentLoader />
  </div>
);

export const ALL_LOCATIONS_ID = 'all';

const ALL_LOCATIONS_OPTION = {
  value: ALL_LOCATIONS_ID,
  label: 'Все локации'
};

const Community = ({ communityPublicProfiles, requestStatus, loadPublicProfiles, locations }) => {
  const history = useHistory();

  const locationOptions = locations
    ? locations.map(item => {
        return {
          value: item.id,
          label: item.t_name
        };
      })
    : [ALL_LOCATIONS_OPTION];
  if (!!locations && locations.length) {
    locationOptions.unshift(ALL_LOCATIONS_OPTION);
  }

  const [selectedValue, setSelectedValue] = useState(ALL_LOCATIONS_OPTION.value);

  const handleSelectChange = event => {
    loadPublicProfiles(event.target.value);
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    loadPublicProfiles(selectedValue.value);
  }, []);

  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [filled, setFilled] = useState(false);

  const handleInputChange = value => {
    setFilled(value !== '');
    setInputValue(value);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleEraseValue = () => {
    setInputValue('');
  };

  return (
    <CommunityBox>
      <TitleBox>
        <StepBack
          title={'Резиденты'}
          onClick={() => history.goBack()}
          style={{ width: 'auto', gap: '12px' }}
          arrowsSpacing={'0'}
        />
        <AccordionBox>
          <CustomSelectField
            id='custom-select'
            label='Локация'
            variant='filled'
            value={selectedValue}
            onChange={handleSelectChange}
            options={locationOptions}
          />
        </AccordionBox>
      </TitleBox>
      <CustomTextField
        placeholder={'Поиск по резидентам'}
        error={''}
        type='text'
        name='search'
        variant='outlined'
        value={inputValue}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        onChange={e => handleInputChange(e.target.value)}
        startAdornment={
          <InputAdornment position='start'>
            <IconComponent type={'search'} />
          </InputAdornment>
        }
        endAdornmentProps={{
          filled: filled,
          isFocused: isFocused,
          handleEraseValue: () => handleEraseValue()
        }}
      />
      {requestStatus.isPending ? (
        <Loader />
      ) : (
        <CommunityList searchValue={inputValue} residentsList={communityPublicProfiles} />
      )}
    </CommunityBox>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Community);
