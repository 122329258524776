import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TicketAccordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='ticket-detail-accordion'>
      <div className={classNames('ticket-detail-accordion-title-wrapper', { 'is-active': isOpen })}>
        <div className='ticket-detail-accordion-title' onClick={toggleAccordion}>
          {title}
        </div>
        <svg width='12' height='7' viewBox='0 0 12 7' fill='none'>
          <path
            d='M1.16108 0.786022C1.41736 0.529741 1.83288 0.529741 2.08916 0.786022L6.00012 4.69698L9.91108 0.786022C10.1674 0.529741 10.5829 0.529741 10.8392 0.786022C11.0954 1.0423 11.0954 1.45782 10.8392 1.7141L6.46416 6.0891C6.20788 6.34538 5.79237 6.34538 5.53608 6.0891L1.16108 1.7141C0.904802 1.45782 0.904802 1.0423 1.16108 0.786022Z'
            fill='#575B60'
          />
        </svg>
      </div>
      <div ref={contentRef} className='ticket-detail-accordion-content' style={{ maxHeight: height }}>
        {children}
      </div>
    </div>
  );
};

TicketAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default TicketAccordion;
