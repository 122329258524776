import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { resetTickets } from '../../store/actions';
import TicketsHistory from './TicketsHistory';
import TicketDetails from './TicketDetails';
import { getActiveServiceRequests } from '../../services/workki/ServicesActions';
import { getTickets } from '../../services/workki/TicketsActions';
import { ticketsSelector } from '../../store/selectors/TicketsSelectors';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import { customerSelector, officeSelector } from '../../store/selectors/DefaultSelectors';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

moment.tz.setDefault('Europe/Moscow');

const MODE = {
  ITEM: 'item',
  LIST: 'list'
};

const Ticket = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ticket = location.state ? location.state.ticket : undefined;
  const customer = useSelector(customerSelector);
  const offices = useSelector(officeSelector);
  // LIST
  const tickets = useSelector(ticketsSelector);
  const loadStatus = useSelector(requestStatusSelector(actions.requestTickets));

  const currentPage = useSelector(state => state.pagination.tickets.currentPage);
  const pageCount = useSelector(state => state.pagination.tickets.pageCount);

  const canLoadMore = useMemo(() => !isNil(currentPage) && !isNil(currentPage) && currentPage < pageCount, [
    currentPage,
    pageCount
  ]);

  useEffect(() => {
    props.customer && dispatch(getActiveServiceRequests());
  }, [dispatch, props.customer]);

  const loadFirstPage = useCallback(() => {
    dispatch(resetTickets());
    props.customer && dispatch(getTickets({ page: 1 }));
  }, [dispatch, props.customer]);

  const loadNextPage = useCallback(() => canLoadMore && dispatch(getTickets({ page: currentPage + 1 })), [
    canLoadMore,
    currentPage,
    dispatch
  ]);

  useEffect(() => loadFirstPage(), [loadFirstPage]);

  // VIEW (MODE)
  const [{ mode, currentItemId }, setState] = useState({
    mode: MODE.LIST,
    currentItemId: null
  });

  const showTicketsItem = useCallback(id => () => setState({ mode: MODE.ITEM, currentItemId: id }), []);
  const showTicketsList = useCallback(() => setState({ mode: MODE.LIST, currentItemId }), [currentItemId]);

  const currentItem = useMemo(() => tickets.find(t => t.id === currentItemId), [currentItemId, tickets]);
  useEffect(() => {
    if (mode === MODE.ITEM && !currentItem) {
      showTicketsList();
    }
  }, [currentItem, mode, showTicketsList]);

  useEffect(() => {
    if (ticket) {
      setState({ mode: MODE.ITEM, currentItemId: ticket.id });
    }
  }, [ticket]);

  return (
    <>
      {mode === MODE.LIST ? (
        <TicketsHistory
          tickets={tickets}
          showTicketsItem={showTicketsItem}
          isLoading={loadStatus.isPending}
          onError={loadStatus.isFailure}
          canLoadMore={canLoadMore}
          loadFirstPage={loadFirstPage}
          loadNextPage={loadNextPage}
          currentItemId={currentItemId}
        />
      ) : (
        currentItem && (
          <TicketDetails ticket={currentItem} showTicketsList={showTicketsList} customer={customer} offices={offices} />
        )
      )}
    </>
  );
};

export default Ticket;
