import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../shared/ui/components/StepButton';
import { CustomTextField } from '../../shared/ui/components/Field/CustomTextField';
import { FileUploadPreview } from '../../shared/ui/components/Field/FileUploadField';
import { useDispatch } from 'react-redux';
import { IconComponent } from '../../shared/ui/components/Field/IconComponent';
import { FileContainer } from '../Services/Print/PrintFileWidget';
import { colors } from '../../shared/ui/constants/styleConstants';
import { styled } from 'styled-components';
import { getRefundTemplateRequest, refundRequest } from '../../services/workki/ChildActions';
import { InfoAlert } from '../../shared/ui/components/Profile/InfoAlert';
import { CustomSelectField } from '../../shared/ui/components/Select';
import saveAs from 'file-saver';

export const Caption = styled.div`
  margin: 0 0 16px 0;
  font-weight: 400;
  color: ${colors.workkiDarkFill};
`;

const CustomInfoAlert = styled(InfoAlert)`
  margin-bottom: 16px;
`;

const balanceTypes = [
  { label: 'Общий баланс', value: 'общий' },
  { label: 'Обеспечительный баланс', value: 'обеспечительный' }
];

const ReturnMoneyWidget = ({ handleClose, handleSuccess }) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleInputChange = inputValue => {
    setError('');
    setMessage(inputValue);
  };

  const handleRemoveFile = () => {
    setFiles([]);
  };

  useEffect(() => {
    setIsSubmitDisabled(!message.trim() || files.length === 0);
  }, [message, files]);

  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(balanceTypes[0].value);
  const [isFocused, setIsFocused] = useState(false);

  const handleSelectChange = event => {
    setSelectedValue(event.target.value);
  };

  const getDocument = link => {
    dispatch(getRefundTemplateRequest())
      .then(response => {
        console.log(response);
        if (response.data instanceof Blob) {
          const fileName = 'Шаблон';
          saveAs(response.data, fileName);
        } else {
          console.error('Ответ не является Blob');
        }
      })
      .catch(error => {
        console.error('Ошибка при скачивании файла:', error);
      });
  };

  const handleCreateRequest = useCallback(
    async event => {
      event.preventDefault();

      dispatch(refundRequest(selectedValue, message, files[0])).then(response => {
        if (response.status === 200) {
          handleClose();
          handleSuccess();
        } else if (response.response && response.response.data && response.response.data.errors) {
          const errors = response.response.data.errors;
          if (errors.refund_file) {
            setError(errors.refund_file);
          } else {
            setError(`Не удалось отправить данные. Код ошибки: ${response.status}`);
          }
        }
      });
    },
    [message, files, dispatch, handleClose]
  );

  return (
    <>
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleCreateRequest}
        autoComplete='off'
        noValidate
      >
        <CustomInfoAlert
          title={'Чтобы обращение было принято, необходимо прикрепить письмо на возврат средств'}
          link={'Скачать шаблон'}
          type={'info'}
          handleAction={getDocument}
        />
        <FormControl fullWidth={true}>
          <CustomSelectField
            id='custom-select'
            label='Локация'
            name='Локация'
            variant='filled'
            value={selectedValue}
            onChange={handleSelectChange}
            options={balanceTypes}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isFocused={isFocused}
          />
          <CustomTextField
            error={error}
            type='text'
            name='message'
            label='Описание проблемы'
            variant='filled'
            value={message}
            required={true}
            onChange={e => handleInputChange(e.target.value)}
            helperText={error}
            autoFocus={true}
          />
          <FileUploadPreview
            fieldType='changeCredentials'
            files={files}
            isSingleFile={true}
            setFiles={newFiles => {
              const firstFile = Array.isArray(newFiles) ? [newFiles[0]] : [];
              setFiles(firstFile);
            }}
          />
          {files.length > 0 && (
            <FileContainer>
              <p>Прикрепленные файлы</p>
              {files.map((file, index) => (
                <div key={index}>
                  <span>{file.name}</span>
                  <IconComponent type={'remove'} handleAction={() => handleRemoveFile(file.name)} />
                </div>
              ))}
            </FileContainer>
          )}
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title={'Отправить'}
              handleAction={handleCreateRequest}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default ReturnMoneyWidget;
