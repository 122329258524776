import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../shared/ui/components/StepButton';
import { CustomTextField } from '../../shared/ui/components/Field/CustomTextField';
import { useDispatch } from 'react-redux';
import { colors } from '../../shared/ui/constants/styleConstants';
import { styled } from 'styled-components';
import { innNotFoundRequest } from '../../services/workki/CustomerActions';

export const Caption = styled.div`
  margin: 0 0 16px 0;
  font-weight: 400;
  color: ${colors.workkiDarkFill};
`;

const InnNotFoundWidget = ({ handleClose, handleSucces }) => {
  const [formData, setFormData] = useState({
    incorrect_inn: '',
    comment: ''
  });

  const [errors, setErrors] = useState({
    incorrect_inn: '',
    comment: ''
  });
  const [filled, setFilled] = useState({
    incorrect_inn: false,
    comment: false
  });
  const [isFocused, setIsFocused] = useState({
    incorrect_inn: false,
    comment: false
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleInputChange = (name, inputValue) => {
    let newInputValue = inputValue;

    if (name === 'incorrect_inn') {
      newInputValue = inputValue.replace(/[^\d.]/g, '');
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: newInputValue !== ''
    }));
    setFormData(prevState => {
      return {
        ...prevState,
        [name]: newInputValue
      };
    });
  };

  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
  };

  const handleEraseValue = fieldName => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  useEffect(() => {
    setIsSubmitDisabled(formData.comment === '' || formData.incorrect_inn === '');
  }, [formData]);

  const dispatch = useDispatch();

  const handleCreateRequest = useCallback(
    async event => {
      event.preventDefault();

      dispatch(innNotFoundRequest(formData.incorrect_inn, formData.comment)).then(response => {
        if (response.status === 200) {
          handleClose();
          handleSucces();
        } else if (response && response.data && response.data.errors) {
          const errors = response.data.errors;
          if (errors.incorrect_inn[0]) {
            setErrors(prevError => ({
              ...prevError,
              incorrect_inn: errors.incorrect_inn[0]
            }));
          } else {
            setErrors(`Не удалось отправить данные. Код ошибки: ${response.status}`);
          }
        }
      });
    },
    [dispatch, handleClose, formData]
  );

  return (
    <>
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleCreateRequest}
        autoComplete='off'
        noValidate
      >
        <FormControl fullWidth={true}>
          <CustomTextField
            error={''}
            type='text'
            name='incorrect_inn'
            label='ИНН'
            variant='filled'
            value={formData.incorrect_inn}
            required={true}
            onFocus={() => handleFocus('incorrect_inn')}
            onBlur={() => handleBlur('incorrect_inn')}
            onChange={e => handleInputChange('incorrect_inn', e.target.value)}
            helperText={errors.incorrect_inn ? errors.incorrect_inn : ''}
            autoFocus={true}
            endAdornmentProps={{
              filled: filled.incorrect_inn,
              isFocused: isFocused.incorrect_inn,
              handleEraseValue: name => handleEraseValue(name)
            }}
          />
          <CustomTextField
            error={''}
            type='text'
            name='comment'
            label='Комментарий'
            variant='filled'
            value={formData.comment}
            required={true}
            onFocus={() => handleFocus('comment')}
            onBlur={() => handleBlur('comment')}
            onChange={e => handleInputChange('comment', e.target.value)}
            helperText={errors.comment ? errors.comment : ''}
            endAdornmentProps={{
              filled: filled.comment,
              isFocused: isFocused.comment,
              handleEraseValue: name => handleEraseValue(name)
            }}
          />
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title={'Отправить'}
              handleAction={handleCreateRequest}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default InnNotFoundWidget;
